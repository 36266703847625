import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import mapBg from '../location/map.jpg';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StateSelector from '../location/state-selector';
import { voteForLocation, changeLocation } from 'store/actions/app-actions';
import { IfBlock } from 'components';

const attribution   = {
  name    : "Steve Richey", //"Joanna Kosinska",
  handle  : "@rvcmedia", //"@joannakosinska",
};

function WelcomLocation({classes}){
  const [isOpen, setIsOpen]       = useState(false);
  const [state, setState]         = useState("");
  const [voted, setVoted]         = useState("");
  const [voteError, setVoteError] = useState(false);
  const profile                   = useSelector(state => state.app.profile);
  const dispatch                  = useDispatch();

  const nameUrl           = `https://unsplash.com/${attribution.handle}`;

  const onVote = async (notify) => {
    if(state){
      const result  = await dispatch(voteForLocation(state, profile.email, notify));
      if(result.status  === "ok"){
        setVoted(`Thank you for your vote!${notify ? "  We will notify you when we reach your state." : ""}`);  //`
        setVoteError(false);
        setState("");
      }
      else{
        setVoteError(true);
      }
    }
  }

  const onContinue = async () => {
    await dispatch(changeLocation("CO"));
  }

  return (
    <Grid container className={classes.root}>
      <Grid item sm={6} className={classes.leftCol}>
        <div className={classes.overlay}>
          <span className={classes.attribution}>Photo by <a href={nameUrl} target="blank">{attribution.name}</a> on <a href="https://unsplash.com/" target="blank">Unsplash</a></span>
        </div>
      </Grid>
      <Grid item sm={6} xs={12} className={classes.rightCol}>
        <Grid container>
          <Grid container className={classes.headerBar}>
            <Typography variant="h2" className={classes.header}>Choose your state</Typography>
          </Grid>
          <Typography variant="h4" className={classes.subHeader}>FigureEx is only available in Colorado at the moment.</Typography>
            
          <Grid container className={classes.content}>
            
            <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column" item spacing={2} className={classes.buttonGrid}>
              <Button size="small" variant="outlined" color="secondary" onClick={onContinue} className={classes.button}>
                I'm in Colorado
                <ArrowForwardIcon className={classes.mLeft} />
              </Button>
              <Button size="small" color="default" className={classes.mutedButton} onClick={() => setIsOpen(!isOpen)}>Other States</Button>
            </Grid>
            {/* <Grid container className={classes.voteSection}>
              <Typography className={classes.info}>Not in Colorado?</Typography>   
              <Button className={classes.linkButton} color="primary" onClick={() => setIsOpen(!isOpen)}>Click here.</Button>           
            </Grid> */}

            {isOpen && 
            <Grid container className={classes.voteSection}>
                <IfBlock condition={!voted}>

                  <Typography variant="h4" className={classes.contentText}>
                    We're working hard to add support for more states, please let us know where you are and we can notify you when we reach your state.
                  </Typography>

                  <Grid container className={classes.selectRow}>
                    <Grid item sm={4} container direction="column" justifyContent="flex-end"><Typography className={classes.label}>State:</Typography></Grid>
                    <Grid item sm={8}>
                      <StateSelector state={state} onSelected={setState} waitlistOnly={true} className={classes.inputField}/>
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="flex-end" className={classes.selectRow} spacing={2}>
                    <Grid item>
                      <Button id="vote" size="small" color="default" className={classes.mutedButton} onClick={() => onVote(false)} title="Vote for this state, but don't send me a notification">Just Vote</Button>
                    </Grid>
                    <Grid item>
                        <Button id="notify" size="small" variant="outlined" color="primary" onClick={() => onVote(true)} title="Vote for this state, and send me a notification when you reach it">Notify Me</Button>
                    </Grid>
                  </Grid>

                </IfBlock>

              <Grid container justifyContent="flex-end" className={classes.alertRow} spacing={2}>
                
                {voted && 
                  <Card elevation={0} className={classes.voted} variant="outlined">
                    <span>{voted}</span>
                  </Card>
                }
                {voteError && 
                  <Card elevation={0} className={classes.error} variant="outlined">
                    <span>We're sorry, there was a problem capturing your vote.  If this persists, please come back and try again later.</span>
                  </Card>
                }

              </Grid>
            </Grid>
            }

          </Grid>

        </Grid> 
      </Grid>       
    </Grid>       
  );
  
}

const styles  = (theme => ({
  root        : {
    height          : "100%",
  },
  leftCol   : {
    backgroundImage     : `url(${mapBg})`,
    backgroundSize      : "cover",
    backgroundPosition  : "50%",   
    position            : "relative", 
    borderRadius        : "4px 0 0 4px",
    padding             : "1px 0 1px 1px",
  },
  rightCol  : {
    borderRadius        : "0 4px 4px 0",
  },
  mLeft   : {
    marginLeft    : theme.spacing(0.5),
  },
  voteSection   : {
    // marginTop     : theme.spacing(1),
  },
  attribution : {
    fontSize        : 14,
    fontWeight      : 400,
    position        : "absolute",
    right           : "10px",
    bottom          : "10px",
    color           : `${theme.palette.common.white}6`,
    "& a"           : {
        color       : `${theme.palette.common.white}6`,
    }
  },
  headerBar   : {
    borderTop   : `10px solid ${theme.palette.primary.dark}`,
    background  : theme.palette.primary.main,
    padding     : 15,
    borderRadius: "0 4px 0 0",
  },
  header  : {
    fontSize        : 28,
    fontWeight      : 400,
    color           : theme.palette.primary.contrastText,
    marginBottom    : 0,
  },
  subHeader   : {
    padding       : 15,
    fontSize      : 18,
    fontWeight    : 400,
    color         : theme.palette.grey[700],
  },
  content   : {    
    borderRadius  : 6,
    padding       : "0 15px", 
    position      : "relative",  
  },
  buttonGrid  : {
    marginTop     : theme.spacing(1),
    padding       : theme.spacing(1),
  },
  button    : {
    marginBottom  : theme.spacing(2),
  },
  contentText  : {
    // padding       : "0 15px",
    fontSize      : 16,
    fontWeight    : 300,
    margin        : "15px 0 10px 0",
    color         : theme.palette.grey[600],
  },
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
  }, 
  selectRow : {
    marginBottom  : 15,
  },
  alertRow  : {
    padding       : theme.spacing(2),
  },
  label     : {
    fontSize      : "1.15rem",
  },
  inputField  : {
    width     : "100%",
  },
  nextButton   : {
    position        : "absolute",
    bottom          : "15px",
    right           : "13px",
  },
  linkButton  : {
    border    : "none",
    fontSize  : 18,
    fontWeight: 300,
    color     : theme.palette.primary.main,
    padding   : 0,
    marginLeft: theme.spacing(1),
    marginTop : `-${theme.spacing(1)}px`,
    textTransform : "lowercase",
  },
  error         : {
    background      : theme.palette.error.light,
    border          : `1px solid ${theme.palette.error.dark}`,
    borderRadius    : 5,
    color           : theme.palette.error.main,
    margin          : "10px 0",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",
  },
  voted         : {
    background      : `${theme.palette.primary.light}33`,
    border          : `1px solid ${theme.palette.primary.dark}`,
    borderRadius    : 5,
    color           : theme.palette.primary.main,
    margin          : "15px 0",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",

  },
  mutedButton   : {
    color   : theme.palette.grey[500],
  }
}));


export default withStyles(styles)(WelcomLocation);