import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectStatus } from './';

export function useStatus(key){
  //using the selector will make sure it gets cached
  const status    = useSelector(state => selectStatus(state, key));  
  return status;
}

export function useStatusMulti(keys){
  const keysArray = _.isArray(keys) ? keys : [keys];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const statuses  = _.compact(keysArray.map(key => useSelector(state => selectStatus(state, key))));
  const merged    = _.reduce(statuses, (accum, status) => {
    const result  = {
      isWorking       : accum.isWorking || Boolean(status?.isWorking),
      error           : accum.error || Boolean(status?.error),
      isInitialized   : accum.isInitialized && Boolean(status?.isInitialized),
      statuses        : [...accum.statuses, status],
    };
    return result;
  }, {statuses: []});

  return merged;
}