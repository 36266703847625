import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { ItemListRow, ItemMenu } from 'components';
// import { deleteWaitlistItem } from 'store/actions/admin-actions';
import { useStatus } from 'redux-action-status';
import { formatDateString } from 'helpers/general-helpers';

function UserItem({item, onAction}){
  const classes           = buildStyles();
  const status            = useStatus("profiles");
  const [data, setData]   = useState(item);
  const [isDeleting, setDeleting]   = useState(false);
  // const dispatch  = useDispatch();
  const uidTitle  = `${data.uid} (click to copy)`;    //`

  useEffect(() => {
    async function prepareItem(item){
      let data  = item;
      if(!data.isLoaded && data.ref){
        data  = {...data, isLoaded: true, ...(await data.ref.data())};
      }
      setData(data);
    }
    prepareItem(item);
    return undefined;
  }, [item]);

  const provider  = data?.provider.toUpperCase().substring(0,1);
  const onToggleDelete = () => { setDeleting(!isDeleting); }
  const onDelete  = (e) => { console.log("delete user?") }

  async function onMenuClick(item, action){
    console.log("action " + action + " for item " + item.name);
    if(action === "delete"){
      onToggleDelete();
    }
    else if(action === "view_invitation"){
      await onAction("view_invitation", item.invite);
    }
  }

  function onMenuClosed(){
    // setOver(false);
  }

  function onShowUser(){
    onAction("view_user", item);
  }

  function copyUid(){
    navigator.clipboard.writeText(data.uid);
  }

  return (
    <ItemListRow isLoading={!item.isLoaded && item.ref} colSpan={4} itemName={data.name} isDeleting={isDeleting} onCancelDelete={onToggleDelete} onDelete={onDelete} isWorking={status.isWorking}>
      <td title={uidTitle} onClick={copyUid} className={classes.uidCol}>{`${data.uid.substring(0,4)}...`}</td>
      <td>
        <Button onClick={onShowUser} color="secondary" className={classes.linkButton}>{data.displayName}</Button>
      </td>
      <td>{data.email}</td>
      <td>
        <Tooltip title={data.role}>
          <Avatar className={clsx(classes.roleAvatar, data.role)}>{data.role.toUpperCase().substring(0,1)}</Avatar>
        </Tooltip>
      </td>
      <td>{data.lastLogin ? formatDateString(data.lastLogin, "M/DD h:mm A") : ""}</td>
      <td>
        <Tooltip title={data.provider}>
          <Avatar className={clsx(`provider-${provider}`, classes.providerAvatar)}>{provider}</Avatar>
        </Tooltip>
      </td>
      <td className="moreCol">            
        <ItemMenu item={item} options={menuOptions[item.status]} status={status} onMenuClick={onMenuClick} onMenuClosed={onMenuClosed} />            
      </td>        
    </ItemListRow>
  );
}

export default UserItem;

const buildStyles   = makeStyles(theme => ({
  actionButton  : {
    marginLeft   : theme.spacing(0.5),
  },
  linkButton    : {
    textTransform     : "none",
    fontSize          : 14,
    fontWeight        : "400",
    padding           : "2px 6px",
  },
  providerAvatar  : {
    height      : theme.spacing(3),
    width       : theme.spacing(3),
    fontSize    : 14,
    fontWeight  : "500",
    "&.provider-G"  : {
      backgroundColor   : `${theme.palette.primary.main}99`, //`
    },
    "&.provider-F"  : {
      backgroundColor   : `${theme.palette.secondary.main}99`, //`
    },
  },
  roleAvatar  : {
    height      : theme.spacing(3),
    width       : theme.spacing(3),
    fontSize    : 14,
    fontWeight  : "500",
    "&.admin"  : {
      backgroundColor     : `${theme.palette.error.main}99`, //`
    },
    "&.user"  : {
      backgroundColor     : `${theme.palette.secondary.main}99`, //`
    },
    "&.reviewer"  : {
      backgroundColor     : `${theme.palette.warning.main}99`, //`
    }
  },
  uidCol  : {
    cursor    : "pointer",
  }
}));


const menuOptions   = {
  waiting   : [
    {id: 0, label: "Invite User", action: "invite" },
    {id: 10, label: "Remove from Waitlist", action: "delete" },
  ],
  invited   : [
    {id: 0, label: "View invitation", action: "view_invitation" },
    {id: 10, label: "Delete Invitation", action: "delete" },
  ],
  accepted   : [
    {id: 0, label: "View invitation", action: "view_invitation" },
    {id: 10, label: "View user", action: "view_user" },
  ]
};