import { ADMIN_ACTIONS } from '../actions/admin-actions';
import helpers from './reducer-helpers';
import { APP_ACTIONS } from 'store/actions/action-types';

const INIT_STATE  = {
  feedback    : null,
  waitlist    : null,
  invitations : null,
  profiles    : null,
  admins      : null,
  dashboard   : null,
  auditLog    : null,
};

function itemsLoaded(key, state, action){
  if(action.isOk){
    return {
      ...state,
      [key]    : action.data,
    };
  }

  return state;
}

function waitlistItemDeleted(state, action){
  if(action.isOk){
    return {
      ...state,
      waitlist    : helpers.immRemoveItem(state.waitlist, item => item.id === action.id),
    };
  }
  return state;
}

function waitlistItemUpdated(state, action){
  if(action.isOk){
    return {
      ...state,
      waitlist    : helpers.immAddOrReplace(state.waitlist, action.data, i => i.id === action.data.id),
    }
  }
}

function invitationCreated(state, action){
  if(action.isOk){
    return {
      ...state,
      invitations   : helpers.immAddOrReplace(state.invitations, action.data, i => i.id === action.data.id),
    }
  }
  return state;
}

//Clear everything out if the user signs out
function onSignedOut(state, action){
  return {
    ...INIT_STATE,
  };
}


//==================
//Reducer
const adminReducer   = {
  [ADMIN_ACTIONS.WAITLIST_LOADED]     : (s, a) => itemsLoaded("waitlist", s, a),
  [ADMIN_ACTIONS.WAITLIST_ITEM_DELETED]  : waitlistItemDeleted,
  [ADMIN_ACTIONS.WAITLIST_ITEM_UPDATED]  : waitlistItemUpdated,
  [ADMIN_ACTIONS.INVITATIONS_LOADED]  : (s, a) => itemsLoaded("invitations", s, a),
  [ADMIN_ACTIONS.INVITATION_CREATED]  : invitationCreated,
  [ADMIN_ACTIONS.PROFILES_LOADED]     : (s, a) => itemsLoaded("profiles", s, a),
  [ADMIN_ACTIONS.ADMINS_LOADED]       : (s, a) => itemsLoaded("admins", s, a),
  [ADMIN_ACTIONS.FEEDBACK_LOADED]     : (s, a) => itemsLoaded("feedback", s, a),
  [ADMIN_ACTIONS.AUDITLOG_LOADED]     : (s, a) => itemsLoaded("auditLog", s, a),
  
  [APP_ACTIONS.SIGNED_OUT]  : onSignedOut,
};

export default helpers.createReducer(INIT_STATE, adminReducer);

