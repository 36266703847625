import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SettingsCard from "./settings-card";

const DashboardView = () => {
  const classes   = buildStyles();

  return (
    <Grid id="data-view" container className={classes.root} spacing={2}>
      <Grid item xs={12}>        
        <Typography variant="h5" color="primary" className={classes.title}>Admin Dashboard</Typography>
      </Grid>
      <Grid item xs={6}>
        <SettingsCard />
      </Grid>
    </Grid>
  );
}

export default DashboardView;

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    // textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    // textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
}));