import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ManageData from './manage-data';
import ViewData from './view-data';

const MyDataView = () => {
  const classes   = buildStyles();

  return (
    <Grid id="my-data-view" container className={classes.dataView}>
      <Route path="/my/data" exact={true} component={ManageData} />
      <Route path={["/my/data/view/:conversationId", "/my/data/view"]} component={ViewData} />
    </Grid>
  );
}

export default MyDataView;

const buildStyles   = makeStyles(theme => ({
  dataView  : {
    padding     : theme.spacing(1),
    width       : "100%", //"90%",
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: 0,
  },
  tab   : {
    fontSize  : 15,
  },
  tabPanel  : {
    padding   : `${theme.spacing(2)}px 0`, //`
  }
}));

