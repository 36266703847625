import React from 'react';
// import _ from 'lodash';
import helper from 'helpers/number-helper';
import fHelper from 'helpers/financials-helper';

export const SubtotalRow = ({label, data, section}) => {
    const total     = fHelper.calculateSectionTotal(section, data);
    const totalStr  = helper.asCurrency(total, "$0");

    return (
        <tr className="section-subtotal">
            <td colSpan="2" className="subtotal-label text-primary"><span>{total && label ? label : ""}</span></td>
            <td colSpan="1" className="subtotal-val field-currency">
                <input type="text" className="form-control text-primary" disabled value={totalStr}></input>
            </td>
        </tr>
    )
}