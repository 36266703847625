import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import { selectRequest } from 'store/selectors/client-selectors';

const ClientPanel = ({share}) => {
  const classes     = buildStyles();
  const request     = useSelector(state => selectRequest(state, share?.requestId));
  const clientName  = request ? `${request?.firstName} ${request?.lastName}`.trim() : "Loading..."; //`

  //TODO: More details:
  // - Notes (not shared with client)
  // - Notes (shared with client)
  // - History (interaction with client - e.g. sent to client, question from client, etc.)

  return (
    <Accordion className={classes.clientPanel}>
      <AccordionSummary className={classes.clientSummary} classes={{content: classes.clientSummaryContent, expandIcon: classes.expandIcon}} expandIcon={<ExpandMoreIcon/>} aria-controls="clientDetails" id="clientSummary">
        <Grid container alignItems="center">
          <AccountCircleIcon className={classes.avatar} color="inherit"/>
          <Typography variant="h5" color="inherit" className={classes.title}>{clientName}</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container className={classes.contactInfoGrid}>
          <Grid item sm={4} container alignItems="center">
            <Tooltip title="Email Address">
              <EmailIcon fontSize="small"/>
            </Tooltip>
            <Typography className={classes.contactText}>{request?.email}</Typography>
          </Grid>
          <Grid item sm={4} container alignItems="center">
            <Tooltip title="Phone Number">
              <PhoneIcon fontSize="small"/>
            </Tooltip>
            <Typography className={classes.contactText}></Typography>
          </Grid>
          <Grid item sm={4} container alignItems="center">
            <Tooltip title="Address">
              <HomeIcon fontSize="small"/>
            </Tooltip>
            <Typography className={classes.contactText}></Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default ClientPanel;

const buildStyles   = makeStyles(theme => ({
  clientPanel   : {
    width     : "100%",
  },
  clientSummary   : {
    "&.Mui-expanded" : {
      fontWeight  : 700,
      color         : theme.palette.secondary.main,
      borderBottom  : `1px solid ${theme.palette.secondary.light}`, //`
      background    : `${theme.palette.secondary.main}22`, //`
      minHeight     : theme.spacing(6),
    }
  },
  clientSummaryContent  : {
    margin      : `${theme.spacing(1)}px ${theme.spacing(0)}px !important`, //`    
  },
  expandIcon  : {
    padding     : `${theme.spacing(0)}px ${theme.spacing(1.5)}px`, //`
  },
  title   : {
    fontSize    : 16,
    fontWeight  : 500,
    display     : "block",
  },
  avatar      : {
    border        : `0.5px solid ${theme.palette.grey[300]}`,
    borderRadius  : "50%",
    height        : 30,
    width         : 30,
    lineHeight    : 30,
    marginRight   : theme.spacing(1),
  }, 
  contactInfoGrid : {
    "& p"   : {
      fontSize    : 16,
      color       : theme.palette.grey[600],
      display     : "inline",
    },
    "& svg"   : {
      fontSize    : 16,
      color       : theme.palette.primary.main,
      display     : "inline",
      marginRight : theme.spacing(0.5),
    }
  },
}));