import _ from 'lodash';
import { APP_ACTIONS } from '../actions/action-types';
import { CONVO_ACTIONS } from '../actions/conversation-actions';
import helpers from './reducer-helpers';

//==================
// Initial Reducer State
const INIT_STATE  = {
  conversations     : null,
  selectedId        : "0",
  currentComments   : {},
};

//==================
// Reducer functions

//#region Loading Conversation or conversations
function conversationsLoaded(state, action){
  //if the data is null, the conversation wasn't found
  const keyFunc   = action.isUser ? data => data.reviewerId : data => data.sharerId;
  // const preped    = _.map(action.data, c => prepareConversation(c));
  const items     = _.reduce(action.data, (acum, curr) => { return {...acum, [keyFunc(curr)]: curr}; }, {});
  
  //TODO: Change this to flatten by conversation
  // const comments  = flattenAllComments(action.data);

  return {
    ...state,
    conversations   : {
      ...state.conversations,
      ...items,
    },
    // comments  : comments
  };
}

function conversationLoaded(state, action){
  //if the data is null, the conversation wasn't found
  const convo     = action.data || null;
  const id = action.convoId;
  const existing = (state.conversations || [])[id];

  return {
    ...state,
    conversations   : {
      ...state.conversations,
      [id]  : {
        ...existing,
        ...convo,
      }
    },
    // comments  : flattenComments(convo),
  };
}
//#endregion

function selectConversation(state, action){
  const conv  = state.conversations[action.id];
  return {
    ...state,
    selectedId        : action.id,
    currentComments   : flattenComments(conv),
  };
}

//#region Create or Update Conversation
function conversationCreated(state, action){
  return {
    ...state,
    conversations   : {
      [action.convoId]  : action.data,
      ...state.conversations,
    }
  };
}

//#endregion

//#region Create Comment
function commentCreated(state, action){
  const convo   = state.conversations[action.convoId];

  return {
    ...state,
    conversations   : {
      [action.convoId]  : {
        ...convo,
        comments  : [action.data, ...convo.comments],
      }
    },
    currentComments : updateCurrentComments(state.currentComments, action),
  };
}
//#endregion


function onSignedOut(state, action){
  return {...INIT_STATE };
}

//==================
// Reducer creation

const convoReducer  = {
  [CONVO_ACTIONS.CONVOS_LOADED]         : conversationsLoaded,
  [CONVO_ACTIONS.CONVO_LOADED]          : conversationLoaded,
  [CONVO_ACTIONS.CONVO_SELECTED]        : selectConversation,
  [CONVO_ACTIONS.CONVO_CREATED]         : conversationCreated,
  [CONVO_ACTIONS.CONVO_UPDATED]         : conversationLoaded,
  [CONVO_ACTIONS.COM_CREATED]           : commentCreated,

  [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

export default helpers.createReducer(INIT_STATE, convoReducer);

// function flattenAllComments(conversations){
//   if(!conversations || conversations.length === 0) return {};
//   let final    = {};
//   conversations.forEach(conv => {
//     const comments   = conv.comments;
//     if(comments && comments.length > 0){
//       let flat  = {};
//       comments.forEach(cmt => {
//         const myCmt   = {...cmt, conversationId: conv.id };
//         const node  = flat[myCmt.fieldKey];
//         if(node === undefined){
//           flat[myCmt.fieldKey]  = [myCmt];
//         }
//         else{
//           node.push(myCmt);
//         }
//       });
//       final[conv.id]  = flat;
//     }
//   });

//   return final;
// }

// function prepareConversation(conversation){
//   return {
//     ...conversation,
//     flatComments  : flattenComments(conversation),
//   };
// }

function flattenComments(conversation){
  if(!conversation || !conversation.comments) return {};
  const allComments   = conversation.comments;
  let flat    = {};
  allComments.forEach(cmt => {
    const myCmt   = {...cmt, conversationId: conversation.id };
    const node  = flat[myCmt.fieldKey];
    if(node === undefined){
      flat[myCmt.fieldKey]  = [myCmt];
    }
    else{
      node.push(myCmt);
    }
  });

  return flat;
}

function updateCurrentComments(collection, action){
  //Add to the flat comments collection as well
  const newItem   = {conversationId: action.convoId, ...action.data}
  const comments  = {...collection};
  if(comments[action.data.fieldKey]){
    comments[action.data.fieldKey].push(newItem)
  }
  else{
    comments[action.data.fieldKey]  = [newItem];
  }

  return comments;
}