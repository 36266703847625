import React from 'react';
import { ListSection, StandardSection, QuestionSection } from './';
import fHelper from 'helpers/financials-helper';
import FinalSection from './final-section';

export const Section = ({regions, region, section, values, onChange}) => {

    const onChangeDetails   = (fieldId, total, details, index) => { 
        return onChange(section.id, fieldId, total, details, index)
    };

    const fields    = fHelper.getFields(section.fields);

    switch(section.type){
        case "list" : 
            return <ListSection section={section} fields={fields} values={values} onChange={onChange}/>;
        
        case "questions":
            return <QuestionSection section={section} fields={fields} values={values} onChange={onChange}/>;

        case "review":
            return null; 

        case "docs":
            return <FinalSection regions={regions} region={region} section={section} values={values} fields={fields}/>

        default : 
            return <StandardSection section={section} fields={fields} values={values} onChange={onChange} onChangeDetails={onChangeDetails}/>;
    }
}