import _ from 'lodash';
import { trackEvent, Events } from 'helpers/analytics';
import { getNowString } from './action-helpers';
// import { STATUS_START } from 'redux-action-status';
// import { getSubCollection } from 'store/middleware/firebase-middleware';

const CONVO_LOADED = "CONVERSATION_LOADED"; //, CONVO_LOADING   = "CONVERSATION_LOADING", CONVO_LOAD_FAILED = "CONVERSATION_LOAD_FAILED";
const CONVOS_LOADED = "CONVERSATIONS_LOADED"; //, CONVOS_LOADING  = "CONVERSATIONS_LOADING", CONVOS_LOAD_FAILED = "CONVERSATIONS_LOAD_FAILED";
const CONVO_CREATING  = "CONVERSATION_CREATING", CONVO_CREATED = "CONVERSATION_CREATED", CONVO_CREATE_FAILED = "CONVERSATION_CREATE_FAILED";
const COM_CREATING    = "COMMENT_CREATING", COM_CREATED = "COMMENT_CREATED", COM_CREATE_FAILED = "COMMENT_CREATE_FAILED";
const CONVO_SELECTED  = "CONVERSATION_SELECTED";
const CONVO_UPDATED  = "CONVERSATION_UPDATED"; //, CONVO_UPDATING  = "CONVERSATION_UPDATING", CONVO_UPDATE_FAILED = "CONVERSATION_UPDATE_FAILED";

export const CONVO_ACTIONS  = {
  CONVO_LOADED,   //CONVO_LOADING, CONVO_LOAD_FAILED,
  CONVOS_LOADED, //CONVOS_LOADING, CONVOS_LOAD_FAILED,
  CONVO_CREATING, CONVO_CREATED, CONVO_CREATE_FAILED,
  COM_CREATING, COM_CREATED, COM_CREATE_FAILED,
  CONVO_SELECTED, 
  CONVO_UPDATED, //CONVO_UPDATING, CONVO_UPDATE_FAILED,
};

//---
// Loads a conversation based on a specific share
export const loadConversation = (shareId) => async(dispatch, getState) => {

  const result  = await dispatch({
    type        : CONVO_LOADED,
    firebase  : {
      type        : "getSingle",
      collection  : "conversations",
      key         : shareId,
      subCollections  : ["comments"],
      hydrate     : true,
      mayNotExist : true,         //indicates a permission denied shouldn't automatically be an error
    },
    convoId   : shareId,      //for the reducer
    statusKey : "conversations",
  });

  //Also flag this as the selected conversation
  await dispatch({type: CONVO_SELECTED, id: shareId});    //share id is the same as the the conversation id in this case
  return result;
}

//---
// Loads all the conversations the current user is having
export const loadMyConversations = () => async(dispatch, getState) => {
  // dispatch({type: STATUS_START, statusKey: "conversations"});
  
  const profile   = getState().app.profile;
  const isUser    = (profile.role === "user");
  const query     = isUser ? ["sharerId", "==", profile.uid] : ["reviewerId", "==", profile.uid];

  const result  = await dispatch({
    type    : CONVOS_LOADED,
    // failType: CONVOS_LOAD_FAILED,
    firebase: {
      type            : "getList",
      collection      : "conversations",
      query           : query,
      order           : ["updatedAt", "desc"],
      hydrate         : true,
      subCollections  : ["comments"],
    },
    isUser      : isUser,
    statusKey   : "conversations",
  });

  return result;
}

export const selectConversation = (id) => async(dispatch, getState) => {
  //TODO: mark the previous conversation as viewed
  const state   = getState();
  if(state.convo.selectedId !== "0"){
    //update the conversation to indicate this user has viewed it.
    dispatch(updateConversation(null, null, true, false));
  }

  if(id === "0"){
    return dispatch({type: CONVO_SELECTED, id: id});
  }

  return dispatch({type: CONVO_SELECTED, id: id});
}

export const updateConversation = (id, updates, isViewed, isTouched) => async(dispatch, getState) => {
  // dispatch({type: STATUS_START, statusKey: "conversations"});
  const state     = getState();
  const profile   = state.app.profile;
  const cId       = id || state.convo.selectedId;
  const convo     = state.convo.conversations[cId];

  if(!convo) return null;
  
  //create the update model
  let myUpdates       = null;
  if(!updates){
    const now         = getNowString();
    const keyPrefix   = profile.isUser ? "sharer" : "reviewer";
    myUpdates         = {};
    if(isViewed) myUpdates[`${keyPrefix}ViewedAt`]  = now;
    if(isTouched) myUpdates[`${keyPrefix}TouchedAt`]  = now;
  }
  else{
    myUpdates   = _.pick(updates, ["sharerViewedAt", "sharerTouchedAt", "reviewerViewedAt", "reviewerTouchedAt"]);
  }

  //update the conversation
  return dispatch({
    type      : CONVO_UPDATED,
    // failType  : CONVO_UPDATE_FAILED,
    firebase: {
      type        : "updateSingle",
      collection  : "conversations",
      key         : convo.id,
      value       : myUpdates,
    },
    convoId     : cId,    //used by the reducer
    statusKey   : "conversations",
  });

}

//---
// Creates a new comment.  Will create a new conversation if necessary, before creating the comment
export const createComment = (shareId, fieldKey, content) => async(dispatch, getState) => {
  
  // dispatch({type: STATUS_START, statusKey: "comments"});
  const state       = getState();
  const profile     = state.app.profile;

  const result  = await ensureConversation(dispatch, state, profile, shareId);
  if(!result.status){
    return result;
    // await dispatch({type: COM_CREATE_FAILED, error: result.error});
    // return {
    //   isOk  : false,
    //   error : result.error,
    // };  //couldn't create the conversation...
  } 

  //Now, create the comment
  const convoId   = profile.isReviewer ? shareId : result.conversation.reviewerId;
  const now     = getNowString();
  const model   = {
    createdAt     : now,
    createdBy     : profile.uid,
    fieldKey      : fieldKey,
    content       : content,
  };
  
  const comResult   = await dispatch({
    type      : COM_CREATED,
    failType  : COM_CREATE_FAILED,
    firebase  : {
      type        : "create",
      collection  : `conversations/${result.conversation.id}/comments`,
      value       : model,
    },
    // shareId   : shareId,  
    convoId   : convoId, //used by the reducer
    statusKey : "comments",
  });

  if(!result.isNew){
    //Track that the conversation has been touched by the current user
    await dispatch(updateConversation(convoId, null, true, true));
  }

  trackEvent(Events.com_created);
  return comResult;
}


//=======================
// Helper Functions

//-----
// Ensures there is a conversation for the comments.  Either validates it exists, or creates a new one
async function ensureConversation(dispatch, state, profile, shareId){
  let share   = _.find(state.share.shares, s => s.id === shareId);
  if(!share){
    return {status: false, error: "Failed to create conversation because there is no sharing agreement between parties."}; //No sharing agreement
  } 
  if(share.sharer !== profile.uid && share.reviewer !== profile.uid){
    return  {status: false, error: "Failed to create conversation because current user is not a participant in provided sharing agreement."}; //I'm not part of this coversation
  } 
  
  let isNew   = false;
  let conversation   = _.find(state.convo.conversations, c => c.shareId === shareId);  //it may not appear in the state by the share id, so find it
  if(!conversation){
    //This is a new conversation, need to create it
    const now       = getNowString();
    const touched   = profile.isUser ? { sharerTouchedAt : now, sharerViewedAt: now } : { reviewerTouchedAt : now, reviewerViewedAt: now };

    const toSave   = {
      // shareId     : shareId,
      createdAt   : getNowString(),
      sharerId    : share.sharer,
      requestId   : share.requestId,
      reviewerId  : share.reviewer,
      shareId     : share.id,
      ...touched
    };

    const createResult  = await dispatch({
      type    : CONVO_CREATED,
      firebase  : {
        type        : "create",
        collection  : "conversations",
        key         : share.id,   //shareId may be the share request id, if it's the reviewer
        value       : toSave,
      },
      convoId   : profile.isReviewer ? shareId : share.reviewer, //used by the reducer
      statusKey   : "conversations",
    });

    if(!createResult.isOk){
      return {status: false, error: createResult.error};    //Failed to create the conversation
    }
    else{
      trackEvent(Events.convo_created);
      conversation   = createResult.data;
      isNew   = true;
    }
  }

  return {status: true, conversation: conversation, isNew : isNew};
}