// import _ from 'lodash';
import { APP_ACTIONS } from '../actions/action-types';
import { SHARE_ACTIONS } from '../actions/share-actions';
import helpers from './reducer-helpers';

// const STATUS_DEFAULT  = { isWorking: false, error: null, isEmpty: false };

//==================
// Initial Reducer State
const INIT_STATE  = {
  requests      : null,   //Requests are sent requests (e.g. sent by a lawyer)
  invitations   : null,   //Invitations are received requests (e.g. received from a lawyer)
  shares        : null,   //Actual share agreements  
};

//==================
// Reducer Methods

//#region Loading Sent Requests
function requestsLoaded(state, action){
  if(action.isOk){
    return {
      ...state,
      requests      : action.data,
    }
  }
  return state;
}

//#endregion

//#region Loading Received Requests
function receivedLoaded(state, action){
  return {
    ...state,
    invitations   : action.data,
  };
}
//#endregion

//#region Updating Requests
function receivedUpdated(state, action){
  return {
    ...state,
    invitations   : helpers.immAddOrReplace(state.invitations, action.data, i => i.id === action.data.id),
  }
}
//#endregion

//#region Shares
function sharesLoaded(state, action){
  if(action.isOk){
    return {
      ...state,
      shares      : action.data,
    }
  }
  
  return state;
}

function shareCreated(state, action){
  return {
    ...state,
    shares      : [action.data, ...(state.shares || [])],
  }
}

function shareDeleted(state, action){
  return {
    ...state,
    shares      : helpers.immRemoveItem(state.shares, s => s.id === action.id),
  }
}
//#endregion

//#region Inviting a user
function requestCreated(state, action){
  if(action.isOk){
    return {
      ...state,
      requests      : [action.data, ...(state.requests || [])],
    }
  }
}

function requestDeleted(state, action){
  return {
    ...state,
    requests      : helpers.immRemoveItem(state.requests, i => i.id === action.id),
  };
}

//#endregion

function onSignedOut(state, action){
  return {...INIT_STATE };
}

//==================
// Reducer creation

const shareReducer  = {
  [SHARE_ACTIONS.RECEIVED_LOADED] : receivedLoaded,
  [SHARE_ACTIONS.RECEIVED_UPDATED]: receivedUpdated,

  [SHARE_ACTIONS.SHARES_LOADED]   : sharesLoaded,
  [SHARE_ACTIONS.SHARE_CREATED]   : shareCreated,
  [SHARE_ACTIONS.SHARE_DELETED]   : shareDeleted,

  [SHARE_ACTIONS.REQUESTS_LOADED] : requestsLoaded,
  [SHARE_ACTIONS.REQUEST_CREATED] : requestCreated,
  [SHARE_ACTIONS.REQUEST_DELETED] : requestDeleted,

  [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

export default helpers.createReducer(INIT_STATE, shareReducer);