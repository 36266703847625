export const SHARE_LABELS   = {
  0       : "pending",
  100     : "accepted",
  [-99]   : "rejected",
};

export const SHARE_CODES   = {
  pending   : 0,
  accepted  : 100,
  rejected  : -99,
};

export function isPending(inv){
  if(inv){
    if(!inv.isLoaded){
      return undefined;
      // inv           = await inv.ref.doc();
      // inv.isLoaded  = true;
    }

    return !inv.status || inv.status === SHARE_CODES.pending;
  }
}

export async function isNotViewed(inv){
  if(inv){
    if(!inv.isLoaded){
      inv           = await inv.ref.doc();
      inv.isLoaded  = true;
    }

    const result  = inv.status === SHARE_CODES.pending && !inv.viewedAt;
    return result;
  }

  return false;
}