const FIELDS     = [
  //Profile Questions
  {
      id      : "qFullName",
      type    : "question",
      label   : "What is your full name?",
      params  : [{ id : "name", type : "text", label : "Full Name", size : "md"}]
  },
  {
      id      : "qCoPName",
      type    : "question",
      label   : "What is the full name of the other party?",
      params  : [{ id : "cop_name", type : "text", label : "Full Name", size : "md"}]
  },
  {
      id      : "qEmpStatus",
      type    : "question",
      label   : "What is your employment status?",
      params  : [
          {id : "isWorking", type : "select", size: "sm", options: [
              {id : 0, label : "Choose", isDisabled : true},
              {id: 1, label: "Employed"}, 
              {id: 2, label: "Unemployed"}
          ]}            
      ]
  },
  {
      id      : "qEmpDate",
      type    : "question",
      condition : "isWorking:=:1",
      label   : "Date Employment Began",
      params  : [{id : "empdate", type : "date", condition : "isWorking:=:1", label : "Enter Date"}]
  },
  {
      id      : "qEmpHours",
      type    : "question",
      condition : "isWorking:=:1",
      label   : "How many hours per week do you work?",
      params  : [{ id : "hours", type : "number", size : "xs"}]
  },
  {
      id      : "qPayFreq",
      type    : "question",
      condition : "isWorking:=:1",
      label   : "How often are you paid?",
      params  : [
          {id : "paysched", type: "select", options : [
              {id : 0, label : "Choose", isDisabled : true},
              {id : 1, label : "Weekly"},
              {id : 2, label : "Bi-weekly"},
              {id : 3, label : "Twice a month"},
              {id : 4, label : "Monthly"}
          ]}
      ]
  },
  {
      id      : "qPayCalc",
      type    : "question",
      condition : "isWorking:=:1",
      label   : "How is your pay calculated?",
      params  : [
          {id : "salsched", type : "select", size: "sm", options : [
              {id : 0, label : "Choose", isDisabled : true},
              {id : 1, label : "Monthly Salary"},
              {id : 2, label : "Hourly Rate"},
              {id : 3, label : "Other"}
          ]},
          {id : "payrate", type : "currency", size: "sm", condition : "salsched:=:[1,2]", label : "Amount"},
          {id : "payrateO", type : "text", size: "md", condition: "salsched:=:3", label: "Please explain"}
      ]
  },
  {
      id      : "qEmpOcc",
      type    : "question",
      condition : "isWorking:=:1",
      label   : "What is your occupation?",
      params  : [
          {id : "occupation", type : "text", label : "Occupation", size: "md"},
      ]
  },
  {
      id      : "qEmpName",
      type    : "question",
      condition : "isWorking:=:1",
      label   : "What is the name of your employer?",
      params  : [
          {id : "employer", type : "text", label : "Employer name", size: "md"}
      ]
  },
  {
      id      : "qEmpAddr",
      type    : "question",
      condition : "isWorking:=:1",
      label   : "What is your Employer's address?",
      params  : [ {id : "empAddress", type : "text", label : "Address", size: "lg"} ]
  },
  {
      id      : "qUnempDate",
      type    : "question",
      condition : "isWorking:=:2",
      label   : "What date did you last work?",
      params  : [ {id : "unempDate", type : "date", size: "sm"} ]
  },
  {
      id      : "qUnempReason",
      type    : "question",
      condition : "isWorking:=:2",
      label   : "Why are you unemployed?",
      params  : [ 
          {id : "unempReason", type : "select", options : [
              {id : 0, label : "N/A"},
              {id : 1, label : "Disability"},
              {id : 2, label : "Involuntary layoff at work"},
              {id : 3, label : "Other"}
          ]},
          {id : "unempReasonOther", type : "text", size: "md", condition: "unempReason:=:3", label: "Other reason"},
      ]
  },
  {
      id      : "qHhCount",
      type    : "question",
      label   : "How many adults and children are in your household?",
      params  : [ 
          {id : "houseAdults", type : "number", size: "xs", label: "Adults"},
          {id : "houseKids", type : "number", size: "xs", label: "Kids"} 
      ]
  },
  {
      id      : "qEstIncOfOther",
      type    : "question",
      label   : "What do you believe the monthly gross income of the other party is?",
      params  : [ {id : "spouseEstIncome", type : "currency", size: "sm", label : "Their Est. Income"}]
  },
  {
      id      : "qLastTaxYear",
      type    : "question",
      label   : "What is the last tax year for which you have returns?",
      params  : [ {id : "lastTaxYear", type : "number", size: "xs", label : "Year"} ]
  },
  {
      id      : "qTaxAgi",
      type    : "question",
      label   : "What is your annual gross income for the last tax year?",
      params  : [ {id : "lastTaxYearIncome", type : "currency", size: "sm", label : "Your AGI"} ]
  },
  {
      id      : "qTaxAgiOther",
      type    : "question",
      label   : "What is the other party's annual gross income for the last tax year.",
      params  : [ {id : "lastTaxYearIncomeCo", type : "currency", size: "sm", label : "Their AGI"}]
  },

  //Income - Monthly
  {id : "incGross",          label : "Gross Monthly Income", desc : "(before taxes and deductions) from salary and wages..."},
  {id : "incSs",             label : "Social Security Benefits (SSA)", desc : "",
      children: [
          {id: "incSsSsdi", type: "checkbox", label: "SSDI", desc: "Disability Insurance - Entitlement Program"},
          {id: "incSsSsi", type: "checkbox", label: "SSI", desc: "Supplemental Income - Needs Based"}
      ]
  },    
  {id : "incUnemployment",   label : "Unemployment & Veterans Benefit"},
  {id : "incDisability",     label : "Disability & Worker's Compensation"},
  {id : "incPension",        label : "Pension & Retirement Benefits"},
  {id : "incInterest",       label : "Interest & Dividends"},
  {id : "incTanf",           label : "Public Assistance (TANF)"},
  {id : "incOtherMonthly",   label : "Other", isOther : true},

  //Income - Miscellaneous
  { id : "incRoyalty",     label : "Royalties, Trusts and Other Investments"},
  { id : "incRoyaltyOthers",    label : "Contributions from Others"},
  { id : "incDependents",    label : "Dependent Children's Monthly Gross Income",
      children: [
          {id: "incDependentsSource", type: "text", label: "Source of Income"}
      ]
  },
  { id : "incOtherSources",  label : "All Other Sources", desc : "i.e. personal injury settlement, non-reported income, etc."},
  { id : "incRental",        label : "Rental Net Income"},
  { id : "incExpAccount",    label : "Expense Accounts"},
  { id : "incChildSupport",  label : "Child Support from Others"},
  { id : "incOther1",        label : "Other", isOther : true},
  { id : "incSpousal",       label : "Spousal Support from Others"},
  { id : "incOther2",        label : "Other", isOther : true},

  //Deductions - Mandatory
  {id : "taxFedInc",      label  : "Federal Income Tax"},
  {id : "taxStateInc",    label  : "State/Local Income Tax"},
  {id : "taxPera",        label  : "PERA/Civil Service"},
  {id : "taxSs",          label  : "Social Security Tax"},
  {id : "taxMedicare",    label  : "Medicare Tax"},
  {id : "taxOther",       label  : "Other",  isOther    : true},

  //Deductions - Voluntary
  {id : "dedLifeDisabIns",    label : "Life and Disability Insurance"},
  {id : "dedStocks",          label : "Stocks/Bonds"},
  {id : "dedHealth",          label : "Health, Dental, Vision Insurance Premiums",
      children: [
          {id: "dedHealthCount", type: "number", label: "# of people covered on plan"}
      ]
  },
  {id : "dedRetirement",      label : "Retirement & Deferred Compensation"},
  {id : "dedChildCare",       label : "Child Care (deducted from salary)"},
  {id : "dedOther1",          label : "Other",    isOther    : true},
  {id : "dedFlex",            label : "Flex Benefit Cafeteria Plan"},
  {id : "dedOther2",          label : "Other",    isOther    : true},

  //Expenses - Housing
  {id : "mortgage1",          label : "1st Mortgage"},
  {id : "mortgage2",          label : "2nd Mortgage"},
  {id : "hseInsurance",       label : "Insurance (Home/Rental) & Property Taxes"},
  {id : "hseFees",            label : "Condo/Homeowner's/Maintenance Fees"},
  {id : "rent",               label : "Rent"},
  {id : "hseOther",           label : "Other", isOther : true},

  //Expenses - Utilities
  {id : "utilGas",            label : "Gas & Electricity"},
  {id : "utilWater",          label : "Water, Sewer, Trash Removal"},
  {id : "utilPhone",          label : "Telephone"},
  {id : "utilProperty",       label : "Property Care", description : "(Lawn, snow removal, cleaning, security system, etc."},
  {id : "utilInternet",       label : "Internet Provider, Cable & Satellite TV"},
  {id : "utilOther",          label : "Other", isOther : true},

  //Expenses - Food
  {id : "groceries",          label: "Groceries & Supplies"},
  {id : "diningOut",          label: "Dining Out"},

  //Expenses - Healthcare
  {id : "hthDoctor",          label : "Doctor & Vision Care"},
  {id : "hthDental",          label : "Dentist & Orthodontist"},
  {id : "hthMedicine",        label : "Medicine & RX Drugs"},
  {id : "hthTherapy",         label : "Therapist"},
  {id : "hthPremium",         label : "Premiums (if not paid by employer)"},
  {id : "hthOther",           label : "Other", isOther : true},

  //Expenses - Transportation
  {id : "vehPayment",         label : "Primary Vehicle Payment"},
  {id : "vehPayment2",        label : "Other Vehicle Payment"},
  {id : "vehUpkeep",          label : "Fuel, Parking and Maintenance"},
  {id : "vehInsurance",       label : "Insurance & Registration/Tax Payments", description : "(yearly amount / 12"},
  {id : "vehPubTrans",        label : "Bus & Commuter Fees"},
  {id : "vehOther",           label : "Other",    isOther : true},

  //Expenses - Children
  {id : "childClothing",  label : "Clothing & Shoes"},
  {id : "childCare",      label : "Child Care"},
  {id : "childExtra",     label : "Extraordinary Expenses (i.e. Special Needs, etc.)"},
  {id : "childMisc",      label : "Misc Expenses (i.e. Tutor, Books, Activities, Fees, Lunch, etc.)"},
  {id : "childTuition",   label : "Tuition"},
  {id : "childOther",     label : "Other",    isOther : true},

  //Expenses - Education
  {
      id      : "edStatus",
      type    : "question",
      label   : "Student status {edStatus}",
      params  : [
          {id : "edStatus", type : "select", size: "sm", options: [
              {id : 0, label : "n/a"},
              {id : 1, label : "Part Time"},
              {id : 2, label : "Full Time"}
          ]}
      ]
  },
  {id : "edTuition",      label : "Tuition, Books, Supplies, Fees, etc."},
  {id : "edOther",        label : "Other",    isOther : true},

  //Expenses - Maintenance
  {id : "maintThis",          label : "Maintenance (this family)"},
  {id : "maintChildThis",     label : "Child Support (this family)"},
  {id : "maintOther",         label : "Maintenance (other family)"},
  {id : "maintChildOther",    label : "Child Support (other family)"},

  //Expenses - Miscellaneous
  {id : "expRecreation",      label : "Recreation / Entertainment"},
  {id : "expPersonal",        label : "Personal Care (Hair, Nails, Clothing, etc.)"},
  {id : "expLegal",           label : "Legal / Accounting Fees"},
  {id : "expSubscriptions",   label : "Subscriptions (Newspapers, magazines)"},
  {id : "expCharity",         label : "Charity / Worship"},
  {id : "expMovies",          label : "Movies & Video Rentals"},
  {id : "expTravel",          label : "Vacation / Travel / Hobbies"},
  {id : "expInvest",          label : "Investments (not part of payroll deductions)"},
  {id : "expMemberships",     label : "Memberships / Clubs"},
  {id : "expHome",            label : "Home Furnishings"},
  {id : "expPets",            label : "Pets / Pet Care"},
  {id : "expSports",          label : "Sports Events / Participation"},
  {id : "expOther1",          label : "Other",    isOther : true},
  {id : "expOther2",          label : "Other",    isOther : true},
  {id : "expOther3",          label : "Other",    isOther : true},
  {id : "expOther4",          label : "Other",    isOther : true},
  {id : "expOther5",          label : "Other",    isOther : true},
  {id : "expOther6",          label : "Other",    isOther : true},
  {id : "expOther7",          label : "Other",    isOther : true},
  {id : "expOther8",          label : "Other",    isOther : true},

  //Debts - this will be a list
  {id : "dbtsName",       type : "text",      header : "Name of Creditor", shortHeader: "Name", size : "sm"},
  {id : "dbtsAccount",    type : "text",      header : "Account # (last 4 digits)", shortHeader: "Acc# (last 4)", size : "xs"},
  {id : "dbtsOwner",      type : "select",    header : "Owner", size : "xs", options : [
      {id : 0, label : "P - Petitioner"},
      {id : 1, label : "C/R - Co-Petitioner"},
      {id : 2, label : "J - Joint"}
  ]},
  {id : "dbtsReason",     type : "text",      header : "Reason Debt was incurred", shortHeader: "Reason", size: "md"},
  {id : "dbtsDate",       type : "date",      header : "Date of Balance", shortHeader: "Date", size : "xs"},
  {id : "dbtsBalance",    type : "currency",  header : "Balance", size : "xs", isSummed : true},
  {id : "dbtsPayment",    type : "currency",  header : "Minimum Monthly Payment", shortHeader: "Monthly Pmt", size : "xs", isSummed: true, isTotal : true},

  //Assets - Real Estate (List)
  {id : "reHas",          type : "checkbox"},
  {id : "reName",         type : "text",      header : "Address / Property Description and name of Creditor/Lendor", shortHeader: "Description", size : "md"},
  {id : "reOwner",        type : "select",    header : "Owner", size : "xs", options : [
      {id : 0, label : "P - Petitioner"},
      {id : 1, label : "C/R - Co-Petitioner"},
      {id : 2, label : "J - Joint"}
  ]},
  {id : "reValue",        type : "currency",  header : "Estimated Value as of Today", shortHeader : "Est Value", size : "xs", isSummed : true},
  {id : "reOwed",         type : "currency",  header : "Amount Owed", size : "xs", isSummed: true},
  {id : "reNet",          type : "currency",  header : "Net Value Equity", shortHeader: "Net Value", size : "xs", isSummed : true, isTotal : true, calculation :"reValue - reOwed"},

  //Assets - Vehicles
  {id : "vehHas",        type : "checkbox"},
  {id : "vehName",        type : "text",      header : "Year, Make, Model and name of Creditor/Lendor", shortHeader: "Description", size : "md"},
  {id : "vehOwner",       type : "select",    header : "Owner", size : "xs", options : [
      {id : 0, label : "P - Petitioner"},
      {id : 1, label : "C/R - Co-Petitioner"},
      {id : 2, label : "J - Joint"}
  ]},
  {id : "vehValue",       type : "currency",  header : "Estimated Value as of Today", shortHeader: "Est Value", size : "xs", isSummed : true},
  {id : "vehOwed",        type : "currency",  header : "Amount Owed", size : "xs", isSummed: true},
  {id : "vehNet",         type : "currency",  header : "Net Value Equity", shortHeader: "Net Value", size : "xs", isSummed : true, isTotal : true, calculation :"vehValue - vehOwed"},

  //Assets - Bank Accounts
  {id : "bnkHas",        type : "checkbox"},
  {id : "bnkName",        type : "text",      header : "Name of bank or financial institution", shortHeader: "Bank", size : "md"},
  {id : "bnkOwner",       type : "select",    header : "Owner", size : "xs", options : [
      {id : 0, label : "P - Petitioner"},
      {id : 1, label : "C/R - Co-Petitioner"},
      {id : 2, label : "J - Joint"}
  ]},
  {id : "bnkType",        type : "text",      header : "Account Type", size : "sm"},
  {id : "bnkNum",         type : "text",      header : "Account Number (last 4 digits)", shortHeader: "Acc # (last 4)", size : "xs"},
  {id : "bnkValue",       type : "currency",  header : "Balance as of today", shortHeader: "Balance", size : "xs", isSummed : true, isTotal : true},

  //Assets - Life Insurance
  {id : "lfInsHas",      type : "checkbox"},
  {id : "lfInsName",      type : "text",      header : "Name of Company / Beneficiary", size : "md"},
  {id : "lfInsOwner",     type : "select",    header : "Owner", size : "xs", options : [
      {id : 0, label : "P - Petitioner"},
      {id : 1, label : "C/R - Co-Petitioner"},
      {id : 2, label : "J - Joint"}
  ]},
  {id : "lfInsType",      type : "text",      header : "Type of Policy", size : "sm"},
  {id : "lfInsAmt",       type : "currency",  header : "Face Amount of Policy", size : "xs", isSummed : true},
  {id : "lfInsValue",     type : "currency",  header : "Cash Value today", size : "xs", isSummed : true, isTotal : true},

  //Assets - Household
  {id : "hhldHas",       type : "checkbox"},
  {id : "hhldName",       type : "text",      header : "Item", size : "md"},
  {id : "hhldOwner",      type : "select",    header : "Owner", size : "xs", options : [
      {id : 0, label : "P - Petitioner"},
      {id : 1, label : "C/R - Co-Petitioner"},
      {id : 2, label : "J - Joint"}
  ]},
  {id : "hhldHolder",     type : "select",    header : "Current Possession Held By", shortHeader: "Held by", size : "xs", options : [
      {id : 0, label : "P - Petitioner"},
      {id : 1, label : "C/R - Co-Petitioner"},
      {id : 2, label : "J - Joint"}
  ]},
  {id : "hhldValue",      type : "currency",  header : "Estimated Value today", shortHeader: "Est Value", desc : "What you could sell it for in it's current condition", size : "xs", isSummed : true, isTotal : true},
  
  //Assets - Investments (NOTE: This merges the main form and JDF 1111-SS, the supporting schedule, into one form)
  {id : "invHas",        type : "checkbox"},
  {id : "invName",        type : "text",       header : "Name or Item or Fund", shotHeader: "Name",    size : "md" },
  {id : "invOwner",      type : "select",      header : "Owner", size : "xs", options : [
        {id : 0, label : "P - Petitioner"},
        {id : 1, label : "C/R - Co-Petitioner"},
        {id : 2, label : "J - Joint"}
  ]},
  {id : "invShares",     type : "number",       header : "# Shares",    size : "xs" },
  {id : "invAcc",        type : "text",         header : "Acc # (last 4)",    size : "xs" },
  {id : "invValue",      type : "currency",     header : "Current Value", size : "xs", isSummed : true, isTotal : true},

  //Assets - Retirement
  {id : "retHas",       type : "checkbox"},
  {id : "retName",       type : "text",        header : "Name of Plan of Fund", shortHeader: "Name",    size : "md" },
  {id : "retOwner",      type : "select",      header : "Owner", size : "xs", options : [
        {id : 0, label : "P - Petitioner"},
        {id : 1, label : "C/R - Co-Petitioner"},
        {id : 2, label : "J - Joint"}
  ]},
  {id : "retType",      type : "text",         header : "Type (401K, IRA, 457, PERA, etc)", shortHeader: "Type",    size : "sm" },
  {id : "retAcc",       type : "number",       header : "Acc # (last 4)",    size : "xs" },
  {id : "retValue",     type : "currency",     header : "Current Value", size : "xs", isSummed : true, isTotal : true},

  //Assets - Miscellaneous
  {id : "miscHas",       type : "checkbox"},
  {id : "miscName",       type : "text",        header : "Item Name / Description",    size : "md" },
  {id : "miscOwner",      type : "select",      header : "Owner", size : "xs", options : [
        {id : 0, label : "P - Petitioner"},
        {id : 1, label : "C/R - Co-Petitioner"},
        {id : 2, label : "J - Joint"}
  ]},
  {id : "miscType",      type : "select",       header : "Type",    size : "md", options : [
        {id : 0, label : "Business Interests", key: "miscBi"},
        {id : 1, label : "Stock Options", key: "miscSo"},
        {id : 2, label : "Money / Loans owed to you", key: "miscLoans"},
        {id : 3, label : "IRS Refunds due to you", key: "miscIrs"},
        {id : 4, label : "Country Club & Other Memberships", key: "miscCC"},
        {id : 5, label : "Livestock, Crops, Farm Equipment", key: "miscFarm"},
        {id : 6, label : "Pending lawsuit or claim by you", key: "miscLgl"},
        {id : 7, label : "Accrued Paid Leave (sick, vacation, personal)", key: "miscLeave"},
        {id : 8, label : "Oil and Gas Rights", key: "miscOil"},
        {id : 9, label : "Vacation Club Points", key: "miscVaca"},
        {id : 10, label : "Safety Deposit Box / Vault", key: "miscDeposit"},
        {id : 11, label : "Trust Beneficiary", key: "miscTrust"},
        {id : 12, label : "Frequent Flyer Miles", key: "miscMiles"},
        {id : 13, label : "Education Accounts", key: "miscEdu"},
        {id : 14, label : "Health Savings Accounts", key: "miscHsa"},
        {id : 15, label : "Mineral and Water Rights", key: "miscWater"},
        {id : 16, label : "Other", key: "miscOther"},
    ], 
    isOptionTotals      : true,
    optionsTotalField   : "miscValue",
  },
  {id : "miscValue",     type : "currency",     header : "Estimated Value Today", size : "xs", isSummed : true, isTotal : true},


  //Assets - Separate Property
  {id : "sepHas",       type : "checkbox"},
  {id : "sepName",       type : "text",        header : "Name",    size : "md" },
  {id : "sepOwner",      type : "select",      header : "Owner", size : "xs", options : [
        {id : 0, label : "P - Petitioner"},
        {id : 1, label : "C/R - Co-Petitioner"},
        {id : 2, label : "J - Joint"}
  ]},
  {id : "sepType",      type : "text",          header : "Property Type",    size : "sm" },
  {id : "sepValue",     type : "currency",      header : "Estimated Value Today", size : "xs", isSummed : true, isTotal : true},

  //Summary - Net Monthly
  {id : "smryIncome",         label : "Income", type : "currency", regionId : "income"},
  {id : "smryDeductions",     label : "Deductions", type : "currency", regionId : "deductions"},
  {id : "smryNetIncome",      label : "Net Income", type : "currency", calculation : "income - deductions", color : "conditional"},
  {id : "smryExpense",        label : "Expenses", type : "currency", regionId : "expenses"},
  {id : "smryDebts",          label : "Debt Payments", type : "currency", regionId : "debts"},
  {id : "smryNetExpenses",    label : "Net Expenses", type : "currency", calculation : "expenses + debts", color : "danger"},
  {id : "smryNetMonthly",     label : "Monthly Net", type : "currency", calculation : "income - deductions - expenses - debts", color : "conditional"},

  //Summary - Net Worth
  {id : "smryAssets",     label : "Assets", type : "currency", regionId : "assets"},
  {id : "smryDebtBalance",label : "Debts", type : "currency", regionId : "debts[dbtsBalance]"},
  {id : "smryNetWorth",   label : "Net Worth", type : "currency", calculation : "assets - debts[dbtsBalance]", color : "conditional"},
];

export default FIELDS;