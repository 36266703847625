import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import { saveDataLocal, saveDataToServer } from 'store/actions/value-actions';

function Tabs({items, isHash, history, onChanged, ...otherProps}){
    const dispatch  = useDispatch();
    const statuses  = useSelector(state => state.values.regionStatus);
  
    const onSave  = async () => {
        await dispatch(saveDataLocal());
        await dispatch(saveDataToServer());
    }

    // const size      = parseInt(12 / items.length);
    return (
      <Grid container direction="row" justifyContent="space-evenly" className={otherProps.className}>
      {
        items.map(item => {
          return (
            <Grid key={item.id} item>
                <TabItem item={item} isComplete={statuses[item.id]} history={history} onSave={onSave} onChanged={onChanged}/>
            </Grid>
          );
        })
      }
      </Grid>
    );
}

Tabs.propTypes = {
    items       : PropTypes.arrayOf(PropTypes.object).isRequired,
    domain      : PropTypes.string,
    isHash      : PropTypes.bool
}

export default withRouter(Tabs);

const isActive = (location, item, path, isHash) => {
    if(isHash){
        if(location.hash === ""){
            return item.alias && path === location.pathname;
        }
        else{
            return path.replace("/", "") === location.hash;
        }
    }
    else{
        return path === location.pathname;
    }
}


const buildStyles = makeStyles(theme => ({
    atkTab    : {
        cursor      : "pointer",
        "& i" : {
          fontSize: 26,
        },
        "& span": {
          fontSize: 16,
        },
        "&.finished"    : {
            "& .tab-icon"   : {
                background      : `${theme.palette.primary.main}33`,  //`
                color           : theme.palette.primary.dark,
            },           
        },

        "&.active"     : {
            "& .tab-icon"   : {
                background      : `${theme.palette.secondary.dark} !important`, //`
                color           : `${theme.palette.secondary.contrastText} !important`,    //`
            },
            "& .tab-text"   : {
                color           : theme.palette.secondary.main,
                fontWeight      : "bold",
            },
        },
        // "&.finished"    : {
        //     background      : `${theme.palette.primary.main}33`, //`
        // }
    },
    tabIcon     : {
        border          : "2px solid #ced4da !important",
        borderRadius    : "50%",
        marginRight     : "0.5rem",
        height          : 40,
        width           : 40,
        lineHeight      : 40,
        padding         : "0.25rem",
    },
    tabText     : {

    },
    badge   : {
        "& svg"     : {
            fontSize        : 12,
            fontWeight      : 600,
        }
    }
}));

function TabItem({item, isComplete, history, onSave, onChanged}){
    const classes       = buildStyles();
    const isSelected    = isActive(history.location, item, item.path);
    
    const onClick       = async () => {
        if(onSave) await onSave();
        history.push(item.path);
        onChanged(item);
    };

    return(
      <Grid container alignItems="center" onClick={onClick} className={clsx(classes.atkTab, {"active": isSelected, "finished": isComplete})} title={item.tip || item.name}>
        <Grid item container alignItems="center" justifyContent="center" className={clsx(classes.tabIcon, "tab-icon")}>
            {isComplete && <CheckIcon fontSize="small"/>}
            {!isComplete && <i className={clsx(item.icon, "zmdi-hc")}></i>}
        </Grid>
        <span className="tab-text">{item.name}</span>
      </Grid>
    );
}