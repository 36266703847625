import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import HappyIcon from '@material-ui/icons/SentimentVerySatisfied';
import SadIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import BugIcon from '@material-ui/icons/BugReport';
import { ItemListRow } from 'components/index';
import { formatDateString } from 'helpers/general-helpers';

const FeedbackItem = ({item, config}) => {
  const classes   = buildStyles();

  function onDelete(){
    console.log("delete me");
  }

  return (
    <ItemListRow itemName={item.feedbackType} colSpan={6} onDelete={onDelete} isWorking={false}>
      <td>{formatDateString(item.sentAt, "M/D/YYYY h:mm A")}</td>
      <td>
        <Tooltip title={item.feedbackType}>
          <span className={clsx(classes.itemType, item.feedbackType)}>
            {ICONS[item.feedbackType]}
          </span>
        </Tooltip>
      </td>
      <td>{item.senderEmail}</td>
      <td>
        <Tooltip title={item.senderRole}>
          <Avatar className={clsx(classes.avatar, item.senderRole)}>
            {item.senderRole.toUpperCase().substring(0,1)}
          </Avatar>
        </Tooltip>
      </td>
      <td>{item.message}</td>
      <td></td>
    </ItemListRow>
  );
}

export default FeedbackItem;

const buildStyles   = makeStyles(theme => ({
  actionButton  : {
    marginLeft   : theme.spacing(0.5),
  },
  linkButton    : {
    textTransform     : "none",
    fontSize          : 14,
    fontWeight        : "400",
    padding           : "2px 6px",
  },
  itemType  : {
    height      : theme.spacing(3),
    width       : theme.spacing(3),
    "&.bug"  : {
      color     : theme.palette.error.main,
    },
    "&.happy"   : {
      color     : theme.palette.success.main,
    },
    "&.sad"  : {
      color     : theme.palette.warning.main,
    }   
  },
  avatar  : {
    height      : theme.spacing(3),
    width       : theme.spacing(3),
    fontSize    : 14,
    fontWeight  : "500",
    "& svg"   : {
      height    : 15,
      width     : 15,
      color     : theme.palette.grey[700],
    },
    "&.admin"  : {
      backgroundColor     : `${theme.palette.error.main}99`, //`
    },
    "&.user"  : {
      backgroundColor     : `${theme.palette.secondary.main}99`, //`
    },
    "&.reviewer"  : {
      backgroundColor     : `${theme.palette.warning.main}99`, //`
    }
  }
}));

const ICONS   = {
  happy   : <HappyIcon fontSize="small" />,
  sad     : <SadIcon fontSize="small" />,
  bug     : <BugIcon fontSize="small" />
};