import React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { saveRegionStatus } from 'store/actions/value-actions';
import { IfBlock } from 'components/index';

const RegionNav = ({region, history, onSave, reposition}) => {
  const classes       = buildStyles();
  const dispatch      = useDispatch();
  const statuses      = useSelector(state => state.values.regionStatus);
  const isComplete    = Boolean(statuses[region.id]);

  const onNav     = async (to) => {
      //Save changes before we nav
      await onSave();
      history.push(to);
      reposition();
  }

  function toggleComplete(e){
    const newVal  = e.target.checked;
    dispatch(saveRegionStatus(region.id, Boolean(newVal)));
  }

  if(region.next || region.prev){
      return (
        <Grid container className={classes.regionNav} alignItems="center">
          <Grid item xs={3} container justifyContent="flex-start">
            <NavButton condition={region.prev} position="left" iconPosition="left" onNav={() => onNav(region.prev)} classes={classes} label="Previous" icon="zmdi-arrow-left" tip="Go to the previous section" />
          </Grid>
          <Grid item xs={6} container justifyContent="center" className={classes.checkContainer}>
            <IfBlock condition={Boolean(region.next)}>
              <Grid item>
                <Checkbox checked={isComplete} size="small" color="primary" onChange={toggleComplete} />
              </Grid>
              <Grid item container direction="column">
                <Typography className={classes.checkLabel}>I'm done with this section</Typography>
                <Typography className={classes.checkSubLabel}>(You can always return and make changes)</Typography>
              </Grid>
            </IfBlock>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            <NavButton condition={region.next} position="right" iconPosition="right" onNav={() => onNav(region.next)} classes={classes} label="Save &amp; Next" icon="zmdi-arrow-right" tip="Go to the next section" />
          </Grid>
        </Grid>
      )
  }
  else{
    return null;
  }
}

export default RegionNav;

const buildStyles   = makeStyles(theme => ({
  regionNav   : {
    flexWrap      : "nowrap",
  },
  navButton   : {
    fontSize        : 15,
    fontWeight      : 300,
    marginRight     : theme.spacing(1),
    textTransform   : "none",
  },
  checkContainer  : {
    flexWrap        : "nowrap",    
  },
  checkLabel      : {
    fontSize        : 15,
    fontWeight      : 400,
    color           : theme.palette.primary.text,
    paddingTop      : 11,
  },
  checkSubLabel   : {
    fontSize        : 14,
    fontWeight      : 300,
    color           : theme.palette.grey[600],
  }
}));

function NavButton({condition, position, iconPosition, onNav, label, icon, tip, classes}){
  if(!Boolean(condition)){
      return null;
  }
  const float     = position === "left" ? "float-left" : "float-right";
  const content   = iconPosition === "right" ? <>{label} <i className={clsx("zmdi", icon, "ml-2")}></i></> : <><i className={clsx("zmdi", icon, "mr-2")}></i> {label}</>

  return (
      <Tooltip title={tip}>
          <Button className={clsx(classes.navButton, float)} onClick={onNav} color="primary" size="small">{content}</Button>
          {/* <button className={clsx("btn btn-link btn-md", float)} onClick={onNav}>{content}</button> */}
      </Tooltip>
  )
  
}