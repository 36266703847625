import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import nHelper from 'helpers/number-helper';
import fHelper from 'helpers/financials-helper';
import { InputField } from './';
import { withFieldChanged } from './with-field-changed';

class QuestionSection extends React.Component {

    render(){
        const { section, fields, values, onChange }   = this.props;
        const isCollapsed   = !fHelper.isConditionMet(section.condition, values);
        let rows            = null;
        
        if(!isCollapsed){
            const validFields   = _.compact(fields);
            rows                = _.map(fields, f => <QuestionRow key={f.id} field={f} values={values}/>);
        }

        return (
            <div className="section-div">
                <table className="table table-bordered table-condensed section-table question-section" onChange={this.props.onFieldChanged}>
                    <QuestionHeader section={section} values={values}/>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}

QuestionSection.propTypes   = {
    section     : PropTypes.object.isRequired,
    values      : PropTypes.object.isRequired,
    onChange    : PropTypes.func.isRequired
}

export default withFieldChanged(QuestionSection);

const QuestionHeader = ({section, values}) => {
    let titleRow        = null;
    
    if(section.title){
        let colSpan     = 2;
        // let headerField = null;
        // let descField   = null;

        // if(section.headerField){
        //      //TODO: deal with a header field for the section
        // }

        titleRow    = (
            <tr className="section-header">
                <td colSpan={colSpan} className="section-title-col">
                    <span className="section-title">{section.title}</span>
                    <span className="section-desc">{section.desc}</span>
                </td>
            </tr>
        )
    }

    return (
        <thead>
            {titleRow}
        </thead>
    );
}

const QuestionRow = ({field, values}) => {
    if(field.type == "separator"){
        return (
            <tr className="question-row separator-row">
                <td colSpan="2">{field.label}</td>
            </tr>
        );
    }
    else{
        if(field.condition){
            if(!fHelper.isConditionMet(field.condition, values)) return null;
        }

        const fields    = _.map(field.params, p => <InputField key={p.id} field={p} data={values}/>);

        return (
            <tr className="question-row">
                <td className="question-label">{field.label}</td>
                <td className="question-value">
                    {fields}
                </td>
            </tr>
        );
    }
}