import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { WaitButton, ErrorAlert } from 'components';
import { sendInvite } from 'store/actions/share-actions';
import { useStatus } from 'redux-action-status';

const TYPE_MAP = {
  client    : "Invite a client",
  lawyer    : "Invite your lawyer",
  share     : "Share with someone",
  wait      : "Invite from Waitlist",
  invite    : "New Invitation",
};

const InviteDialog = ({isOpen, onClose, type, sendCallback, waitlistItem}) => {
  const classes   = buildStyles();
  const [props, setProps]   = useState({fName: "", lName: "", email: "", message: ""});
  const status    = useStatus("requests");
  const dispatch  = useDispatch();

  useEffect(() => {
    if(waitlistItem){
      setProps(p => ({...p, fName: waitlistItem.name, email: waitlistItem.email, message: "Welcome to FigureEx!"}));
    }
  }, [waitlistItem]);

  const canSave   = props.fName.length > 0 && props.email.length > 0;

  const handleChange  = (e) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const onInvite = async () => {
    const model   = {
      firstName   : props.fName,
      lastName    : props.lName,
      email       : props.email,
      message     : props.message,
      requestType : type,   
    };

    if(sendCallback){
      sendCallback(model);
      setProps({fName: "", lName: "", email: "", message: ""});
      onClose(true);
    }
    else{
      const result  = await dispatch(sendInvite(model));
      if(result.isOk){
        setProps({fName: "", lName: "", email: "", message: ""});
        onClose(true);
      }
    }
  }

  const onDialogClose = (reason) => {
    if(!status.isWorking) onClose();
  }

  const title   = TYPE_MAP[type]; //isFromUser ? "Invite your lawyer" : "Invite user";

  return (
    <Dialog open={isOpen} onClose={onDialogClose} maxWidth="xs">
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>{title}</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container justifyContent="center" className={classes.contentRoot} spacing={2}>
          <ErrorAlert error={status.error} title="Invitation failed" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField id="fName" value={props.fName} onChange={handleChange} className={classes.textField} fullWidth label="First name *" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="lName" value={props.lName} onChange={handleChange} className={classes.textField} fullWidth label="Last name *" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="email" value={props.email} type="email" onChange={handleChange} className={classes.textField} fullWidth label="Email address *" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="message" value={props.message} onChange={handleChange} className={classes.textField} fullWidth multiline rows={3} label="Optional message" />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <WaitButton color="primary" size="small" isWaiting={status.isWorking} disabled={!canSave} onClick={onInvite}>Invite</WaitButton>
        <Button size="small" onClick={onClose} disabled={status.isWorking}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InviteDialog;

const buildStyles   = makeStyles(theme => ({
  title   : {
    padding     : theme.spacing(2),
    background  : theme.palette.primary.light,
    color       : theme.palette.primary.contrastText,
  },
  titleText   : {
    fontSize    : 20,
    fontWeight  : 500,
  },
  contentRoot  : {
    padding     : theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  textField           : {
    // width         : "100%",
    "& input, textarea, label"   : { 
      fontSize      : 16,      
    },
    "& label" : {
      color   : theme.palette.grey[400],
    }
  },
}));