import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function LandingFooter(){
  const classes   = buildStyles();

  return (
    <Grid container className={classes.footerGrid}>
      <Grid item xs={8} container alignItems="center">
        <NavLink to="/" exact={true} className={classes.footerLink} activeClassName={classes.activeLink}>Home</NavLink> |
        <NavLink to="/landing/learn-more" className={classes.footerLink} activeClassName={classes.activeLink}>Learn More</NavLink> |
        <NavLink to="/landing/lawyer" className={classes.footerLink} activeClassName={classes.activeLink}>For Lawyers</NavLink> |
        <NavLink to="/landing/about" className={classes.footerLink} activeClassName={classes.activeLink}>About</NavLink> |
        <NavLink to="/landing/contact" className={classes.footerLink} activeClassName={classes.activeLink}>Contact Us</NavLink>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end" alignItems="center">        
        <Typography className={classes.copyright}>
          ©2019 FigureEx
        </Typography>
      </Grid>
    </Grid>
  )
}

export default LandingFooter;

const buildStyles   = makeStyles(theme => ({
  footerGrid  : {
    height      : "60px",
    background  : theme.palette.grey[900],
    color       : theme.palette.grey[50],
    width       : "100%",
    padding     : theme.spacing(2),
    borderTop   : `2px solid ${theme.palette.grey[700]}`,
  },
  footerLink  : {
    fontSize    : 15,
    textDecoration  : "none",
    color       : theme.palette.grey[50],
    marginRight : theme.spacing(1),
    marginLeft  : theme.spacing(1),
    "&:hover, &:active"   : {
        color       : theme.palette.grey[100],
    },
  },
  activeLink  : {
    color       : `${theme.palette.grey[500]} !important`,
    "&:hover, &:active"   : {
      color       : theme.palette.grey[500],
  },
  },
  copyright   : {
    fontSize    : 15,
    color       : theme.palette.common.white[700],
    marginRight : theme.spacing(1),
  }
}));