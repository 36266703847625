import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DataTableFieldComments from 'sections/financials/parts/data-table-field-comments';

const CommentContainer = ({fieldKey, profile, isReadOnly, children}) => {
  const classes   = buildStyles();
  const headerId  = `cc-header-${fieldKey}`;   //`
  const bodyId    = `cc-body-${fieldKey}`;   //`

  return (
    <Accordion defaultExpanded={false} className={classes.headerPanel} elevation={0} TransitionProps={{ unmountOnExit: true }} disabled={isReadOnly}>
      <AccordionSummary className={classes.headerSummaryPanel} classes={{content: classes.headerContent}} expandIcon={null} aria-controls={bodyId} id={headerId}>
        {children}
      </AccordionSummary>
      <AccordionDetails className={classes.commentsPanel}>
        <DataTableFieldComments fieldKey={fieldKey} profile={profile}/>
      </AccordionDetails>
    </Accordion>
  );
}

export default CommentContainer;

const buildStyles   = makeStyles(theme => ({
  headerPanel  : {
    "&.Mui-expanded"  : {
      marginBottom    : `${theme.spacing(0.5)}px !important`,  //`
      marginTop       : `${theme.spacing(0.5)}px !important`,  //`
      boxShadow       : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "&.Mui-disabled"  : {
      background      : theme.palette.common.white,
    }
  },
  headerSummaryPanel  : {
    minHeight   : 24,
    padding     : 0, 
    "&.Mui-expanded"   : {
      background    : `${theme.palette.secondary.light}33`, //`
      borderBottom  : `1px solid ${theme.palette.secondary.main}`, //`
      paddingLeft   : theme.spacing(1),
      minHeight   : 24,
    },
    "&.Mui-disabled"  : {
      opacity      : 1,
    }
},
  headerContent   : {
    margin    : `${theme.spacing(0.5)}px ${theme.spacing(0)}px`, //`
    "&.Mui-expanded"   : {
      margin        : `${theme.spacing(1)}px ${theme.spacing(0)}px `, //`
    }
  },
  commentsPanel  : {
    background    : `${theme.palette.secondary.light}22`, //`
    padding       : theme.spacing(1),
    paddingBottom : theme.spacing(2),
  }, 
}));