import React from 'react';
import _ from 'lodash';
// import Select from '@material-ui/core/Select';
// import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import { STATES } from 'config/locations-config';
import { AtkSelect } from 'components';

const StateSelector = ({state, onSelected, waitlistOnly, ...otherProps}) => {

  const items     = waitlistOnly ? _.filter(STATES, s => s.isWaitList === true) : STATES;
  const states    = _.map(items, s => <MenuItem key={s.id} id={s.id} value={s.id}>{s.name}</MenuItem>);

  return ( 
    <AtkSelect id="state-combo" value={state} onChange={(e) => onSelected(e.target.value)} {...otherProps}>
      {states}
    </AtkSelect>
   );
}
 
export default StateSelector;