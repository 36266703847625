import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ItemList } from 'components';
import { useStatusMulti } from 'redux-action-status';
import { loadProfiles, loadAdmins, loadInvitations } from 'store/actions/admin-actions';
import { selectProfiles } from 'store/selectors/admin-selectors';
import UserItem from './user-item';
import InvitationDialog from '../common/invitation-dialog';
import UserDialog from '../common/user-dialog';

const UsersView = () => {
  const classes                   = buildStyles();
  const [view, setView]           = useState("all");
  const [invitation, setInvitation]   = useState(null);
  const [user, setUser]           = useState(null);
  const users     = useSelector(selectProfiles);
  const status    = useStatusMulti(["profiles", "admins", "invitations"]);
  const dispatch  = useDispatch();

  const filtered  = filterList(users, view);

  useEffect(() => {
    if(!users){
      onRefresh();
    }
  }, []);

  function onRefresh(){
    dispatch(loadProfiles());
    dispatch(loadAdmins());
    dispatch(loadInvitations());
  }

  function onAction(action, item){
    if(action === "view_invitation"){
      setInvitation(item);
    }
    else if(action === "view_user"){
      setUser(item);
    }
  }

  return (
    <>
      <ItemList config={listConfig} items={filtered} status={status} onRefresh={onRefresh} onFilter={v => setView(v)} onAction={onAction} ItemComponent={UserItem}/>
      <InvitationDialog isOpen={Boolean(invitation)} onClose={() => setInvitation(null)} invitation={invitation}/>
      <UserDialog isOpen={Boolean(user)} onClose={() => setUser(null)} profile={user} />
    </>    
  );
}

export default UsersView;

const buildStyles   = makeStyles(theme => ({
  
}));

function filterList(list, view){
  switch(view){
    case "all"  : return list;
    case "users"  : return _.filter(list, item => item.role === "user");
    case "reviewers"  : return _.filter(list, item => item.role === "reviewer");
    case "admins" : return _.filter(list, item => item.role === "admin");
    default: return list;
  }
}

const listConfig  = {
  title   : "Users",
  cols    : [
    {id: 0, label: "Uid", className: "",},
    {id: 10, label: "Name", className: "active",},
    {id: 15, label: "Email", className: ""},
    {id: 20, label: "Role", className: ""},
    {id: 30, label: "Last Login", className: ""},
    {id: 40, label: "Provider", className: ""},
    {id: 60, label: "", className: ""},
  ],
  filters : [
    {id: 0, label: "All", value: "all", icon: "clear_all" },
    {id: 10, label: "Users", value: "users", icon: "person"},
    {id: 20, label: "Reviewers", value: "reviewers", icon: "assignment_ind"},
    {id: 30, label: "Admins", value: "admins", icon: "supervisor_account"},
  ],
}