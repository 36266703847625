import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { SummarySection } from '../parts';
import { ErrorBoundary } from 'components';
import { selectProfile } from 'store/selectors/app-selectors';

const buildStyles    = makeStyles(theme => ({
    summaryContainer : {
        position    : "fixed",
        top         : 136,
        width       : "24%",
    },
    saveText        : {
        // marginTop       : theme.spacing(1),
        fontSize        : 16,
        fontWeight      : 400,
        color           : theme.palette.grey[500],
    },
    saveIcon    : {
        fontSize        : 20,
        fontWeight      : 400,
        color           : theme.palette.grey[500],
        marginRight     : theme.spacing(0.5),
    }
}));

export const SummaryContainer = ({regions, data, values}) => {
    const classes       = buildStyles();
    const summaries     = _.filter(regions, r => r.type === "summary");
    const summaryNodes  = _.map(summaries, s => <SummarySection key={s.id} region={s} regions={regions} values={values}/>);

    const profile   = useSelector(state => selectProfile(state));
    const dbProps       = useSelector(state => state.values.database);
    const localProps    = useSelector(state => state.values.local);
    const lastSave      = profile.isRemote ? dbProps.savedAt : localProps.savedAt;
    let lastSaveText    = "You have not made any changes during this session.  All changes are saved automatically as you work.";
    if(!!lastSave){
        lastSaveText    = `Last saved ${moment(lastSave).fromNow()}.  All changes are saved automatically as you work.`
    }

    return (
        <div className={clsx(classes.summaryContainer, "summary-container")}>
            <ErrorBoundary>
                {/* <SummaryNav/> */}
                {summaryNodes}
                <Tooltip title={lastSaveText}>
                    <Grid container justifyContent="center" alignItems="center">
                        <InfoIcon className={classes.saveIcon} fontSize="small"/>
                        <Typography className={classes.saveText}>Auto-save: On</Typography>
                    </Grid>
                </Tooltip>
            </ErrorBoundary>
        </div>
    );
}
