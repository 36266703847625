import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WelcomePanel from './welcome-panel';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SignIn from './auth/sign-in';
import SignUp from './auth/sign-up';
import { BeginImage } from 'config/images-config';
// import beginImg from './start.jpg';
import beginImg from './begin.jpg';
import { StatusCard } from 'components';
import { useStatus } from 'redux-action-status';

//attribution for start.jpg
// const attribution   = {
//   name    : "onlyyouqj",
//   handle  : "@onlyyouqj",
//   url     : `https://www.freepik.com/onlyyouqj`,
//   source  : "https://www.freepik.com/free-photos-vectors/background",
//   sourceName  : "Freepik.com"
// };

function WelcomeAuth(){
  const classes       = buildStyles();
  const [tabIndex, setTabIndex]   = useState(0);
  const status        = useStatus("signIn");
  const signInError   = status.error;
  // const signInError   = useSelector(state => state.app.signInError);
  const onTabChanged  = (e, val) => setTabIndex(val);

  return (
    <WelcomePanel 
      header="Welcome" 
      bgImage={beginImg} attribution={BeginImage} leftColSize={4}>

        <Grid container justifyContent="space-between" className={classes.contentGrid}>
          
          <Tabs value={tabIndex} indicatorColor="primary" centered textColor="secondary" onChange={onTabChanged} className={classes.spreadTabs}>
            <Tab label="Returning User" classes={{selected: classes.selectedTab}}/>
            <Tab label="New User" classes={{selected: classes.selectedTab}}/>
          </Tabs>

          <StatusCard isVisible={Boolean(signInError)} status="error" message={signInError?.message} />

          <Grid container justifyContent="center">
            {tabIndex === 0 && <SignIn changeTab={(index) => setTabIndex(index)}/>}            
            {tabIndex === 1 && <SignUp />}
          </Grid>
        </Grid>

    </WelcomePanel>
  );
}

export default WelcomeAuth;

const buildStyles   = makeStyles(theme => ({
  contentGrid   : {
    marginTop     : 0,
  },
  spreadTabs  : {
    width         : "100%",
    marginTop     : theme.spacing(1),
    marginBottom  : theme.spacing(1),    
  },
  selectedTab   : {
    background    : `${theme.palette.primary.light}11`,
  }
}));