import _ from 'lodash';
import { APP_ACTIONS, PROFILE_ACTIONS } from './action-types';
import { ONBOARDING_ACTIONS } from './onboarding-actions';
import { prepareForMerge, getNowString } from './action-helpers';
import { getProfile, watchFirebaseAuth } from 'config/firebase-config';
import { SAVED_KEY } from '../localstorage-keys';
import { trackEvent, Events } from 'helpers/analytics';
import rHelpers, { getCurrentDateTime } from 'store/reducers/reducer-helpers';
import { STATUS_FAILED } from 'redux-action-status';

const URL = {
    export          : "https://figure-ex-funcs.azurewebsites.net/api/export",
    // export          : "http://localhost:7071/api/export",
    account         : "account/current",
    notifications   : "services/app/notification/GetUserNotifications"
};

export const initialize = () => async(dispatch, getState) => {

    const appState  = getState().app;
    if(appState.isInitialized) return;      //don't re-initialize

    const onSignedIn    = async (user, profile) => dispatch(signedIn(user, profile));
    const onSignedUp    = async (user) => dispatch(signedUp(user));
    const onSignedOut   = async () => dispatch(signedOut());
    const onSignInError = async (error) => dispatch(signInError(error));

    watchFirebaseAuth(getState, onSignedIn, onSignedUp, onSignedOut, onSignInError);
    
    await dispatch({
        type        : APP_ACTIONS.SETTINGS_LOADED,
        firebase    : {
            type: "getSingle",
            collection: "settings",
            key: "onboarding",
        }
    });
    
    await dispatch({
        type        : APP_ACTIONS.INITIALIZE,
        localStorage    : {
            type        : "read",
            key         : SAVED_KEY,
            // type    : "read_list",
            // filter  : k => k === LOCATION_KEY || k === TOS_KEY || k === SAVED_KEY || k === SAFETY_KEY,
        }
    });

    const state     = getState().app;    
    return { lastSaved: state.lastSaved };   
}

export const loadRegionsForLocation = (locationKey) => async (dispatch, getState) => {
    try{
        let config        = await import(`config/locations/${locationKey.toLowerCase()}-config`);
        if(config){
            //pull the version of the config out of the file
            const version   = config.configVersion || "1.0.0";
            config          = _.omit(config, "configVersion");

            const regions   = _.map(_.keys(config), key => config[key]);
            return await dispatch({
                type        : APP_ACTIONS.LOAD_LOCATION,
                value       : regions,
                version     : version,
            });
        }
    }
    catch(ex){
        //Couldn't load the config for this region... need to send them back to select the region again
        console.log(`Failed to load region '${locationKey}.  Clearing out the region.`, ex)
        //otherwise, clear out any existing regions config.
        return await dispatch({
            type        : APP_ACTIONS.LOAD_LOCATION,
            value       : [],
        });
    }
}

const loadLocationRegions = async (locationKey, dispatch) => {

    if(locationKey){
        
        try{
            let config        = await import(`config/locations/${locationKey.toLowerCase()}-config`);
            if(config){
                //pull the version of the config out of the file
                const version   = config.configVersion || "1.0.0";
                const orderMap  = config.regionOrder;
                config          = _.omit(config, "configVersion", "regionOrder");
                
                //if there's a map of the region order, re-order things accordingly
                
                let regions   = _.map(_.keys(config), key => config[key]);
                if(orderMap){
                    regions = orderMap.map(key => regions.find(reg => reg.id === key));
                }

                return await dispatch({
                    type        : APP_ACTIONS.LOAD_LOCATION,
                    value       : regions,
                    version     : version,
                });
            }
        }
        catch(ex){
            //Couldn't load the config for this region... need to send them back to select the region again
            console.log(`Failed to load region '${locationKey}.  Clearing out the region.`, ex)
            // return await dispatch(changeLocation(null));
            //otherwise, clear out any existing regions config.
            return await dispatch({
                type        : APP_ACTIONS.LOAD_LOCATION,
                value       : [],
            });
        }
    }

    //otherwise, clear out any existing regions config.
    return await dispatch({
        type        : APP_ACTIONS.LOAD_LOCATION,
        value       : [],
    });
}

export function welcomeComplete(){
    return {
        type        : APP_ACTIONS.WELCOME_COMPLETE,
        value       : true,
    };
}

export const changeLocation = (newLocation) => async(dispatch, getState) => {
    const profile   = getState().app.profile;
    
    var result  = await dispatch({
        type        : APP_ACTIONS.CHANGE_LOCATION,
        value       : newLocation,
        firebase    : {
            type        : "updateSingle",
            collection  : "profiles",
            key         : profile.id,
            value       : {
                locationKey     : newLocation,
            },
        },
    });

    trackEvent(Events.location_changed, {value: newLocation});
    
    await loadLocationRegions(newLocation, dispatch);   //Get the region configuration for this location    

    //TODO: Need to write the location version to the store

    return result;
}

export const voteForLocation = (state, email, notify) => async (dispatch, getState) => {
    
    trackEvent(Events.location_voted, {label: state});

    const result  = await dispatch({
        type        : APP_ACTIONS.VOTED_LOCATION,
        value       : state,
        firebase    : {
            type        : "createOnly",
            collection  : "votes",
            value       : { state: state, email: email, notify: Boolean(notify) },
        },
    });

    return result;
}

export const updateProfile = (changes) => async (dispatch, getState) => {
    const profile  = getState().app.profile;
    //TODO: Change this to a whitelist?
    const safeChanges   = _.omit(changes, ["uid", "email", "inviteId", "provider"]);    //role...?  let them toggle in the future

    return dispatch({
        type        : PROFILE_ACTIONS.UPDATED,
        firebase    : {
            type        : "updateSingle",
            collection  : "profiles",
            key         : profile.id,
            value       : safeChanges,
        },
        changes     : safeChanges,
    });
}

export const sendContactMessage = (data) => async(dispatch, getState) => {
    
    const result    = await dispatch({
        type        : APP_ACTIONS.SEND_MESSAGE,
        firebase    : {
            type            : "create",
            collection      : "messages",
            key             : null,
            value           : data,
        }
    });

    return result;
}

export const sendFeedback = (fbType, message) => async(dispatch, getState) => {
    const profile     = getState().app.profile;
    const model     = {
        senderId        : profile.uid,
        senderEmail     : profile.email,
        senderRole      : profile.role,
        sentAt          : getNowString(),
        feedbackType    : fbType,
        message         : message,
    };

    const result    = await dispatch({
        type        : APP_ACTIONS.FEEDBACK_SENT,
        firebase    : {
            type        : "create",
            collection  : "feedback",
            key         : null,
            value       : model,
        },
        statusKey   : "feedback",
    });

    return result;
}

export const sendAudit = (event, props) => async(dispatch, getState) => {
    const profile       = getState().app.profile;
    if(!profile) return;
    
    const model     = {
        uid         : profile.uid,
        timestamp   : getCurrentDateTime(),
        event       : event,
    };

    if(props) model.props   = props;

    const result    = dispatch({
        type        : APP_ACTIONS.NO_OP,
        firebase    : {
            type        : "create",
            collection  : "audit_log",
            key         : null,
            value       : model,
        },
    });

    return result;
}

const signedIn = (user, profile) => async (dispatch, getState) => {

    const state     = getState().app; 

    if(!user || !profile){
        //NOTE: should not get here, but just in case
        console.log("signed in, but no user or profile");
        return;
    }
    
    if(!state.regions && profile?.locationKey){
        await loadLocationRegions(profile.locationKey, dispatch);   //Get the region configuration for this location    
    }
    if(profile && profile.photoURL && !profile.photo){
        try{
            profile.photo   = await fetch(profile.photoURL).then(r => r.blob());
        }
        catch(ex){
            console.error("failed to load photo for profile", ex);
        }
    }

    if(profile && profile.isAdmin === true){
        //profile says this user is an admin, need to validate that against the admins collection
        try{
            const admin    = await dispatch({
                type        : APP_ACTIONS.NO_OP,
                firebase    : {
                    type        : "getSingle",
                    collection  : "admins",
                    key         : profile.uid,
                },
                statusKey   : "signIn",
            });    

            if(!admin || !admin.isOk){
                profile.isAdmin = false;  //no entry in the admins collection
            }
        }
        catch(ex){
            profile.isAdmin = false;
        }
    }

    //Update the reducer with the user info
    const r1    = await dispatch({
        type    : APP_ACTIONS.SIGNED_IN,
        user    : user,
        profile : profile,
    });

    //Write the sign in to the audit log and the profile
    dispatch({
        type        : PROFILE_ACTIONS.UPDATED,
        firebase    : {
            type        : "updateSingle",
            collection  : "profiles",
            key         : profile?.id || "[no profile]",
            value       : {lastLogin : getCurrentDateTime()},
        },
    });
    
    dispatch(sendAudit("login"));
    
    //Check to see if the welcome stuff is necessary:
    if(profile && profile.locationKey && profile.tosAcceptedOn && !state.lastSaved){
        //No need for the welcome view
        await dispatch(
            {
                type        : APP_ACTIONS.WELCOME_COMPLETE,
                value       : true,
            }
        );
    }

    return r1;
}

const signedUp = (user, invite, isReviewer) => async (dispatch, getState) => {
    const inviteProps = getState().app.inviteProps;
    const { invite, isReviewer } = inviteProps ?? {};

    let profile;
    if(user){
        const pResult   = await dispatch(createProfile(user, invite?.id ?? "none", !!isReviewer));
        profile         = pResult;
    }

    if(user && invite && !invite.isWildcard){
        await dispatch(updateInvitation(invite, user));        
    }

    return dispatch(signedIn(user, profile));  
}

const signedOut = () => async (dispatch, getState) => {
    //Update the reducer with the user info
    const r1    = await dispatch({
      type        : APP_ACTIONS.SIGNED_OUT,
      statusReset : true,
    });    

    return r1;
}

const signInError = (error) => async (dispatch, getState) => {
    console.log("error whlie signing in.");
    
    await dispatch(signOut());  //make sure they're not partially logged in

    return await dispatch({
        type              : STATUS_FAILED,
        finishStatusKey   : "signIn",
        error             : error,
        });
    
};

export const signIn = (provider, username, password) => async(dispatch, getState) => {
    // await dispatch({type : APP_ACTIONS.SIGNING_IN});
    const appState  = getState().app;
    let user        = null;
    user        = appState.user;

    if(!user){
        const uResult = await dispatch({
            firebase    : {
                type        : "signIn",
                provider    : provider,
                username    : username,
                password    : password,
            },
            type        : APP_ACTIONS.NO_OP,    //the firebase watcher triggers the signed-in action, so don't do anything here
            statusKey   : "signIn",
        });

        if(uResult.status === "ok"){
            user    = uResult.user;
        }
        else if(uResult.status === "error"){
            dispatch({type : APP_ACTIONS.SIGN_IN_ERROR, value: uResult.error});
        }
    }

    return user;
}

export const signUpWithProvider = (provider, invite, isReviewer) => async(dispatch, getState) => {
    const appState  = getState().app;
    let user        = appState.user;
    // let profile     = appState.profile;

    if(invite || isReviewer){
        //Hold on to these for later
        await dispatch({
            type: APP_ACTIONS.STORE_SIGNUP_PROPS,
            value: {
                invite,
                isReviewer,
            },
        });
    }

    if(!user){
        const uResult = await dispatch({
            firebase    : {
                type        : "signIn",
                provider    : provider,
            },
            type        : APP_ACTIONS.NO_OP,    //the firebase watcher triggers the signed-in action, so don't do anything here
            statusKey   : "signIn",
        });

        user    = uResult.user;
    }

    return { user: user };

    //TODO: this has to happen after auth.signedIn has been triggered...

    //Check to see if there's already a profile for this user
    // let profile = await getProfile();

    // if(user && !profile){
    //     const pResult   = await dispatch(createProfile(user, invite.id, isReviewer));
    //     profile         = pResult.data;
    // }

    // if(user && invite && !invite.isWildcard){
    //     await dispatch(updateInvitation(invite, user));        
    // }

    // return { user: user, profile: profile };
}

export const signUpWithEmail = (username, password, invite, isReviewer) => async (dispatch, getState) => {
    const appState  = getState().app;
    let user        = appState.user;
    // let profile     = appState.profile;

    if(invite || isReviewer){
        //Hold on to these for later
        await dispatch({
            type: APP_ACTIONS.STORE_SIGNUP_PROPS,
            value: {
                invite,
                isReviewer,
            },
        });
    }

    if(!user){
        const uResult   = await dispatch({
            firebase    : {
                type        : "signUp",
                username    : username,
                password    : password,
            },
            type            : APP_ACTIONS.NO_OP,        //firebase watcher will issue the signed in action
            statusKey       : "signIn",            
        });

        user    = uResult.user;
    }

    return { user };
    // if(user && !profile){
    //     const pResult   = await dispatch(createProfile(user, invite.id, isReviewer));
    //     profile         = pResult.data;
    // }

    // if(user && invite && !invite.isWildcard){
    //     await dispatch(updateInvitation(invite, user));        
    // }

    // return { user: user, profile: profile };
}

export const createProfile  = (user, inviteId, isReviewer) => async(dispatch, getState) => {
    
    // dispatch({type: APP_ACTIONS.PROFILE_CREATING});

    const data  = {
        uid             : user.uid,
        displayName     : user.displayName || user.providerData[0]?.displayName || user.email,
        photoURL        : user.photoURL || user.providerData[0]?.photoURL,
        email           : user.email || user.providerData[0]?.email,
        provider        : user.providerData[0]?.providerId,
        inviteId        : inviteId,
        createdOn       : rHelpers.getCurrentDateTime(),
        role            : isReviewer ? "reviewer" : "user",
        tosAcceptedOn   : getNowString(),
    };
    const key   = user.email || user.uid;   //don't have the email for all provider types...?

    const result    = await dispatch({
        firebase    : {
            type        : "createOnly",     //can't do andRead because permission failure?  Probably because it's just creating the profile?
            collection  : "profiles",
            key         : key,
            value       : data,
        },
        type        : APP_ACTIONS.PROFILE_CREATED,
        statusKey   : "profile",
        // failType    : APP_ACTIONS.PROFILE_FAILED,
    });

    const profile = {id: result.id ?? result.data?.id, ...data, isLoaded: true};
    return profile;
}

export const signOut = () => async(dispatch, getState) => {
    return dispatch({
        firebase    : {
            type    : "signOut"
        },
        type    : APP_ACTIONS.SIGNING_OUT,
    });
}

export const updateInvitation = (invite, user) => async (dispatch, getState) => {

    const updates   = {
      acceptedOn  : rHelpers.getCurrentDateTime(), 
      acceptedBy  : user.email,
      userId      : user.uid, 
    };
  
    return dispatch({
        type        : ONBOARDING_ACTIONS.INVITE_UPDATED,
        firebase    : {
            type        : "updateSingle",
            collection  : "invitations",
            key         : invite.id,
            value       : updates,
        },
    });
}

export function toggleOpenDialog(forceClose = false){
    return {
        type    : APP_ACTIONS.TOGGLE_OPEN_DIALOG,
        meta    : {
            forceClose  : forceClose
        }
    };
}

export function fetchLoginInfo(){
    return {
        fetch   : {
            url     : URL.account,
            verb    : "GET",
            data    : null
        },
        types   : {
            action  : APP_ACTIONS.WORKING,
            success : APP_ACTIONS.RECEIVE_ACCOUNT,
            failure : APP_ACTIONS.ERROR
        },
        meta    : {
            status  : "Fetching login information..."
        }
    };
}

export function fetchNotifications(maxCount = 10){
    return {
        fetch   : {
            url     : URL.notifications,
            verb    : "POST",
            data    : {maxResultCount: maxCount}
        },
        types   : {
            action  : APP_ACTIONS.WORKING,
            success : APP_ACTIONS.RECEIVE_NOTIFICATIONS,
            failure : APP_ACTIONS.ERROR
        },
        meta    : {
            status  : "Fetching notifications..."
        }
    }
}

export function clearAllErrors(){
    return {
        type    : APP_ACTIONS.CLEAR_ALL_ERRORS
    };
}

export const exportData = (data, isPrimary) => async (dispatch, getStore) => {

    //Add subtotals and totals to the data...
    const appData       = getStore().app;
    const regions       = appData.regions;
    const profile       = appData.profile;
    const exportData    = prepareForMerge(data, regions);

    return dispatch({
        fetch   : {
            url         : URL.export,
            verb        : "POST",
            data        : exportData,
        },
        types   : {
            action  : APP_ACTIONS.EXPORTING,
            success : APP_ACTIONS.EXPORTED,
            failure : APP_ACTIONS.EXPORT_ERROR
        },
        meta    : {
            status      : "Exporting & Merging...",
            isPrimary   : isPrimary,
        },
        firebase    : {
            type        : "updateSingle",
            collection  : "profiles",
            key         : profile.id,
            value       : {
                lastExportedAt  : getNowString(),
                exportCount     : (profile.exportCount || 0) + 1,
            },
        },
        type    : APP_ACTIONS.NO_OP,
        statusKey   : "export",
    });
}

export const loadStripeCustomer = () => async (dispatch, getState) => {
    const state = await getState();
    const profile = state.app.profile;

    await dispatch({type: APP_ACTIONS.CUSTOMER_LOADING});
    const result = await dispatch({
        type: APP_ACTIONS.CUSTOMER_LOADED,
        failType: APP_ACTIONS.CUSTOMER_FAILED,
        firebase : {
            type: "getSingle",
            collection: "stripe_customers",
            subCollections: ["payments"],
            key: profile.uid
        },
    });

    return result;
}