import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuList from '@material-ui/core/MenuList';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { MenuListItem } from 'components';
import { signOut } from 'store/actions/app-actions';
import { selectCustomer, selectProfile } from 'store/selectors/app-selectors';
import { saveDataToServer } from 'store/actions/value-actions';
import { useAnchor } from 'helpers/hooks';

export default function ProfileMenu(){
  const classes               = buildStyles();
  const dispatch              = useDispatch();
  const user 	 		            = useSelector(state => state.app.user);		
  const customer              = useSelector(state => selectCustomer(state));
  const profile               = useSelector(selectProfile);
  const history               = useHistory();
  const [anchorEl, isOpen, onOpen, onClose, position]   = useAnchor("bottomLeft");

  if(!user) return null;
  
  async function onSignOut(){
    await dispatch(saveDataToServer());
    onClose();
    await dispatch(signOut());    
  }
  function onNavigate(url){
    history.push(url);
    onClose();
  }

  const hasPhoto    = Boolean(profile.photo) || Boolean(profile.photoUrl);
  const image       = hasPhoto ? <img className={classes.avatar} src={profile.photo ?? profile.photoUrl} noreferrerpolicy="no-referrer" alt="profile"></img> : <AccountCircleIcon className={classes.avatar}/>;
  const userType = profile.isAdmin ? "admin" : (customer?.isLicensed ? customer.licenses[0].displayName : "Free Edition");

  return (
    <>
      <Button aria-haspopup="true" color="primary" className={classes.menuButton} onClick={onOpen}>
        {image}{profile.displayName}
      </Button>          

      <Popover open={isOpen} anchorEl={anchorEl} {...position} onClose={onClose} >
        <Grid container justifyContent="center" className={classes.popoverContent}>
          
          <Grid item sm={12} container direction="column" alignItems="center" className={classes.profilePanel}>
            {hasPhoto && <img className={classes.largeAvatar} src={profile.photo ?? profile.photoUrl} noreferrerpolicy="no-referrer" alt="avatar"/> }
            {!hasPhoto && <AccountCircleIcon fontSize="large" color="primary"/> }
            <Typography className={classes.bold}>{profile.displayName}</Typography>
            <Typography>{profile.email}</Typography>
            <Typography>{userType}</Typography> {/* {profile.isAdmin ? "admin" : profile.role}</Typography> */}
          </Grid>

          <Grid item sm={8}>
            <Divider />
          </Grid>
          
          <Grid item sm={12} container className={classes.menuContent}>
            <MenuList className={classes.menuList}>
              {!customer?.isLicensed && <MenuListItem text="Upgrade to Premium" icon="shopping_cart" onClick={() => onNavigate("/my/subscription")}/> }
              {!customer?.isLicensed && (
                <Grid container justifyContent="center" style={{margin: "10px 0"}}>
                  <Grid item sm={8}>
                    <Divider />
                  </Grid>
                </Grid>
              )}
              <MenuListItem text="My Profile" icon="account_circle" onClick={() => onNavigate("/my")}/>
              <MenuListItem text="My Data" icon="storage"  onClick={() => onNavigate("/my/data")}/>
              {customer?.isLicensed && <MenuListItem text="My Subscription" icon="shopping_cart" onClick={() => onNavigate("/my/subscription")}/> }
              {/* <MenuListItem text="My Sharing" icon="group" onClick={() => onNavigate("/my/sharing")} /> */}
              {/* <MenuListItem icon="info" text="About FigureEx" onClick={() => onNavigate("/system/info")} /> */}
              {profile.isAdmin && <MenuListItem text="Administration" icon="settings" onClick={() => onNavigate("/admin")} />}
              <Grid container justifyContent="center" style={{margin: "10px 0"}}>
                <Grid item sm={8}>
                  <Divider />
                </Grid>
              </Grid>
              <MenuListItem text="Log Out" icon="exit_to_app" onClick={onSignOut}/>
            </MenuList>
          </Grid>
          
        </Grid>              
      </Popover>
    </>
  );
}

const buildStyles   = makeStyles(theme => ({  
  menuButton    : {
    cursor        : "pointer",
    padding         : "3px 6px",
    textTransform   : "none",
    fontSize        : 14,
    fontWeight      : 400,
    marginLeft      : theme.spacing(1.5),
  },
  avatar      : {
    border        : `0.5px solid ${theme.palette.grey[300]}`,
    borderRadius  : "50%",
    height        : "36.66px",
    width         : "36.66px",
    lineHeight    : "36.66px",
    marginRight     : theme.spacing(1),
  },
  largeAvatar   : {
    borderRadius  : "50%",
    height        : "60px",
    width         : "60px",
    lineHeight    : "60px",
    marginBottom  : theme.spacing(1),
  },
  popoverContent  : {
    maxWidth    : 300,
  },
  profilePanel: {
    padding         : `${theme.spacing(2)}px 0`,
    "& svg"     : {
      marginBottom  : theme.spacing(1),
    },
    "& p"       : {
      fontSize        : "16px",
      color           : theme.palette.grey[600],
      fontWeight      : 300,
    }
  },
  bold: {
    fontWeight: "600 !important",
  },
  menuContent   : {
    padding           : `0 5px`,
  },
  menuList    : {
    width   : "100%",
  },
}));