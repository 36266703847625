import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import authGoogle from './auth_service_google.svg';
import authFacebook from './auth_service_facebook.svg';
// import authTwitter from './auth_service_twitter.svg';
// import authMicrosoft from './auth_service_mslive.svg';

function AuthProviders({header, onAuth, isWorking}){

  const classes     = useStyles();

  const onSignIn  = async (event) => {
    const provider  = event.currentTarget.id;
    return onAuth(provider);    
  }

  return (

    <Grid container justifyContent="center" className={classes.root}>

      {header && 
        <Grid item sm={12} container justifyContent="center">
          <Typography className={classes.info}>{isWorking ? "Working..." : header}</Typography>
        </Grid>
      }

      <Grid item sm={9} container justifyContent="space-evenly">
        <Button id="google" color="primary" variant="outlined" className={classes.btn} onClick={onSignIn} disabled={isWorking}>
          <img src={authGoogle} className={classes.btnImage} alt="google"/>
          Google
        </Button>

        <Button id="facebook" color="primary" variant="outlined" className={classes.btn} onClick={onSignIn} disabled={isWorking}>
          <img src={authFacebook} className={classes.btnImage} alt="facebook"/>
          Facebook
        </Button>
      </Grid>

      {/* <Grid item sm={9} container justifyContent="space-evenly">
        <Button id="twitter" color="primary" variant="outlined" className={classes.btn} onClick={onSignIn}>
          <img src={authTwitter} className={classes.btnImage} />
          Twitter
        </Button>

        <Button id="microsoft" color="primary" variant="outlined" className={classes.btn} onClick={onSignIn}>
          <img src={authMicrosoft} className={classes.btnImage} />
          Microsoft
        </Button>
      </Grid> */}

    </Grid>
  );
}

export default AuthProviders;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
    textAlign     : "center",
  },
  btn       : {
    marginTop     : theme.spacing(1),
    minWidth      : 170,
  },
  btnImage  : {
    marginRight   : theme.spacing(1),
  },  
}));