import _ from 'lodash';
import firebase from 'config/firebase-config';
import { getFirestore } from 'config/firebase-config';
// import { asyncForEach } from 'helpers/general-helpers';

const firebaseActions   = {
  signIn    : signIn,
  signOut   : signOut,
  signUp    : signUp,

  create        : create,
  createOnly    : (info, action) => create(info, action, false),
  getSingle     : getSingle,
  updateSingle  : updateSingle,
  deleteSingle  : deleteSingle,

  getList       : getList,
};


const FirebaseMiddleware = store => next => async action => {
    if(!action.firebase){
        return next(action);
    }

    const { type }  = action.firebase;    
    const result    = await firebaseActions[type](action.firebase, action);

    const nextType  = (!result.isOk && action.failType) ? action.failType : action.type;
    //remove the firebase section so we don't re-run this process...
    const actionWithResult  = {
      ..._.omit(action, ["firebase", "failType"]),
      ...(result || {}),
      type  : nextType,
    };
    
    return next(actionWithResult);
    
}

export default FirebaseMiddleware;

async function create(info, action, andRead = true){
  try{
    let db      = getFirestore();
    let result  = null;

    if(info.key){
      //If there's a key, use that as the document key
      const doc   = db.collection(info.collection).doc(info.key);
      result  = await doc.set(info.value);

      //TODO: result doesn't have the object, need to get it after I create  
      if(andRead === true){
        result  = (await doc.get()).data();
        result  = { ...result, id: info.key, isLoaded: true };
      }
      else{
        result  = {id: info.key, ...info.value, isLoaded: false, ref: doc};
      }
    }
    else{
      //otherwise, let firestore create an id for me
      const doc  = await db.collection(info.collection).add(info.value);
      if(andRead === true){
        result  = {id: doc.id, ...(await doc.get()).data(), isLoaded: true};
      }
      else{
        result  = {id: doc.id, ...info.value, isLoaded: false, ref: doc};
      }
      //TODO: leave it un-hydrated and wait for someone else to hydrate it?
    }

    // console.log(`Created new item in db collection ${info.collection}`, result);
    return {
      status  : "ok",
      isOk    : true,
      data    : result,
    };
  }
  catch(ex){
    console.error(`Failed to create new item in db collection ${info.collection}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

export async function getSubCollection(docRef, sub){
  const snapshot  = await docRef.collection(sub).get();
  let items     = [];
  snapshot.forEach(snap => {
    items.push({id: snap.id, ...snap.data()});
  });

  return { [sub] : items };
}

async function getSingle(info, action){
  try{
    let db      = getFirestore();
    let result  = null;

    if(!info.key){
      throw new Error("Key is required for get.");
    }

    let docRef  = db.collection(info.collection).doc(info.key);
    result      = await docRef.get();
    if(result.exists){

      let data  = {id: result.id, ...result.data(), isLoaded: true, ref: result};
      if(info.subCollections){
        let scPromises  = info.subCollections.map(sub => getSubCollection(docRef, sub));
        const scResults  = await Promise.all(scPromises);
        scResults.forEach(r => data = {...data, ...r});
      }

      // console.log(`retrieved doc with key ${info.key}`, result);
      return {
        status  : "ok",
        isOk    : true,       
        data    : data,
      };
    }
    else{
      //Item doesn't exist
      // console.error(`Document with key ${info.key} not found`);
      return {
        status  : "error",        
        isOk    : false,       
        error   : { code: "not-found", message: "The requested item was not found."},
      };
    }
  }
  catch(ex){
    //TODO: handle permission denied as it may just not exist
    if(isInsufficientPermissions(ex) && info.mayNotExist === true){
      return {
        status  : "ok",
        isOk    : true,
        data    : null,
        wasPermissionDenied  : true,
      }
    }
    // console.error(`Failed to retrieve document with key ${info.key}`, ex);
    return {
      status  : "error",
      isOk    : false,       
        error   : ex
    };
  }
}

async function getList(info, action){
  try{
    let db      = getFirestore();
    // let result  = null;

    // if(!info.query){
    //   throw new Error("Query is required for getList.");
    // }

    let collection  = db.collection(info.collection);
    let snapshot    = null;
    if(info.query){
      //TODO: Support multiple queries for filtering (e.g. audit log date range)      
      // const queries = _.isArray(info.query[0]) ? info.query : [info.query];
      // collection    = _.reduce(queries, (coll, qu, i) => {
      //   return coll.where(qu[0], qu[1], qu[2])
      // }, collection);
      collection    = collection.where(info.query[0], info.query[1], info.query[2]);
      // snapshot      = await cQuery.get();
    }
    if(info.order){
      collection    = collection.orderBy(info.order[0], info.order[1] || "asc");
    }
    
    snapshot      = await collection.get();
    
    let list        = [];
    snapshot.forEach(doc => {
      let item = null;
      if(info.hydrate === true){
        const data  = doc.data();
        item  = {id: doc.id, isLoaded: true, ...data, ref: doc.ref, };
      }
      else{
        item  = {id: doc.id, isLoaded: false, ref: doc, }; //...data};
      }
      // const item  = {id: doc.id, isLoaded: false, ref: doc, }; //...data};
      list.push(item);
    });
    
    if(info.hydrate && info.subCollections){
      //Enumerate the items and then enumerate the 
      for(let i = 0; i < list.length; i++){
        const itm = list[i];
        for(let j = 0; j < info.subCollections.length; j++){
          const sub   = info.subCollections[j];
          const coll  = await getSubCollection(itm.ref, sub);
          list[i]   = {...itm, ...coll};
        }
      }      
    }

    return {
      status  : "ok",
      isOk    : true,
      data    : list,
    };
    
    //No results? or does snapshot return an empty array?
    // console.error(`No results found`);
    // return {
    //   status  : "error", 
    //   isOk    : false,       
    //   error   : { code: "not-found", message: "The requested item was not found."},
    // };

  }
  catch(ex){
    console.error(`Failed to retrieve documents for query`, ex);
    return {
      status  : "error",
      isOk    : false,       
      error   : ex
    };
  }
}

async function updateSingle(info, action){
  try{
    let db      = getFirestore();
    // let result  = null;

    if(!info.key){
      throw new Error("Key is required for update.");
    }

    let docRef    = db.collection(info.collection).doc(info.key);
    await docRef.update(info.value);
    const doc     = await docRef.get();
    return {
      status  : "ok",
      isOk    : true,
      data    : { id: info.key, ...doc.data(), isLoaded: true, ref: doc }
    };
  }
  catch(ex){
    console.error(`Failed to update document with key ${info.key}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

async function deleteSingle(info, action){
  try{
    let db      = getFirestore();
    
    if(!info.key || !info.collection){
      throw new Error("Collection and Key and required for delete.");
    }

    //delete the item from the collection
    const doc   = db.collection(info.collection).doc(info.key);
    try{
      const result  = await doc.get();  //see if the document exists
      if(result.exists){
        await doc.delete();
      }
    }
    catch(ex){
      console.log("error trying to get document for delete", ex);
      return {
        status  : "error",
        isOk    : false,
        error   : ex
      };
    }

    // console.log(`Deleted item from db collection ${info.collection}`);
    return {
      status  : "ok",
      isOk    : true,
      data    : null,
    };
  }
  catch(ex){
    console.error(`Failed to delete item in db collection ${info.collection}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

async function signUp(info, action){
  let user  = firebase.auth().currentUser;
  if(user) return { user: user }
  else{    
    try{
      //NOTE: this will also trigger onAuthChanged in firebase-config
      const result  = await firebase.auth().createUserWithEmailAndPassword(info.username, info.password);
      return {
        ...result,
        isOk    : true,
        status  : "ok",
        user    : result.user,
      };
    }
    catch(ex){
      return {
        isOk      : false,
        status    : "error",
        error     : ex,
      };
    }
  }
}

async function signIn(info, action){
  const user  = firebase.auth().currentUser;
  if(user){ 
    return  { status: "ok", user: user, isAreadySignedIn: true };  
  }
  else{
    try{
      if(info.provider){
        const provider      = PROVIDERS[info.provider](); //new firebase.auth.GoogleAuthProvider();
        const response      = await firebase.auth().signInWithPopup(provider);
        return response;
      }
      else{
        const response      = await firebase.auth().signInWithEmailAndPassword(info.username, info.password);
        return response;
      }
    }
    catch(ex){
        console.error("Exception signing in: ", ex);
        return { status: "error", error  : ex };
    }
  }
}

async function signOut(info, action){
  const user  = firebase.auth().currentUser;
  if(user){
    await firebase.auth().signOut();
    return { status: "ok", data : null };
  }
}

const PROVIDERS   = {
  google      : () => { return new firebase.auth.GoogleAuthProvider(); },
  facebook    : () => { return new firebase.auth.FacebookAuthProvider(); },
  twitter     : () => { return new firebase.auth.TwitterAuthProvider(); },
  microsoft   : () => { return new firebase.auth.OAuthProvider("microsoft.com"); },
};


function isInsufficientPermissions(error){
  return error.message === "Missing or insufficient permissions.";
}