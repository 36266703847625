import _ from 'lodash';
import fHelper from 'helpers/financials-helper';
import { VALUES_VERSION } from '../localstorage-keys';
import rHelper from '../reducers/reducer-helpers';

//----------------------
// Transforms the pagination structure into the correct format
//----------------------
export const transformPagination = (pagination) => {
    if(!pagination) return {};  //no pagination info
    if(pagination.offset && pagination.limit) return pagination;    //already transformed
    if(!pagination.pageNum || !pagination.pageSize) throw new Error("invalid pagination information passed to transformPagination");

    return {
        offset  : (pagination.pageNum-1) * pagination.pageSize,
        limit   : pagination.pageSize
    };
}

//----------------------
// Adds the query parameters and pagination information to the url
//----------------------
export const addQueryParameters = (url, pagination, queryParameters) => {
    
    if(pagination == null && queryParameters == null) return url;
    
    const myPagination  = transformPagination(pagination);    
    const allParams     = _.merge({}, myPagination, queryParameters);
    const qps           = _.compact(_.map(allParams, (value, key) => { return value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : null}));
    const qstring       = qps && qps.length > 0 ? qps.join("&") : null;
    
    return url + (qstring ? `?${qstring}` : "");
}

//----------------------
// Transforms the raw data into the format to send to the merge function
//----------------------
export const prepareForMerge = (data, regions) => {
    let totals          = {};

    _.forEach(regions, region => {
        if(region.totals){

            region.sections.forEach(section => {
                const secId     = `t_${region.id}_${section.id}`;
                totals[secId]   = fHelper.calculateSectionTotal(section, data);

                //Check to see if there are other fields that need to be totaled
                const otherTotals   = fHelper.getOtherTotals(region, section, data);
                if(otherTotals) totals = {...totals, ...otherTotals};

                //Check for list row total fields
                if(section.type === "list" && section.rowTotalFields){
                    const updatedRows   = fHelper.addRowTotals(section, data);
                    data[section.id]    = updatedRows;
                }

                if(section.type === "list" && section.addOptionNames){
                    const withNames   = fHelper.addRowOptionNames(section, data);
                    data[section.id]  = withNames;
                }
            });

            const rId           = `t_${region.id}`;
            totals[rId]         = fHelper.calculateRegionTotal(region, data);
        }
        else if(region.type === "summary"){
            //this is a summary section, so need to handle it a little different
            const fields    = fHelper.getFields(region.fields);
            _.forEach(fields, field => {
                let total   = 0;
                if(field.calculation){
                    total   = fHelper.calculateSummaryField(field, data, regions);
                }
                else{
                    total   = fHelper.calculateSummaryRegion(field, data, regions);
                }

                const rId   = `s_${field.id}`;
                totals[rId] = total;
            });
        }
    });

    return {...data, ...totals};
}

//----------------------
// Converts the data into a blob, and triggers a download
//----------------------
export const downloadRaw = (state) => {

    const preped    = prepareValuesLocal(state);

    //Create a blob with the json data
    const blob = new Blob([JSON.stringify(preped, null, 2)], {type : 'application/json'});
    const fileName  = "FigurEx-raw.json";

    var linkElement = document.createElement('a');
    try {
        //To get the file to download, need to create a virtual link pointed to the file url and "click" it.
        var url = window.URL.createObjectURL(blob);
        linkElement.setAttribute('href', url);
        linkElement.setAttribute("download", fileName);

        var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        });
        linkElement.dispatchEvent(clickEvent);

        return true;    //indicate it worked

    } catch (ex) {
        console.log(ex);
        return false;
    }
}

//----------------------
// Prepares the data to be downloaded / saved
//----------------------
export const prepareValues = (state) => {
    const vData     = state.values;
    const aData     = state.app;
    const timestamp = rHelper.getCurrentDateTime();

    const values    = {
        appVersion  : VALUES_VERSION,
        uid         : state.app.profile.uid,
        reviewers   : vData.reviewers || [],
        values      : _.omit(vData, ["meta", "local", "database", "isDirty", "a_isLoaded", "a_lastModified", "reviewers", "reviewerStatus"]),
        meta        : {
            locationKey     : aData.profile.locationKey,
            locationVersion : aData.locationVersion,
            lastSaved       : vData.database.savedAt || vData.local.savedAt || "",
            preparedAt      : timestamp,
        },
        
    }

    return values;
}

//----------------------
// Prepares the data to be downloaded / saved
//----------------------
export const prepareValuesLocal = (state) => {
    const vData     = state.values;
    const aData     = state.app;
    const timestamp = rHelper.getCurrentDateTime();

    const values    = {
        appVersion  : VALUES_VERSION,
        values      : _.omit(vData, ["meta", "local", "database", "isDirty", "a_isLoaded", "a_lastModified", "reviewers", "reviewerStatus"]),
        meta        : {
            locationKey     : aData.profile.locationKey,
            locationVersion : aData.locationVersion,
            lastSaved       : vData.database.savedAt || timestamp,
            preparedAt      : timestamp,
        },
        
    }

    return values;
}

export function getNowString(){
    return new Date().toISOString();
}

export function getNow(){
    return new Date();   
}