import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextDivider from '../../../components/text-divider';
import AuthProviders from './auth-providers';
import StandardLogin from './standard-login';
import Invitation from './invitation';
// import UserType from './user-type';
import { signUpWithEmail, signUpWithProvider } from 'store/actions/app-actions';
import { useQuery } from 'helpers/hooks';
import { useStatusMulti } from 'redux-action-status';

const NON_INVITE = {
  id: "none",
  isWildcard: true,
}

function SignUp(){
  const query       = useQuery();
  const [userType, setUserType]   = useState(query.get("type") || "user");   //other option is "reviewer"
  const status      = useStatusMulti(["signIn", "profile"]);
  const inviteRequired = useSelector(state => state.app?.settings?.isInviteRequired);
  const invite      = useSelector(state => state.onboarding.invitation);
  const dispatch    = useDispatch();
  const isReviewer  = Boolean(userType === "reviewer");
  const classes     = useStyles();
  
  const onAuthenticate = async (provider, username, password) => {
    const myInvite = inviteRequired ? invite : NON_INVITE;

    if(provider){
      await dispatch(signUpWithProvider(provider, myInvite, isReviewer));
    }
    else if(username && password){
      await dispatch(signUpWithEmail(username, password, myInvite, isReviewer));
    }
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      
      {inviteRequired && !invite && <Invitation /> }

      {(invite || !inviteRequired) && 
        <Grid container justifyContent="center">
          
          <Grid item sm={8} container justifyContent="center" className={classes.infoRow}>
            {inviteRequired && <Typography className={classes.info}>Your invitation code has been accepted. Choose an option below to create your account.</Typography>}
            {!inviteRequired && <Typography className={classes.info}>Welcome to FigureEx. Please choose from the options below to create your account.</Typography>}
          </Grid>
          {/* <Grid item sm={8} container justifyContent="center" className={classes.userTypeGrid}>
            <UserType value={userType} setValue={setUserType} />
          </Grid>

          <TextDivider text="sign up with" cols={12} textCols={4}/> */}

          <AuthProviders onAuth={onAuthenticate} isWorking={status.isWorking}/>
          <TextDivider text="or" cols={8} />
          <StandardLogin isConfirm={true} onAuth={(email, pwd) => onAuthenticate(null, email, pwd)} isWorking={status.isWorking}/>
          
          {/* <Grid container className={classes.tosGrid} justifyContent="flex-end">
              <Typography className={classes.text}>
                By creating an account, you agree to our <a href="/legal/terms-of-service" target="_blank">Terms of Service</a> and our <a href="/legal/terms-of-service#privacy" target="_blank">Privacy Policy</a>
              </Typography>
            </Grid> */}
        </Grid>
      }

    </Grid>
  );
}

export default SignUp;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  infoRow   : {
    marginBottom  : theme.spacing(1),
  },
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
    textAlign     : "center",
  },
  userTypeGrid  : {
    marginBottom  : theme.spacing(2),
  },
  userTypeButton  : {
    margin    : `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    fontSize  : 16,
  },
  tosGrid   : {
    marginTop     : theme.spacing(2),
  },
  text   : {
    fontSize      : 14,
    color         : theme.palette.grey[600],
    marginTop     : theme.spacing(1),
    marginBottom  : theme.spacing(1),
    "& a, a:hover"  : {
      // textDecoration  : "none",
      color       : `${theme.palette.primary.light}cc`, //`
    }
  },
  
}));


