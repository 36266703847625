import { createTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

const fontList  = ['"Nunito Sans"', 'Roboto', 'sans-serif'];

//Customizations for the default Material UI Theme go here
const themeCustomizations   = {
    breakpoints     : {
        values  : {
            xs      : 0,
            sm      : 750,
            md      : 960,
            lg      : 1280,
            xl      : 1920,
        },
    },
    palette: {
        primary     : teal,
        secondary   : blue,
        error       : {
            light       : "#fdd9d7",
            main        : "#7f231c",
            dark        : "#fccac7",
        }, 
    },
    typography  : {
        //Override the default fonts and font weights
        fontFamily                 : fontList.join(','),
        fontSize                   : 14,
        fontWeightLight            : 300,
        fontWeightRegular          : 400,
        fontWeightMedium           : 600,
        useNextVariants            : true,
        suppressDeprecationWarnings: true,
        htmlFontSize               : 12,
    },
    overrides   : {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#f4f4f4',
                    fontSize: 14,
                    fontWeight: 400
                },
            },
        },        
        MuiIconButton  : {
            root    : {
                "&:focus"   : {
                    outline : 0,
                },
            },            
        },
        MuiButton   : {
            root    : {
                "&:focus"   : {
                    outline : 0,
                },
                color       : grey[600],
            },
            outlined : {
                "&:focus"   : {
                    outline : 0,
                },
            },
            textSizeSmall   : {
                fontSize    : 16,
            },
            sizeSmall   : {
                fontSize    : 16,
            }
        },
        MuiTabs     : {
            root    : {
                "& button:focus"    : {
                    outline     : 0,
                },
            },
        },
        MuiFilledInput  : {
            root    : {
                backgroundColor     : "unset",
            },
            input   : {
                paddingTop  : "10px",
                background  : "#0096881A !important", //#2979ff33 !important",
                borderRadius: "5px 5px 0 0 !important",
                "&$disabled" : {
                    background      : "#fafafa !important",                     
                },
            },                        
        },  
        MuiSelect   : {
            root  : {
                "&$focused" : {
                    background  : "unset",
                }
            }
        }      
    }
};

//-----------------
// Creates and returns the custom theme for the Portal
export const buildTheme = () => {
    const customized    = createTheme(themeCustomizations);
    return customized;
};

