import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import helper from 'helpers/financials-helper';
import nHelper from 'helpers/number-helper';
import { Section, RegionNav } from './';
import { NotFound, ErrorBoundary } from 'components';
import { saveDataLocal, saveDataToServer, saveRegionStatus } from 'store/actions/value-actions';

const Region = (props) => {
    const dispatch  = useDispatch();
    const statuses  = useSelector(state => state.values.regionStatus);
    const {regions, location, values, onChange, history, reposition}    = props;    
        
    const region       = _.find(regions, r => r.path === location.pathname);
    if(!region) return <NotFound/>;

    const isComplete    = Boolean(statuses[region.id]);
    const _onChange = async (section, field, value, details, index) => {
        if(isComplete){
            await dispatch(saveRegionStatus(region.id, false));
        }
        return onChange(region.id, section, field, value, details, index);
    };

    const onSave = async () => {
        await dispatch(saveDataLocal());
        await dispatch(saveDataToServer());
    }
        
    const sections  = region.sections.map(section => <Section key={section.id} regions={regions} region={region} section={section} values={values} onChange={_onChange}></Section>);
    const regionClass   = `region-${region.id}`;    //`

    return (
        <div className={clsx("region region-view", regionClass)}>
            <div id={region.id} className="jr-card region">
                <div className="jr-card-header">
                    <h2 className="region-title">
                        {/* {isComplete ? <CheckCircleOutlineIcon fontSize="small"/> : <RadioButtonUncheckedIcon fontSize="small"/>} */}
                        {region.title}
                        <RegionTotal region={region} data={values}/>
                    </h2>
                    <span className="region-desc">
                        {region.desc}
                    </span>                
                </div>
                <div className="jr-card-body">
                    <ErrorBoundary>
                        <Grid container>
                            {sections}
                        </Grid>
                        <RegionNav region={region} history={history} onSave={onSave} reposition={reposition}/>
                    </ErrorBoundary>
                </div>
            </div>        
        </div>
    );
}

export default withRouter(Region);

const RegionTotal = ({region, data}) => {
    let topTotal        = null;
    if(region.totals){
        const total     = nHelper.asCurrency(helper.calculateRegionTotal(region, data), "$0");
        topTotal        = <span className="total-top float-right font-weight-bold">{total}</span>;
    }

    return topTotal;
}