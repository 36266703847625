import _ from 'lodash';
import { APP_ACTIONS } from '../actions/action-types';
import { CLIENT_ACTIONS } from '../actions/client-actions';
import helpers, { DEFAULT_STATUS, workingStatus, finishedStatus, failedStatus } from './reducer-helpers';

const INIT_STATE  = {
  clientStatus    : {...DEFAULT_STATUS},
  clientData      : null,
};

//==================
// Reducer Methods

//#region Client Data
function clientDataLoading(state, action){
  return {
    ...state,
    ...workingStatus("clientStatus"),
  };
}

function clientDataLoaded(state, action){
  return {
    ...state,
    clientData  : {
      ..._.pick(action.data, ["id", "appVersion", "meta", "uid"]),
      values  : {
        a_isLoaded  : true,
        ...action.data.values,
      },
    },
    ...finishedStatus("clientStatus", action.data),
  }
}

function clientDataFailed(state, action){
  return {
    ...state,
    ...failedStatus("clientStatus", action.error),
  };
}
//#endregion

function onSignedOut(state, action){
  return {...INIT_STATE };
}

//==================
// Reducer creation

const clientReducer  = {
  [CLIENT_ACTIONS.CLIENT_LOADING]   : clientDataLoading,
  [CLIENT_ACTIONS.CLIENT_LOADED]    : clientDataLoaded,
  [CLIENT_ACTIONS.CLIENT_FAILED]      : clientDataFailed,

  [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

export default helpers.createReducer(INIT_STATE, clientReducer);