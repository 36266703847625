import React, { useState } from 'react';
// import _ from 'lodash';
// import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ReviewSection from './review-section';
import DocsSection from './docs-section';

const steps   = [
  "Review",
  "Export"
];

function FinalSection({regions, region, section, values, fields, classes}){
  const [step, setStep] = useState(0);
  const isExporting     = useSelector(state => state.app.isExporting);

  const rSection        = region.sections[1];

  return (
    <Grid item container className={classes.root}>
      <Grid item container className={classes.options} justifyContent="center">
        <Tabs value={step} onChange={(e, val) => setStep(val)} indicatorColor="primary" textColor="primary">
          <Tab icon={<AssignmentTurnedInIcon fontSize="small"/>} label="Review"/>
          <Tab icon={<GetAppIcon fontSize="small"/>} label="Export"/>          
        </Tabs>
      </Grid>

      <Grid item container className={classes.stepContent}>
          {step === 0 && <ReviewSection regions={regions} region={region} section={rSection} values={values} fields={fields}/> }
          {step === 1 && <DocsSection section={section} /> } 
      </Grid>
    </Grid>
  );
}

const styles  = (theme => ({
  root  : {

  },
  congrats  : {
    fontSize    : 22,
    fontWeight  : 500,
    color       : theme.palette.secondary.main,   
    margin      : theme.spacing(1), 
    // textAlign   : "center",
  },

  stepContent   : {
    marginTop       : theme.spacing(2),
  },
  buttonIcon      : {
    marginRight   : theme.spacing(1),
  },
  instructions  : {
    // textAlign     : "center",
    fontSize      : "16px",
    "& .bold"     : {
      fontWeight    : 600,
    },
    "& .italic"   : {
      fontStyle     : "italic",
    }
  },
}));

export default withStyles(styles)(FinalSection);


const DownloadStep = ({onDownload, classes, isBusy, onSkip}) => {
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.instructions} color="primary">
          We recommend that you download and save your data.  This is optional, however FigureEx does not store your data on our servers, so this will ensure your data is safe in the event of your browser cache being cleared.  This will also let you transfer your data to a different computer.
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button color="primary" variant="outlined" className={classes.downloadButton} onClick={onDownload} disabled={isBusy} title="Download">
          <CloudDownloadIcon className={classes.buttonIcon}/>
          <span>Download</span>
        </Button>
        <Button color="primary" variant="outlined" size="small" className={classes.downloadButton} onClick={onSkip} disabled={isBusy} title="Skip">
          <span>Skip</span>
          <ArrowForward style={{marginLeft: "4px"}}/>
        </Button>
      </Grid>
    </Grid>
    
  );
}