import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Beforeunload } from 'react-beforeunload';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReduxToastr from 'react-redux-toastr';
import { IfBlock, ErrorAlert, Loading, FeedbackPanel } from 'components';
import { Region } from './parts';
import { changeLocation, exportData } from 'store/actions/app-actions';
import { updateFieldValue, saveDataLocal, saveDataToServer } from 'store/actions/value-actions';
import { selectLocation } from 'store/selectors/app-selectors';
import { Header, SubHeader, SummaryContainer } from './layout';
import InviteCard from '../shared/invitation-card';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import EmptyPanel from './parts/empty-panel';
import './ffcls.css';
import './custom.scss';

const scrollToTop = (ref) => {
    try{
        ref.current.scrollTo({ top: 0, left: 0, behavior: "smooth"}); // ref.current.scrollIntoView({behavior: "smooth", block: "start", });
    }
    catch(ex){}
};

class FinancialsMain extends React.Component{

    constructor(props){
        super(props);
        this.regionRef  = React.createRef();
    }

    onBlur = (e) => {
        const nodeType  = e.target.nodeName;
        if(nodeType === "INPUT" || nodeType === "SELECT"){
            if(this.props.values.isDirty){
                this.props.actions.saveDataLocal();                                
            }
        }
        // else{
        //     console.log("ignored blur from node", e.target.id, e.target.nodeName);
        // }        
    }

    onExport = (form) => {
        const toExport  = {
            a_locationKey   : this.props.locationKey,
            a_form          : form,
            ...this.props.values,
        };

        return this.props.actions.exportData(toExport);
    }

    onUnload = () => {
        console.log("unloading financials-view");
        this.props.actions.saveDataToServer();
    }

    componentWillUnmount(){
        this.onUnload();
    }

    render(){
        const { classes, regions, userLocation, data, hasValues, values, actions, isWorking, isExporting, status }    = this.props;
        const headerRegions = _.filter(regions, r => (r.type !== "summary" && r.type !== "docs"));
        const isBusy        = !hasValues || isWorking || isExporting;
        const isEmpty       = !isBusy && values.local.isEmpty;

        return (
            <Beforeunload onBeforeunload={this.onUnload}>
                
                <Grid container direction="row" className={classes.root}>
                    <Grid item sm={12} container direction="column" className={classes.headerWrapper}>
                        <Header/>
                        <IfBlock condition={!isEmpty}>
                            <SubHeader regions={headerRegions} userLocation={userLocation} changeUserLocation={actions.changeLocation} saveData={actions.saveData} exportData={this.onExport} isBusy={isBusy} reposition={() => scrollToTop(this.regionRef)}/>
                        </IfBlock>
                    </Grid>
                    <Grid item sm={12} container direction="row" className={classes.contentWrapper} ref={this.regionRef}>
                        <IfBlock condition={isEmpty}>
                            <EmptyPanel />
                        </IfBlock>
                        <IfBlock condition={!isBusy && !isEmpty}>
                            <Grid item sm={9} className={classes.regionContent} onBlur={this.onBlur}>
                                <ErrorAlert domain="app" />
                                <ErrorAlert domain="app" domainKey="exportError" />
                                <InviteCard />
                                <div id="scroll-target" />
                                <Loading isVisible={isBusy || !regions} message={status} size="medium" align="center" className={classes.working}/>
                                {!!regions && <Region data={data} regions={regions} values={values} onChange={actions.updateFieldValue} isBusy={isBusy} busyMessage={status} saveData={actions.saveData} reposition={() => scrollToTop(this.regionRef)}/>}
                            </Grid>
                            <Grid item sm={3} container className={classes.summaryContent}>
                                <SummaryContainer regions={regions} data={data} values={values}/>
                                <FeedbackPanel />
                            </Grid>
                        </IfBlock>
                    </Grid>
                </Grid>

                <ReduxToastr timeOut={3000} newestOnTop={false} preventDuplicates position="bottom-right" transitionIn="fadeIn" transitionOut="fadeOut" progressBar closeOnToastrClick/>

            </Beforeunload>
        );
    }
}

function mapState(state, myProps){
    return {
        location    : myProps.location,
        settings    : state.app.settings,
        locationKey : state.app.profile?.locationKey,
        data        : state.data,
        values      : state.values,
        profile     : state.app.user,
        userLocation: selectLocation(state),
        isWorking   : state.app.isWorking,
        isExporting : state.app.isExporting,
        status      : state.app.status,
        hasValues   : state.values.a_isLoaded,
        regions     : state.app.regions,
    };
}

function mapActions(dispatch){
    return {
        actions     : bindActionCreators({updateFieldValue, saveDataLocal, saveDataToServer, exportData, changeLocation}, dispatch)
    };
}

const styles    = (theme => ({
    root    : {

    },
    headerWrapper   : {

    },
    contentWrapper  : {
        position        : "relative",
        paddingTop      : theme.spacing(2),
        paddingLeft     : theme.spacing(2),
        height          : `calc(100vh - 136px)`,
        overflow        : "auto",
        overflowY       : "scroll",

    },
    regionContent   : {
        paddingRight    : theme.spacing(2),
        // position        : "relative",
    },
    summaryContent  : {

    },
    working         : {
        marginTop       : "15%",
        marginLeft      : "40%",
        width           : "20%",
        padding         : 25,
    }
}));

export default connect(mapState, mapActions)(withStyles(styles)(FinancialsMain));