import createCachedSelector from 're-reselect';

const _getFieldValue    = (state, fieldKey)   => fieldKey ? state.values[fieldKey] : null;
const _getRowFieldValue = (state, fieldKey, index)   => fieldKey && state.values[fieldKey] ? state.values[fieldKey][index] : null;

export const selectValue = createCachedSelector(
  [_getFieldValue],
  (value) => {
    // console.log("** Value Selector Called **")
    return value;
  }
)(
  (_, fieldKey) => fieldKey //the cache key
);

export const selectRowValue = createCachedSelector(
  [_getRowFieldValue],
  (value) => {
    // console.log("** Value Selector Called **")
    return value;
  }
)(
  (_, fieldKey, index) => `${fieldKey}-${index}` //the cache key  //`
);