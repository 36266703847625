import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Loading } from './';

function TBodyWrapper({colSpan, isWorking, isEmpty, workingText, emptyText, children}){
  const classes     = buildStyles();
  const myIsEmpty   = Boolean(!isWorking && (isEmpty || children === null));

  return (
    <tbody className={classes.tableBody}>
        <WorkingRow colSpan={colSpan} isWorking={isWorking} workingText={workingText}/>
        <EmptyRow colSpan={colSpan} isEmpty={myIsEmpty} emptyText={emptyText}/>
        {children}
    </tbody>
  );
}

TBodyWrapper.propTypes  = {
  colSpan       : PropTypes.number,  
  isWorking     : PropTypes.bool,
  isEmpty       : PropTypes.bool,
  workingText   : PropTypes.string,
  emptyText     : PropTypes.string,
};

TBodyWrapper.defaultProps   = {
  colSpan       : 1,
  isWorking     : false,
  isEmpty       : false,
  emptyText     : "There are no items",
  workingText   : "Loading...",
};

const buildStyles   = makeStyles(theme => ({
  tableBody : {
    "& #empty-row"   : {
      "& td"   : {
        padding     : theme.spacing(3),
        fontSize    : "1.15rem",
      }
    },
    "& #working-row"   : {
      "& td"   : {
        padding     : theme.spacing(3),
        fontSize    : "1.15rem",
      }
    },
  }
}));

export default TBodyWrapper;

function EmptyRow({isEmpty, colSpan, emptyText}){
  if(!isEmpty) return null;
  return (
    <tr id="empty-row">
        <td colSpan={colSpan} className="text-center m-t-md">
          <Grid container spacing={3}>
            <span className="italic font-italic">{emptyText}</span>
          </Grid>
        </td>
    </tr>
  );
}

function WorkingRow({isWorking, colSpan, workingText}){
  if(!isWorking) return null;
  return (
    <tr id="working-row">
        <td colSpan={colSpan} className="text-center m-t-md">
            <Grid container spacing={3}>
              <Loading isVisible={isWorking} message={workingText} />
            </Grid>
        </td>
    </tr>
  );
}