import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { NavButton } from 'components';
import { updateReceivedInvite } from 'store/actions/share-actions';
import { getNowString } from 'store/actions/action-helpers';
import { usePrepareModels } from 'helpers/hooks';

const InvitationCard = () => {
  const classes       = buildStyles();
  const dispatch      = useDispatch();
  const rawInvites    = useSelector(state => state.share.invitations);
  const invitations   = usePrepareModels(rawInvites);
  if(!invitations) return null;

  const pending   = _.filter(invitations, inv => !inv.viewedAt); //Only show if there are invites not yet viewed
  const count   = pending.length;
  if(count <= 0) return null;

  const onDismiss   = async () => {
    pending.forEach(async(invite) => {
      await dispatch(updateReceivedInvite(invite.id, {viewedAt: getNowString()}));
    });
  }

  return (
    <Card elevation={0} variant="outlined" className={classes.inviteCard}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={8} container alignItems="center">
            <Typography className={classes.text}>You have {count} share request(s) waiting</Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
            <NavButton to="/my/sharing" size="small" className={classes.viewButton}>View</NavButton>
            <Button size="small" className={classes.dismissButton} onClick={onDismiss}>Dismiss</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default InvitationCard;

const buildStyles   = makeStyles(theme => ({
  inviteCard  : {
    // padding     : theme.spacing(0.5),
    background  : theme.palette.primary.light,  
    marginBottom  : theme.spacing(2),  
  },
  cardContent   : {
    padding   : theme.spacing(1),
    "&:last-child"  : {
      paddingBottom     : theme.spacing(1),
    }
  },
  text   : {
    fontSize    : 16,
    fontWeight  : 400,
    color       : theme.palette.primary.contrastText,
    // textAlign   : "center",
    // marginBottom: theme.spacing(1),
  },
  viewButton  : {
    fontSize    : 14,
    fontWeight  : 600,
    color       : theme.palette.common.white,
  },
  dismissButton   : {
    fontSize    : 14,
    fontWeight  : 600,
    color       : theme.palette.grey[300],
  },
}));