import React from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import StatusCard from './status-card';

const WaitContainer = ({isWaiting, message, isEmpty, emptyMessage, className, variant, color, children, classes}) => {
  
  //TODO: multi-prop waiting
  // const props   = getProps(isWaiting, message);
  
  const waitMessage   = message === undefined ? "Loading..." : message;
  const progress      = variant === "circular" ? 
    <CircularWaiter color={color} classes={classes} className={className} message={waitMessage}/> : 
    <LinearWaiter color={color} classes={classes} className={className} message={waitMessage}/>;
  

  return (
    <React.Fragment>
      {isWaiting && progress }
      {(!isWaiting && isEmpty && emptyMessage) && 
        <StatusCard status="info" isVisible={true} style={{width: "100%"}} className={clsx(classes.empty, className)}>
          <span>{emptyMessage}</span>
        </StatusCard>
      }
      {(!isWaiting && (!isEmpty || !emptyMessage)) && 
        <React.Fragment>
          {children}
        </React.Fragment>
      }   
    </React.Fragment>
  )
}

const styles = theme => ({
  root   : {
    textAlign   : "center",
    padding     : theme.spacing(2),
  },
  message   : {
    fontSize    : "1.25rem",
  },
  empty     : {
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
  },
  circular  : {
    display         : "flex",
    flexDirection   : "column",
    justifyContent  : "center",
    alignItems      : "center",
    padding         : theme.spacing(4),
  },
  circularProgress  : {
    marginBottom     : theme.spacing(1),
  },
  linear  : {

  },
  linearProgress  : {
    minWidth    : "100px",
    marginTop   : theme.spacing(1),
  }
});

export default withStyles(styles)(WaitContainer);

WaitContainer.propTypes = {
  isWaiting   : PropTypes.bool.isRequired,
  message     : PropTypes.string,
  className   : PropTypes.string,
  variant     : PropTypes.string,
  color       : PropTypes.string,
};

WaitContainer.defaultProps = {
  variation   : "circular",
  color       : "primary",
};

const LinearWaiter = ({color, classes, className, message}) => (
  <div className={clsx("waiting waiting-linear", classes.linear, classes.root, className)}>
    <em className={classes.message}>{message}</em>
    <LinearProgress color={color} className={classes.linearProgress}/> 
  </div>
);

const CircularWaiter = ({color, classes, className, message}) => (
  <div className={clsx("waiting waiting-circular", classes.circular, classes.root, className)}>
    <CircularProgress color={color} className={classes.circularProgress}/>  
    <em className={classes.message}>{message}</em>
  </div>
);

//TODO: Implement the multi-prop waiting
// function getProps(isWaiting, message){
//   if(_.isArray(isWaiting)){
//     const idx   = _.findIndex(isWaiting, w => w === true);
//     return {
//         isWaiting   : idx >= 0,
//         message     : _.isArray(message) ? message[idx] : message || "Loading...",
//     };
//   }
//   else{
//     return {
//       isWaiting   : isWaiting,
//       message     : _.isArray(message) ? message[0] : message || "Loading...",
//     };
//   }
// }