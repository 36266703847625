import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import fHelper from 'helpers/financials-helper';
import { FieldLabel, MultiFieldLabel } from './data-table-field-label';
import { CommentContainer } from 'components';

//==========
// Single value field
function DataTableField({fieldKey, values, profile}){
  const classes   = buildStyles();
  const field     = fHelper.getField(fieldKey, false);
  //NOTE: Toggle this to turn off the commenting feature...  Set it to true and comments will be disabled
  const match     = useRouteMatch("/app/review");     //TODO: a better way to make this read only??

  //Make sure we have a field, and if so, if the condition for the field is met
  if(!field || (field.condition && !fHelper.isConditionMet(field.condition, values))){
    return null;
  }
  
  const isPField  = Boolean(field.params && field.params.length > 1) || Boolean(field.children);
  
  return (
    <tr className={clsx(classes.fieldRow)}>
      <td colSpan={3}>
        <CommentContainer fieldKey={fieldKey} profile={profile} isReadOnly={!!match}>
          {isPField && <MultiFieldLabel field={field} values={values} />}
          {!isPField && <FieldLabel field={field} values={values} />}
        </CommentContainer>        
      </td>
    </tr>
  );
}

export default DataTableField;

const buildStyles   = makeStyles(theme => ({
  fieldRow  : {
    "& td": {
      borderBottom  : `1px dotted ${theme.palette.grey[300]}`,
    },
    "& .bold"   : {
      fontWeight  : 700,
    },        
  },  
}));