import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { Redirect, Route, Switch } from 'react-router-dom';
import LandingContainer from '../landing/landing-container';
import AppContainer from './app-container';
import Initializing from './app-initializing';
import AdminView from '../admin/admin-view';

function AppRouter(){

  const classes   = useStyles();

  return (
    <div id="app-router">
      <div className={classes.mobileWarning}>
        <Card className={classes.tooSmall} elevation={0} variant="outlined">
          <Grid container>
            <h3>Ugh, we're sorry!</h3>
            <p>We don't currently support smaller form-factors.  We're a small team, and we're working on it, but for now, please visit us from a full-sized web browser on your computer. We promise it will be worth your while.</p>
            <p>Thanks!</p>
          </Grid>
        </Card>
      </div>      

      <div className={classes.app}>
        <Switch>
          <Route path="/initializing" component={Initializing} />
          <Route path="/" exact><Redirect to="/app" /></Route>
          <Route path={["/app", "/my", "/welcome"]} component={AppContainer}/>
          <Route path={"/admin"} component={AdminView} />       
          <Route path={["/landing", "/legal"]} component={LandingContainer} />   
        </Switch>
      </div>
    </div>
  );
}

export default AppRouter;

const bpSize    = "sm"; //The size at which the app becomes unusable

const useStyles   = makeStyles(theme => ({
  tooSmall  : {
    borderRadius    : 5,
    color           : theme.palette.primary.main,
    margin          : "15%",
    padding         : "10px 0 0 10px",
    position        : "relative",
    paddingLeft     : 15,
    paddingRight    : 10,
  },
  mobileWarning  : {
    [theme.breakpoints.down(bpSize)]: {
      display   : "unset",
    },
    [theme.breakpoints.up(bpSize)]: {
      display   : "none",
    }
  },
  app   : {
    [theme.breakpoints.down(bpSize)]: {
      display   : "none",
    },
    [theme.breakpoints.up(bpSize)]: {
      display   : "unset",
    }
  }
}));