import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getInvitation} from 'store/actions/onboarding-actions';
import InvitationWaitlist from './invitation-waitlist';

function Invitation(){

  const dispatch                = useDispatch();
  const classes                 = useStyles();
  const [code, setCode]         = useState("");
  const [isOpen, setOpen]       = useState(false);
  const invError                = useSelector(state => state.onboarding.invitationError);

  const onSubmit = async () => {
    console.log("validating invitation code: ", code)
    const myCode  = code.trim();
    if(myCode){
      return dispatch(getInvitation(myCode));
    }
  };

  const buttonStyle   = code && code.length >= 4 ? "contained" : "outlined";

  return (
    <Grid container justifyContent="center">
      <Grid item sm={12} container justifyContent="center">
        <Typography className={classes.info}>FigureEx is currently available by invitation only.  Please enter your invitation code below to begin.</Typography>
      
        <Grid item sm={9} container justifyContent="flex-start">
          <TextField id="invitationCode" label="Invitation Code" fullWidth value={code} onChange={(e) => setCode(e.target.value)} />
        </Grid>
        <Grid item sm={3} container justifyContent="flex-end" className={classes.btnRow}>
          <Button variant={buttonStyle} color="primary" onClick={() => onSubmit(code)}>Submit</Button>
        </Grid>
      
        {invError && 
          <Card elevation={0} className={classes.errorCard} variant="outlined">
            <span>{invError.message}</span>
          </Card>
        }
      </Grid>

      <Grid item sm={12} container justifyContent="flex-start" className={classes.bottomRow}>
        <Typography className={classes.info}>Need an invitation?</Typography>
        <Button className={classes.linkButton} color="primary" onClick={() => setOpen(!isOpen)}>Click here.</Button>
        { isOpen &&  <InvitationWaitlist isOpen={isOpen} /> }          
      </Grid>
    </Grid>
  );
}

export default Invitation;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  }, 
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
  },   
  btnRow    : {
    marginTop     : theme.spacing(2),
  },
  bottomRow   : {
    marginTop     : theme.spacing(8),
  },
  linkButton  : {
    border    : "none",
    fontSize  : 18,
    fontWeight: 300,
    color     : theme.palette.primary.main,
    padding   : 0,
    marginLeft: theme.spacing(1),
    marginTop : `-${theme.spacing(1)}px`,
    textTransform : "lowercase",
  },
  errorCard         : {
    background      : theme.palette.error.light,
    border          : `1px solid ${theme.palette.error.dark}`,
    borderRadius    : 5,
    color           : theme.palette.error.main,
    margin          : "10px 0",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",
  }, 
  
}));