import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LandingPage from './landing-page';
import LearnMorePage from './learn-more-page';
import AboutPage from './about-page';
import ContactPage from './contact-page';
import LegalPage from '../legal/legal-page';
import LawyerLanding from './landing-lawyer';

const LandingContainer = () => {

  const classes   = buildStyles();

  return (
    <div id="landing-container" className={classes.root}>
      <Switch>        
        <Route path="/landing/learn-more" component={LearnMorePage}/>
        <Route path="/landing/about" component={AboutPage}/>
        <Route path="/landing/contact" component={ContactPage}/>  
        <Route path="/landing/lawyer" component={LawyerLanding}/>  
        <Route path="/legal" component={LegalPage}/>
        <Route path="/" component={LandingPage}/>
      </Switch>
    </div>
  );
}

export default LandingContainer;

const buildStyles   = makeStyles(theme => ({
  root  : {
    // padding     : theme.spacing(1),
  },
  
}));