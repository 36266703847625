import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import helper from 'helpers/financials-helper';
import nHelper from 'helpers/number-helper';
// import { create, all } from 'mathjs';
import fHelper from 'helpers/financials-helper';

// const math  = create(all, {});

const SummarySection = ({region, regions, values, classes}) => {
    const fields    = fHelper.getFields(region.fields);
    const rows      = _.map(fields, f => <SummaryRow key={f.id} field={f} values={values} regions={regions}/>);
    const cName     = `jr-card sidebar-summary sidebar-summary-${region.id}`;

    return (
        <div className={clsx(classes.card, cName)}>
            {region.label && 
                <div className="jr-card-header">
                    <h2 className="summary-section-title">{region.label}</h2>
                </div>
            }
            <div className="jr-card-body">
                <table className="table table-condensed">
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const styles = theme => ({
    card    : {
        padding     : `${theme.spacing(2)}px !important`,
        fontSize: 14,
    },
});

export default withStyles(styles)(SummarySection);

const SummaryRow = ({field, values, regions}) => {
    let total       = 0;
    if(field.calculation){
        total   = helper.calculateSummaryField(field, values, regions);
    }
    else{
        total   = helper.calculateSummaryRegion(field, values, regions);
    }
    
    const totalStr  = nHelper.asCurrency(total);
    const color     = getColor(field, total);
    const rowClass  = field.calculation ? "summary-row summary-total-row" : "summary-row";
    const colClass  = `value-col field-currency ${color}`;

    return (
        <tr key={field.id} className={rowClass}>
            <td className="col-label-summary">{field.label}</td>
            <td className={colClass}>{totalStr}</td>
        </tr>
    );
    
}

const getColor = (field, total) => {
    let colorClass  = "";
    if(field.color){
        if(field.color === "conditional"){
            colorClass  = total > 0 ? "text-success" : total < 0 ? "text-danger" : "";
        }
        else{
            colorClass  = `text-${field.color}`;
        }
    }

    return colorClass;
}