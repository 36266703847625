import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import TermsOfService from './terms-of-service';

export default function LegalPage(){
  const classes   = buildStyles();

  return (
    <Grid className={classes.root}>
      <Helmet>
        <title>FigureEx Legal</title>
      </Helmet>
      <Route path="/legal/terms-of-service" component={TermsOfService} />
    </Grid>
  );
}

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(2),
  }
}));