import React, { useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import StorageIcon from '@material-ui/icons/Storage';
import PersonIcon from '@material-ui/icons/Person';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// import GroupIcon from '@material-ui/icons/Group';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';

const MyNav = () => {
  const classes   = buildStyles();
  const [isCollapsed, setCollapsed]   = useState(false);

  return (
    <Grid item container className={clsx(classes.navWrapper, {"collapsed": isCollapsed})} direction="column" justifyContent="flex-start">
      <NavLink to="/app" className={classes.navItem} title="Back to your Financials"><ArrowBackIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Back"}</NavLink>
      <NavLink to="/my" exact={true} className={classes.navItem} title="My Profile"><PersonIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Profile"}</NavLink>
      {/* <NavLink to="/my/sharing" exact={true} className={classes.navItem} title="My Sharing"><GroupIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Sharing"}</NavLink> */}
      <NavLink to="/my/data" exact={true} className={classes.navItem} title="My Data"><StorageIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Data"}</NavLink>
      <NavLink to="/my/subscription" exact={true} className={classes.navItem} title="My Subscription"><ShoppingCartIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Subscription"}</NavLink>
      {/* <NavLink to="/my/account" exact={true} className={classes.navItem} title="My Account"><PaymentIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Account"}</NavLink> */}
      <IconButton size="small" className={classes.expandButton} onClick={() => setCollapsed(!isCollapsed)} title="Expand / Collapse Menu">
        <MenuIcon fontSize="small" />
      </IconButton>
    </Grid>
  );
}

export default MyNav;

const buildStyles   = makeStyles(theme => ({
  navWrapper      : {
    // padding     : theme.spacing(2),
    marginTop  : theme.spacing(4),
    width       : 180,
    "&.collapsed" : {
      width   : 60,
    }
  },
  navItem   : {
    fontSize    : 14,
    fontWeight  : 600,
    color       : theme.palette.grey[800],

    marginBottom  : theme.spacing(2),
    padding       : theme.spacing(1), //`${theme.spacing(1)}px ${theme.spacing(1)}px`, //`,
    borderRadius  : "0 5px 5px 0", 
    textDecoration  : "none",
    "&:hover"   : {
      textDecoration  : "none",
      background    : `${theme.palette.grey[400]}44`, //`
    },
    "&.active"  : {
      color         : theme.palette.secondary.main,
      background    : `${theme.palette.secondary.light}44`, //`
    }
  },
  navIcon   : {
    marginRight   : theme.spacing(1),
    marginBottom  : `-${theme.spacing(1)}px`,   //`
  },
  disabled  : {
    color       : theme.palette.grey[400],
  },
  expandButton : {
    marginTop     : theme.spacing(2),
    alignSelf     : "flex-start",
    marginLeft    : theme.spacing(1),
  },
}));