import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { StatusCard } from 'components';
import LandingHeader from './landing-header';
import LandingFooter from './landing-footer';

function LawyerLanding(){
  const classes = buildStyles();
  const target  = {
    pathname  : "/app",
    search    : "?type=reviewer",
  };

  return (
    <Grid container className={classes.root}>
      <LandingHeader/>
      <Grid item container className={classes.lawyerView} alignItems="center" justifyContent="center">
        <Grid item xs={4} container justifyContent="center">
          <StatusCard isVisible={true} status="ok" message="Coming Soon: Lawyer Landing"/>
          <Button component={Link} to={target} className={classes.linkButton} color="primary" variant="contained">Get Started</Button>
        </Grid>        
      </Grid>      
      <LandingFooter/>
    </Grid>
  )
}

export default LawyerLanding;

const buildStyles   = makeStyles(theme => ({
  root   : {
    width   : "100%",
    height  : "100vh",
  },
  lawyerView  : {
    height    : "calc(100vh - 120px)",
    marginTop : 60,
  },
  linkButton  : {
    marginTop   : theme.spacing(3),
  }
}));