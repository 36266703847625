import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IfBlock, WaitContainer } from 'components';
import { deleteSentInvite } from 'store/actions/share-actions';
import { formatDate } from 'helpers/general-helpers';
import { useAnchor } from 'helpers/hooks';
import { SHARE_LABELS } from 'config/share-config';
import { useStatus } from 'redux-action-status';

function ClientListItem({item, mode}){
  const classes           = buildStyles();
  const status            = useStatus("shares");
  const [data, setData]   = useState(item);
  const [isOver, setOver] = useState(false);
  const [isDeleting, setDeleting]   = useState(false);
  const isAccepted        = Boolean(mode === "accepted");
  
  const [anchor, isOpen, onClick, onClose]  = useAnchor();
  
  const dispatch  = useDispatch();

  useEffect(() => {
    async function prepareItem(item){
      let data  = item;
      if(!data.isLoaded && data.ref){
        data  = {...data, isLoaded: true, ...(await data.ref.data())};
      }
      setData(data);
    }
    prepareItem(item);
    return undefined;
  }, [item]);

  const onEnter   = (e) => { setOver(true); }
  const onLeave   = (e) => { setOver(false); }
  const onToggleDelete = () => { setDeleting(!isDeleting); onClose(); }
  const onDelete  = (e) => { dispatch(deleteSentInvite(item.id)); }
  const url       = data.isLoaded ? `/app/clients/${data.id}` : "#loading";
  const itemStatus= SHARE_LABELS[data.status] || "invited";

  //TODO: change the menu items for accepted invitations.  Should have Archive, and something else...?
  //TODO: Status column should be info for the lawyer - perhaps whether or not they've viewed the data.  Setup a workflow for lawyer?
  
  return (
    <>
      <IfBlock condition={isDeleting && data.isLoaded}>
        <tr className={clsx(classes.clientRow, classes.deleteRow)}>
          <td colSpan={3}>
            Are you sure you'd like to delete the invitation for <strong>{data.firstName} {data.lastName}</strong>
          </td>
          <td colSpan={2} style={{textAlign: "right"}}>
            <Button color="secondary" size="small" variant="outlined" className={classes.actionButton} onClick={onDelete}>Continue</Button>
            <Button size="small" variant="outlined" onClick={onToggleDelete} className={classes.actionButton}>Cancel</Button>
          </td>
        </tr>
      </IfBlock>
      <IfBlock condition={!isDeleting && data.isLoaded}>
        <tr className={clsx(classes.clientRow, {"over" : isOver})} onMouseEnter={onEnter} onMouseLeave={onLeave}>
          <td>
            {isAccepted && <Link to={url}>{data.firstName} {data.lastName}</Link>}
            {!isAccepted && <span>{data.firstName} {data.lastName}</span>}
          </td>
          <td>{data.email}</td>
          <td className={classes[itemStatus]}>{itemStatus}</td>
          <td>{isAccepted ? "" : formatDate(data.invitedAt)}</td>
          <td className="moreCol">
            <IconButton size="small" onClick={onClick}>
              <MoreVertIcon fontSize="small"/>
            </IconButton>
            <Menu id="moreMenu" anchorEl={anchor} open={isOpen} onClose={onClose} disabled={status.isWorking}>
              <MenuItem onClick={onToggleDelete} disabled={Boolean(item.acceptedOn) || status.isWorking} fontSize="small">Delete invitation</MenuItem>
              <MenuItem disabled={Boolean(item.acceptedOn) || status.isWorking} fontSize="small">Re-send email</MenuItem>              
            </Menu>
          </td>
        </tr>
      </IfBlock>
      <IfBlock condition={!data.isLoaded}>
        <tr className={classes.clientWaitRow} style={{textAlign: "center"}}>
          <td colSpan={5} >
            <WaitContainer isWaiting={true}/>
          </td>
        </tr>
      </IfBlock>
    </>
  );
}

export default ClientListItem;

const buildStyles   = makeStyles(theme => ({
  clientRow   : {
    fontSize    : 14,
    fontWeight  : 500,
    color       : theme.palette.grey[700],
    "& td"  : {
      padding   : `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px `, //`,
      "& a"  : {
        textDecoration  : "none",
        color           : theme.palette.secondary.main,
        "&:hover"   : {
          textDecoration  : "underline",
        },
      },  
      "&.moreCol"   : {
        width       : 50,
        padding     : `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
        "& button"  : {
          display   : "none",
          "& svg"   : {
            fontSize  : 20,
            fontWeight  : 600,
          }
        },        
      }   
    },
    "&.over .moreCol"  : {
      "& button"  : {
        display   : "unset",
      },
    },
  },
  deleteRow   : {
    background    : theme.palette.error.light,
    // color         : theme.palette.error.dark,
  },
  actionButton  : {
    marginLeft   : theme.spacing(0.5),
  },
  accepted  : {
    color       : theme.palette.primary.main,
    fontWeight  : 600,
  },
  pending  : {
    // color       : theme.palette.primary.main,
    fontWeight  : 600,
  }
}));