export const VALUES_KEY    = "figex.values";
export const SAVED_KEY     = "figex.lastSaved";
export const LOCATION_KEY  = "figex.locationKey";
// export const TOS_KEY       = "figex.isTosAgreed";
export const SAFETY_KEY    = "figex.isSecurityAgreed";

export const DOWNLOADED_KEY   = "figex.lastDownloaded";

//This tracks the version of the app for download / upload purposes.
// if anything about the values struct changes, need to modify this and
// handle conversion between old versions and new versions of the software
export const VALUES_VERSION   = "1.0.0";


