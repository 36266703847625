import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import HappyIcon from '@material-ui/icons/SentimentVerySatisfied';
import SadIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import ShareIcon from '@material-ui/icons/Share';
import { toastr } from 'react-redux-toastr';
import FeedbackDialog from './feedback-dialog';
import { InviteDialog } from 'components';

const FeedbackPanel = () => {
  const [isInviting, setInviting]   = useState(false);
  const [isOpen, setOpen]   = useState(false);
  const [fbType, setFbType] = useState("happy");

  const classes   = buildStyles();

  function openDialog(type){
    setFbType(type);
    setOpen(true);
  }

  function closeDialog(result){
    if(result === true){
      toastr.success("Feedback Sent", "Thank you for your feedback.");
    }
    setOpen(false)
  }

  function closeInvitation(result){
    if(result === true){
      toastr.success("Invitation Sent", "Thank you for sharing FigureEx.");
    }
    setInviting(false)
  }

  return (
        <Grid id="feedback-grid" container justifyContent="center" className={classes.feedbackRoot}>
          <Tooltip title="Invite someone to FigureEx">
            <ShareIcon fontSize="small" className={classes.icon} onClick={() => setInviting(true)}/>
          </Tooltip>
          <Tooltip title="Send feedback">
            <HappyIcon fontSize="small" className={classes.icon} onClick={() => openDialog("happy")}/>
          </Tooltip>
          <Tooltip title="Send feedback">
            <SadIcon fontSize="small" className={classes.icon} onClick={() => openDialog("sad")}/>
          </Tooltip>
          <FeedbackDialog feedbackType={fbType} isOpen={isOpen} onClose={closeDialog}/>
          <InviteDialog type="share" isOpen={isInviting} onClose={closeInvitation}/>
        </Grid>
  );
}

export default FeedbackPanel;

const buildStyles   = makeStyles(theme => ({
  feedbackRoot  : {
    padding     : theme.spacing(1),
    background  : theme.palette.grey[50],
    border      : `1px solid ${theme.palette.grey[300]}`, //`
    borderWidth : "1px 1px 0 1px",
    borderRadius: "5px",
    position    : "fixed",
    bottom      : 0,
    right       : 18,
    width       : 135,

  },
  icon  : {
    marginRight   : theme.spacing(1),
    color         : theme.palette.grey[400],
    cursor        : "pointer",
    "&:hover"     : {
      color         : theme.palette.secondary.main, //`${theme.palette.secondary.main}99`, //`
    }
  }
}));