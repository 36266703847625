import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useSelector } from "react-redux";
import { selectCustomer } from "store/selectors/app-selectors";
import { getPaymentProducts, purchaseSubscription } from "helpers/payments-helper";
import { Loading } from "components";
import SubscriptionChoices from "./parts/subscription-choices";
import Subscribed from "./parts/subscription-subscribed";

const MySubscriptionView = () => {
  const classes   = buildStyles();
  const customer = useSelector(state => selectCustomer(state));
  const license = useMemo(() => customer?.licenses ? customer.licenses[0] : null, [customer]);
  const [stripeProduct, setStripeProduct] = useState(null);
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    async function doEffect(){
      const products = await getPaymentProducts();
      console.log("Stripe Products: ", products);
      setStripeProduct(products[0]);
    }
    doEffect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPurchase = (priceId) => async () => {
    setIsWorking(true);
    const result = await purchaseSubscription(priceId);
    console.log("Purchase result", result);
    setIsWorking(false);
  }

  const onRequestRefund = async () => {
    console.log("User requested a refund.");
  }

  return (
    <Grid id="my-subscription" container className={classes.subscriptionRoot}>
      <Grid item container>
        <Grid item sm={9} container alignContent="flex-start">
          <Grid item container><Typography className={classes.title}>FigureEx</Typography></Grid>
          <Grid item container><Typography className={classes.subTitle}>Sworn Financial Statements Simplified</Typography></Grid>
        </Grid>
        <Grid item sm={3} container alignItems="center" justifyContent="center">
          <div className={classes.avatarWrapper}>
            <div className={classes.avatarWrapperInner}>
              <ShoppingCartIcon className={classes.avatar} color="primary"/>
            </div>
          </div>
        </Grid>      
      </Grid>

      <Grid item xs={12} className={classes.mainContent}>
        {!stripeProduct && <Loading isVisible={true} message="Loading product information..." /> }
        {stripeProduct && !license && <SubscriptionChoices stripeProduct={stripeProduct} onPurchase={onPurchase} isWorking={isWorking} />}
        {stripeProduct && license && <Subscribed license={license} onRequestRefund={onRequestRefund} isWorking={isWorking} />}
      </Grid>

    </Grid>
  );
}

export default MySubscriptionView;

const buildStyles   = makeStyles(theme => ({
  subscriptionRoot  : {
    padding     : theme.spacing(1),
  },
  avatarWrapper      : {
    border        : `0.5px solid ${theme.palette.grey[300]}`,
    borderRadius  : "50%",
    height        : "90px",
    width         : "90px",
    lineHeight    : "90px",
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
    marginBottom  : theme.spacing(2),
    padding: theme.spacing(1),
  },
  avatarWrapperInner: {
    backgroundColor: theme.palette.primary.main,
    borderRadius  : "50%",
    height        : "74px",
    width         : "74px",
    lineHeight    : "74px",
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
  },
  avatar: {
    color: "#f4f4f4",
    height: 48,
    width: 48,
  },
  title   : {
    fontSize    : 25,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
    color       : theme.palette.primary.main,
  },
  subTitle  : {
    fontSize      : 18,
    fontWeight    : 400,
    marginBottom  : theme.spacing(1),
    color         : theme.palette.grey[600],
  },  
  settingCard   : {
    marginBottom  : theme.spacing(2),
  },
  cardHeader   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  cardSubHeader: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: theme.spacing(3),
    color         : theme.palette.grey[600],
    textAlign: "center",
  },
  commandGrid   : {
    padding       : theme.spacing(3),
    "&:not(:last-child)": {
      borderRight  : `1px solid ${theme.palette.grey[200]}`,
    },
    "&:last-child": {
      paddingBottom   : 0,
    },
  },
  commandText   : {
    fontSize      : 20,
    fontWeight    : 600,
    display: "inline",
    marginBottom: theme.spacing(2),
  },
  commandTextPrice   : {
    fontSize      : 18,
    fontWeight    : 400,
    display: "inline",
    // marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  commandSubText  : {
    fontSize      : 15,
    fontWeight    : 300,
    color         : theme.palette.grey[500],
    "& strong"  : {
      color       : theme.palette.primary.main,
      fontWeight  : 700,
    }
  },
  commandButton : {
    fontSize      : 14,
    fontWeight    : 500,
  },
}));