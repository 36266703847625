import React, { useState } from 'react';
// import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { trackEvent, Events } from 'helpers/analytics';
import { readFileAsJson } from 'helpers/file-helper';
import { ErrorAlert } from 'components';
import { downloadRawData, loadRawData } from 'store/actions/value-actions';
// import { selectProfile } from 'store/selectors/app-selectors';
// import { updateProfile } from 'store/actions/app-actions';
// import { clearDataFromServer, clearLocalData } from 'store/actions/value-actions';

const LoadDataDialog = ({isOpen, onClose}) => {
  const [props, setProps]   = useState({isChecked: false, isLoading: false, error: null}); 
  // const profile   = useSelector(state => selectProfile(state));
  const dispatch  = useDispatch(); 
  const classes   = buildStyles();

  if(!isOpen) return null;

  const onDownload = async() => {
    trackEvent(Events.download);
    dispatch(downloadRawData());
  }

  const onFileChange = (e) => {
    const file  = e.target.files[0];
    openFile(file);
  }

  async function openFile(file){
    //TODO: give them the details of the file they're loading before the load (and overwrite)?
    const json  = await readFileAsJson(file);
    const result  = await dispatch(loadRawData(json));

    if(result && result.isOk){
      trackEvent(Events.open_existing);
      setProps({isChecked: false, isLoading: false, error: null});
      onClose(true);
    }
  }
  
  const closeDialog = () => {
    if(!props.isLoading){
      setProps({isChecked: false, isLoading: false, error: null});
      onClose(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>Load data from a file</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid id="data-view" container justifyContent="center" className={classes.contentRoot} spacing={2}>
          <Grid item xs={12}>
            <ErrorAlert error={props.error} title="Load Failed" onClose={() => setProps({...props, error: null})} />            
          </Grid>
          <Grid item xs={12}>
            <React.Fragment>
              <Typography className={classes.contentText}>
                You are about to load your data from a file which will <strong>overwrite your current data</strong>. This action is permenant, and cannot be undone.
              </Typography>
              <Typography className={classes.contentText}>
                <strong>It is strongly recommended</strong> that you download your current data and store it in a safe location before loading a different file.
              </Typography>
              <Typography className={classes.contentText}>
                Once you complete this action, <strong>your current data will be permenantly overwritten</strong>.  To continue, check the 'I Agree' box below, and click then the button.
              </Typography>
            </React.Fragment>            
          </Grid>
          <Grid item container>
            <FormControlLabel classes={{label: classes.check}}
              control={
                <Checkbox value="isChecked" color="primary" size="small" checked={props.isChecked} onChange={() => setProps({isChecked: !props.isChecked})}/>
              }
              label="I Agree"
            />
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button id="cancel" size="small" variant="outlined" color="default" onClick={closeDialog} disabled={props.isLoading}>Cancel</Button>
        <Button id="download" size="small" variant="outlined" color="default" onClick={onDownload} disabled={props.isLoading}>Download Current</Button>

        <input accept=".json" style={{ display: 'none' }} id="openButton" type="file" disabled={!props.isChecked || props.isLoading} onChange={onFileChange} />
        <label htmlFor="openButton">
          <Button  variant="outlined" color="primary" size="small" component="span" disabled={!props.isChecked || props.isLoading}>Load from file</Button>
        </label>
      </DialogActions>
    </Dialog>
  );
}

export default LoadDataDialog;

const buildStyles   = makeStyles(theme => ({
  title   : {
    padding     : theme.spacing(2),
    background  : theme.palette.primary.light,
    color       : theme.palette.primary.contrastText,
  },
  titleText   : {
    fontSize    : 20,
    fontWeight  : 500,
  },  
  contentRoot  : {
    padding     : theme.spacing(1),
  },
  notifyText  : {
    fontSize  : 18,
    fontWeight: 400,
    color     : theme.palette.secondary.light,
    marginBottom  : theme.spacing(1.5),
  },
  contentText  : {
    fontSize  : 16,
    fontWeight: 300,
    marginBottom  : theme.spacing(1),
    "& a" : {
      textDecoration  : "none",
      color   : theme.palette.secondary.main,
    },
    "& strong" : {
      fontWeight  : 500,
    }
  },
  check   : {
    fontSize  : 16,
  }
}));