import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { loadRegionsForLocation } from 'store/actions/app-actions';
import { loadClientData } from 'store/actions/client-actions';
import { loadConversation } from 'store/actions/conversation-actions';
import { ErrorAlert } from 'components';
import ClientPanel from './parts/client-panel';
import DataTable from '../financials/parts/data-table';
import { IfBlock, Loading } from 'components';
import { useCurrentShare } from 'helpers/selector-hooks';

const ClientDetails = () => {
  const classes       = buildStyles();
  const dispatch      = useDispatch();
  const share         = useCurrentShare();
  const data          = useSelector(state => state.client.clientData);
  const status        = useSelector(state => state.client.clientStatus);
  
  useEffect(() => {
    async function initClient(){
      const p1 = dispatch(loadClientData(share.sharer));
      const p3 = dispatch(loadConversation(share.id));      
      await Promise.all([p1, p3]);
    }
    if(share && !data) initClient();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, share]);

  useEffect(() => {
    async function loadRegions(){
      await dispatch(loadRegionsForLocation(data.meta.locationKey));
    }
    if(data && data.meta?.locationKey) loadRegions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Grid id="data-view" container justifyContent="center" className={classes.root}>
      
      <ErrorAlert error={status.error} title="Failed to load client data" />
      <Loading isVisible={!data && status.isWorking} message="Loading client details..."/>

      <IfBlock condition={Boolean(data)}>
        <Grid item container className={classes.clientGrid}>
          <ClientPanel share={share}/>
        </Grid>     

        <DataTable isReviewer={true}/>
      </IfBlock>
    </Grid>
  );
}

export default ClientDetails;

const buildStyles   = makeStyles(theme => ({
  root  : {
    // padding     : theme.spacing(1),
  },
  clientGrid   : {
    padding     : `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    marginBottom  : theme.spacing(1),
  },
   
}));