import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Switch, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from 'store/actions/admin-actions';

const SettingsCard = () => {
  const classes   = buildStyles();
  const settings = useSelector(state => state.app.settings);
  const dispatch = useDispatch();

  const onSettingChanged = async (prop, event) => {
    const newValue = event.target.checked;
    const changes = {
      ...settings,
      isInviteRequired: newValue,
    };

    const result = await dispatch(updateSettings(changes));
    console.log("settings updated: ", result);
  }

  return (
    <Card className={classes.cardRoot} elevation={0} variant="outlined">
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardHeader}>Application Settings</Typography>
        <table className={classes.propertiesTable}>
          <tbody>
            <tr className={classes.propertiesRow}>
                <td className={classes.labelCol}>Require Invitation</td>
                <td className={classes.alignRight}>{settings.isInviteRequired ? "Yes" : "No"}</td>
                <td className={classes.actionsCol}>
                  <Switch checked={settings.isInviteRequired} onChange={e => onSettingChanged("isIniteRequired", e)} />
                </td>
              </tr>
            </tbody>
        </table>
      </CardContent>
    </Card>
  );
}

export default SettingsCard;

const buildStyles   = makeStyles(theme => ({
  cardRoot  : {
    // padding     : theme.spacing(1),
    width         : "100%",
    marginRight   : theme.spacing(3),
  },
  cardHeader   : {
    fontSize    : 22,
    fontWeight  : 500,
    // textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  propertiesTable  : {
    marginTop       : theme.spacing(2),
    fontSize        : 16, 
    fontWeight      : 500,   
    width           : "100%",
  },
  propertiesRow    : {
    "&:not(:last-child)": {
      "& td"  : {
        borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
      },
    }
  },
  labelCol    : {
    fontSize        : 12,
    fontWeight      : 600,
    padding         : `${theme.spacing(2.5)}px ${theme.spacing(0)}px`, //`
    textTransform   : "uppercase",
    color           : theme.palette.grey[500],
    width           : 250,
  },
  actionsCol  : {
    textAlign   : "right",
  },
  alignRight: {
    textAlign : "right",
  }
}));