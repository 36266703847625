import _ from 'lodash';
import Api from './api-middleware';
import ErrorHelper from '../../helpers/error-helpers';

const fetchMiddleware = store => next => async action => {
    if(!action.fetch){
        return next(action);
    }

    const { fetch, types }  = action;
    const { dispatch }      = store;
    const myAction          = _.omit(action, ['fetch', 'types']);

    //dispatch the action type to flag that we're about to perform this action
    if(types.action){
        dispatch({
            type    : types.action,
            ...myAction        
        });
    }

    //execute the API call, then dispatch success or failure 
    try{
        const myJson = await Api.fetch(fetch);
        
        return dispatch({
            type    : types.success,
            ...myJson,
            ...myAction
        });
    }
    catch(ex){
        const err   = ErrorHelper.tryNormalize(ex);
        console.error("Error occurred during fetch", err);
        //TODO: Dispatch an app-level unhanded exception?
        return dispatch({
            type    : types.failure,
            error   : err,
            ...myAction
        });
    }
}

export default fetchMiddleware;