import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { trackEvent, Events } from 'helpers/analytics';
import { ErrorAlert, StatusCard } from 'components';
import { selectProfile } from 'store/selectors/app-selectors';
import { updateProfile } from 'store/actions/app-actions';
import { saveDataToServer, clearDataFromServer } from 'store/actions/value-actions';

const MoveDataDialog = ({isOpen, onClose, forShare}) => {
  const [props, setProps]   = useState({isChecked: false, isMoving: false, error: null}); 
  const profile   = useSelector(state => selectProfile(state));
  const invites   = useSelector(state => state.share.invitations);
  const dispatch  = useDispatch(); 
  const classes   = buildStyles();

  if(!isOpen) return null;

  const isToServer  = !Boolean(profile.isRemote);
  const isLocked    = !isToServer && _.some(invites, inv => inv.status === 100);
  const title       = forShare ? " and accept Request" : (isToServer ? "to the server" : "to my computer");

  const onSave  = async() => {
    trackEvent(isToServer ? Events.move_data_server : Events.move_data_client);
    const changes   = {
      isRemote  : isToServer,      
    };

    //different order based on to the server or to the client
    const action1  = isToServer ? () => dispatch(saveDataToServer(true)) : () => dispatch(updateProfile(changes));
    const action2  = isToServer ? () => dispatch(updateProfile(changes)) : () => dispatch(clearDataFromServer());

    const result1   = await action1();
    if(result1.status === "error"){
      console.error("failed with step 1 in moving data", {toServer: isToServer}, result1.error);
      setProps({...props, error: result1.error});
      return;
    }

    const result2   = await action2();
    if(result1.status === "error"){
      console.error("failed with step 2 in moving data", {toServer: isToServer}, result2.error);
      setProps({...props, error: result2.error});
      return;
    }

    setProps({isChecked: false, isMoving: false, error: null});
    onClose(true);
  }

  const closeDialog = () => {
    if(!props.isMoving){
      setProps({isChecked: false, isMoving: false, error: null});
      onClose(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>Move data {title}</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid id="data-view" container justifyContent="center" className={classes.contentRoot} spacing={2}>
          <Grid item xs={12}>
            <ErrorAlert error={props.error} title="Update Failed" onClose={() => setProps({...props, error: null})} />
            <StatusCard isVisible={isLocked} status="warning">
              <Typography className={classes.contentText}>
                You must revoke access from all reviewers before moving your data.  You may revoke access on the <Link to="/my/sharing">My Sharing</Link> tab.
              </Typography>
            </StatusCard>
          </Grid>
          <Grid item xs={12}>
            {isToServer && 
              <React.Fragment>
                {forShare && 
                  <Typography className={classes.notifyText}>
                    In order to share your data with a reviewer, you must move your data to the server.
                  </Typography>
                }
                <Typography className={classes.contentText}>
                  By choosing to store your data on the FigureEx servers, you confirm that you have read the <a href="/legal/terms-of-service" target="_blank">terms of service</a>, and the <a href="/legal/terms-of-service#privacy" target="_blank">privacy policy</a>.
                </Typography>
                <Typography className={classes.contentText}>
                  If, at any time, you wish to change the location of your data, you may do so by returning to the My Data tab, and clicking the Move Data option again.  To continue, please check the 'I Agree' box below then click the Move Data button.
                </Typography>
              </React.Fragment>
            }
            {!isToServer && 
              <React.Fragment>
                <Typography className={classes.contentText}>
                  This option will remove your data from the FigureEx servers. Your data will remain stored in your browser's local storage. <strong>You will not lose any data</strong> in this process, however your data will be stored locally, so if you clear your browser's local storage, your data may be lost.
                </Typography>
                <Typography className={classes.contentText}>
                  If, at any time, you wish to change the location of your data, you may do so by returning to the My Data tab, and clicking the Move Data option.  To continue, <strong>please check the 'I Agree' box</strong> below then click the Move Data button.
                </Typography>
              </React.Fragment>
            }
          </Grid>
          <Grid item container>
            <FormControlLabel classes={{label: classes.check}}
              control={
                <Checkbox value="isChecked" color="primary" size="small" disabled={isLocked} checked={props.isChecked} onChange={() => setProps({isChecked: !props.isChecked})}/>
              }
              label="I Agree"
            />
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button id="cancel" size="small" color="default" variant="outlined" onClick={closeDialog} disabled={props.isMoving}>Cancel</Button>
        <Button id="save" size="small" color="primary" variant="outlined" onClick={onSave} disabled={!props.isChecked || props.isMoving || isLocked}>Move Data {title}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MoveDataDialog;

const buildStyles   = makeStyles(theme => ({
  title   : {
    padding     : theme.spacing(2),
    background  : theme.palette.primary.light,
    color       : theme.palette.primary.contrastText,
  },
  titleText   : {
    fontSize    : 20,
    fontWeight  : 500,
  },
  contentRoot  : {
    padding     : theme.spacing(1),
  },
  notifyText  : {
    fontSize  : 18,
    fontWeight: 400,
    color     : theme.palette.secondary.light,
    marginBottom  : theme.spacing(1.5),
  },
  contentText  : {
    fontSize  : 16,
    fontWeight: 300,
    marginBottom  : theme.spacing(1),
    "& a" : {
      textDecoration  : "none",
      color   : theme.palette.secondary.main,
    },
    "& strong" : {
      fontWeight  : 500,
    }
  },
  check   : {
    fontSize  : 16,
  }
}));