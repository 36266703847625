import React from 'react';
import ReactDOM from 'react-dom';
import App from './sections/app/app';
import { bootstrapApp } from 'config/app-config';

const config  = bootstrapApp();
// const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const render = () => {
  ReactDOM.render(
    // <React.StrictMode>
      <App config={config}/>,
    // </React.StrictMode>,
    rootElement
  );
};

render();

if (module.hot) {
  module.hot.accept("./sections/app/app", () => {
    render();
  });
}