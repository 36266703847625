import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatDateString } from 'helpers/general-helpers';
import { useHover, useAnchor, usePrepareModel } from 'helpers/hooks';
import { SHARE_LABELS, SHARE_CODES } from 'config/share-config';
import { acceptInvite, rejectInvite } from 'store/actions/share-actions';
import { addReviewer, removeReviewer } from 'store/actions/value-actions';

const SharingListItem = ({invite, share}) => {
  const classes   = buildStyles();
  const dispatch  = useDispatch();
  const data      = usePrepareModel(invite);
  const [isOver, onEnter, onLeave]  = useHover();
  const [anchor, isOpen, onMenuOpened, onMenuClosed, position]   = useAnchor();
  // const [isDeleting, setDeleting]   = useState(false);

  if(!data) return null;
  const isAccepted  = invite.status === SHARE_CODES.accepted;
  const isRejected  = invite.status === SHARE_CODES.rejected;
  const statusLabel = SHARE_LABELS[invite.status || 0];
  const url         = `/my/data/view/${share?.reviewer}`; //`
  
  const onAccept  = async () => {
    const result  = await dispatch(acceptInvite(invite));
    if(result.isOk){
      await dispatch(addReviewer(invite.senderId));
    }
    onMenuClosed();
  }
  const onReject  = async () => {
    //if this is already shared, need to remove the reviewer
    let isOk  = true;
    if(share){
      const result  = await dispatch(removeReviewer(share.reviewer));
      isOk  = result.isOk;
    }
    
    if(isOk){
      await dispatch(rejectInvite(invite.id, share.id));
    };

    onMenuClosed();
  }

  return (
    <tr  className={clsx(classes.inviteRow, {[classes.rejectedRow] : isRejected}, {"over": isOver})} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <td><Link to={url}>{data.senderName}</Link></td>
      <td>{data.senderEmail}</td>
      <td className={classes[statusLabel]}>{statusLabel}</td>
      <td>{formatDateString(data.invitedAt, "M/DD/YYYY")}</td>
      <td className="moreCol">
        <IconButton size="small" onClick={onMenuOpened}>
          <MoreVertIcon fontSize="small"/>
        </IconButton>
        <Menu id="moreMenu" anchorEl={anchor} open={isOpen} onClose={onMenuClosed} disabled={statusLabel.isWorking} transformOrigin={position.transformOrigin}>
          {isAccepted && <MenuItem disabled={isRejected} fontSize="small" className={classes.menuItem} onClick={onReject}>Revoke Access</MenuItem>}
          {isRejected && <MenuItem disabled={isAccepted} fontSize="small" className={classes.menuItem} onClick={onAccept}>Allow Access</MenuItem>}
        </Menu>
      </td>
    </tr>
  );
}

export default SharingListItem;

const buildStyles   = makeStyles(theme => ({
  inviteRow   : {
    fontSize    : 14,
    fontWeight  : 500,
    color       : theme.palette.grey[700],
    "& td"  : {
      padding   : `${theme.spacing(1.5)}px ${theme.spacing(1)}px`, //`,
      "& a"  : {
        textDecoration  : "none",
        color           : theme.palette.secondary.main,
        "&:hover"   : {
          textDecoration  : "underline",
        },
      },  
      "&.moreCol"   : {
        width       : 180,
        padding     : `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
        textAlign   : "right",
        "& button"  : {
          display   : "none",
        },        
      }   
    },
    "&.over .moreCol"  : {
      "& button"  : {
        display   : "unset",
      },
    },
  },
  menuItem  : {
    fontSize    : 15,
  },
  deleteRow   : {
    background    : theme.palette.error.light,
  },
  actionButton  : {
    marginLeft  : theme.spacing(0.5),
    fontSize    : 15,
    fontWeight  : 600,
    display     : "inline-block",
  },
  accepted  : {
    color     : theme.palette.primary.main,
    fontWeight: 600,
  },
  rejected  : {
    color     : theme.palette.error.main,
    fontWeight: 600,
  },
  rejectedRow   : {
    background  : theme.palette.error.light,
  }
}));