import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionActions from '@material-ui/core/AccordionActions';
import Icon from '@material-ui/core/Icon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTableSection from './data-table-section';
import fHelper from 'helpers/financials-helper';
import nHelper from 'helpers/number-helper';
import { useConversation } from 'helpers/selector-hooks';
import { selectCurrentConversation } from 'store/selectors/conversation-selectors';

const DataTableRegion = ({region, values, profile, isReviewer, isDefaultExpanded}) => {

  const classes         = buildStyles();
  const panelHeaderId   = `panel-header-${region.id}`; //`
  const panelBodyId     = `panel-body-${region.id}`; //`
  
  // const title = isReviewer ? region.title : <Link to={region.path}>{region.title}</Link>;
  const isTotaled   = !!region.totals;
  const total       = isTotaled ? nHelper.asCurrency(fHelper.calculateRegionTotal(region, values), "$0") : "";
  const isNegative  = Boolean(["deductions", "expenses", "debts"].indexOf(region.id) >= 0);

  return (
      <Accordion defaultExpanded={isDefaultExpanded} className={classes.panelRoot} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary className={classes.panelHeader} classes={{content: classes.panelHeaderContent, expandIcon: classes.expandIcon}} expandIcon={<ExpandMoreIcon />} aria-controls={panelBodyId} id={panelHeaderId}>
          
          <Grid container className={classes.headerGrid}>
            <Grid item container alignItems="center">
              <Icon fontSize="small" color="inherit" className={classes.titleIcon}>{region.muiIcon}</Icon>
              <Typography className={classes.title}>{region.title}</Typography>
            </Grid>
            <Grid item>
              <Typography className={clsx(classes.total, {[classes.neg]: isNegative})}>{total}</Typography>
            </Grid>
          </Grid>

        </AccordionSummary>
        <AccordionDetails className={classes.panelBody}>
          {isReviewer && <ReviewerRegionContent region={region} values={values} profile={profile} /> }
          {!isReviewer && <UserRegionContent region={region} values={values} profile={profile} /> }
        </AccordionDetails>
      </Accordion>
  );
}

export default DataTableRegion;

const buildStyles   = makeStyles(theme => ({
  panelRoot  : {
    // padding     : theme.spacing(1),
    // width     : "100%",
  },
  panelHeader   : {
    fontSize    : 16,
    fontWeight  : 500,
    color       : theme.palette.primary.dark,
    "&.Mui-expanded" : {
      fontWeight  : 600,
      borderBottom  : `1px solid ${theme.palette.primary.light}`, //`
      background    : `${theme.palette.primary.main}22`, //`
      minHeight     : theme.spacing(6),
    }
  },
  panelHeaderContent  : {
    margin      : `${theme.spacing(1)}px ${theme.spacing(0)}px !important`, //`    
  },
  headerGrid  : {
    flexWrap    : "nowrap",
  },
  panelBody   : {
    padding     : `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
  },
  expandIcon  : {
    padding     : `${theme.spacing(0)}px ${theme.spacing(1.5)}px`, //`
  },
  titleIcon   : {
    marginRight   : theme.spacing(1),
    display       : "inline-block",
  },
  title   : {
    fontSize    : 16,
    fontWeight  : 500,
    display     : "inline-block",
    "& a, a:hover"   : {
      textDecoration  : "none",
      color           : theme.palette.primary.main,
    },
  },
  total   : {
    fontSize    : 16,
    fontWeight  : 500,
    marginRight   : theme.spacing(2),
  },
  neg : {
    color     : theme.palette.error.main,
  }
}));

function ReviewerRegionContent({region, profile, values}){
  const conversation  = useConversation();

  return (
    <Grid container direction="column">
      {_.map(region.sections, sec => <DataTableSection key={sec.id} section={sec} values={values} profile={profile} isReviewer={true} conversation={conversation}/>)}
    </Grid>
  );
}

function UserRegionContent({region, profile, values}){
  const conversation  = useSelector(selectCurrentConversation);

  return (
    <Grid container direction="column">
      {_.map(region.sections, sec => <DataTableSection key={sec.id} section={sec} values={values} profile={profile} isReviewer={false} conversation={conversation}/>)}
    </Grid>
  );
}