import React, { useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LandingHeader from './landing-header';
import LandingFooter from './landing-footer';
import phone from './phone.jpg';
import { ErrorAlert, StatusCard } from 'components';
import { sendContactMessage } from 'store/actions/app-actions';

const attribution   = {
  name  : "Alexander Andrews",
  handle: "@alex_andrews",
  url   : "https://unsplash.com/@alex_andrew",
};
const DEFAULT_STATE   = {name: "", email: "", message: "", validation: "", isSent: false};

function ContactPage({}){
  const classes   = buildStyles({background: phone});
  const [props, setProps]   = useState(DEFAULT_STATE);
  const [error, setError] = useState({hasErrors: false, validation: []});
  const dispatch            = useDispatch();

  const onInputChanged = (e) => {
    const key   = e.target.id;
    const val   = e.target.value;
    setProps({
      ...props,
      [key]   : val,
    });
  }

  const sendMessage = async () => {
    //validation
    const validation  = validate(props);
    setError(validation);
    if(validation.hasErrors){
      return;
    }

    const data    = {..._.omit(props, ["isSent", "validation"]), sentOn: new Date().toLocaleString()};
    const result  = await dispatch(sendContactMessage(data));
    if(result.status === "ok"){
      setProps({...DEFAULT_STATE, isSent : true});
    }
    else{
      setError({hasErrors: true, validation: [...error.validation || [], result.error]});
    }
  }

  return (
    <Grid container className={classes.contactPage}>  
      <LandingHeader/>
      <Grid item container className={classes.contactView}>
        <Grid item xs={5} className={classes.imageCol}>
        <div className={classes.overlay}>
          <span className={classes.attribution}>Photo by <a href={attribution.url} target="blank">{attribution.name}</a> on <a href="https://unsplash.com/" target="blank">Unsplash</a></span>
        </div>
        </Grid>
        <Grid item xs={7} container className={classes.contentCol} direction="column">
          <Grid item container justifyContent="center">
            <Typography variant="h2" className={classes.titleText}>Drop us a line, we'd love to hear from you</Typography>
          </Grid>
          <Grid item container justifyContent="center" alignItems="center" className={classes.bmarg}>
            <Typography classes={classes.infoText}>Email us at</Typography>
            &nbsp;
            <a href="mailto:info@figureex.com">info@figureex.com</a>
          </Grid>
          <Grid item container justifyContent="center" className={classes.bmarg}>
            - OR -
          </Grid>

          <Grid item container>
            <StatusCard isVisible={props.isSent} status="ok" message="Thank you, your message has been sent." className={classes.statusCard}/>
            {error.hasErrors && <ErrorAlert error={error} title="Please correct the following errors" onClose={() => setError({hasErrors: false})} className={classes.statusCard}/> }
          </Grid>

          <Grid item container className={classes.fieldContainer}>
            <TextField id="name" label="Name" variant="outlined" className={classes.contactField} fullWidth value={props.name} onChange={onInputChanged}></TextField>
            <TextField id="email" label="Email" variant="outlined" className={classes.contactField} fullWidth value={props.email} onChange={onInputChanged}></TextField>
            <TextField id="message" label="Message" multiline rows={8} maxLength={1024} variant="outlined" className={classes.contactField} fullWidth value={props.message} onChange={onInputChanged}></TextField>
            <Grid item container justifyContent="space-between">
              <Grid item xs={9} container alignItems="center">
                <Typography className={classes.fieldText}>Five plus 5 equals</Typography>
                <TextField id="validation" label="I'm human" variant="outlined" className={clsx(classes.bmarg, classes.catchpa)} value={props.validation} onChange={onInputChanged}/>
              </Grid>
              <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
                <Button className={classes.bmarg} variant="outlined" color="secondary" onClick={sendMessage}>Send</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>    
      <LandingFooter/>
    </Grid>
  )
}

export default ContactPage;

const buildStyles   = makeStyles(theme => ({
  contactPage   : {
    width   : "100%",
    height  : "100vh",
  },
  contactView  : {
    minHeight    : "calc(100vh - 120px)",
    marginTop : 60,    
  },
  bmarg   : {
    marginBottom  : theme.spacing(2),
  },
  imageCol  : props => ({
    background          : `url(${props.background})`,
    backgroundSize      : "cover",
    backgroundPosition  : "0%",   
    height              : "100%", 
    position: "relative",
  }),
  contentCol : {
    // background    : theme.palette.grey[400],    
  },
  titleText   : {
    fontSize    : 26,
    fontWeight  : 600,
    margin      : `${theme.spacing(4)}px`,
    textAlign   : "center",
  },
  fieldContainer  : {
    padding   : `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  contactField  : {
    marginBottom  : theme.spacing(2),
  },
  fieldText   : {
    marginBottom  : theme.spacing(2),
    marginRight   : theme.spacing(1),
  },
  sendButton  : {
    marginBottom  : theme.spacing(2),
  },
  attribution : {
    fontSize        : 14,
    fontWeight      : 400,
    position        : "absolute",
    right           : "10px",
    bottom          : "10px",
    color           : `${theme.palette.grey[900]}44`,
    "& a"           : {
        color       : `${theme.palette.grey[900]}44`,
    }
  },
  catchpa   : {
    width         : 135,
  },
  statusCard  : {
    margin  : "0 15px",
  }
}));

function validate(props){
  let errors      = [];
  let hasErrors   = false;
  if(!props.name || props.name.length === 0){
    errors.push("Name is required");
    hasErrors   = true;
  }
  if(!props.email || props.email.length === 0){
    errors.push("Email is required");
    hasErrors   = true;
  }
  if(!props.message || props.message.length === 0){
    errors.push("Message is required");
    hasErrors   = true;
  }
  
  if(!props.validation || props.validation.length === 0){
    errors.push("Please answer the validation question at the bottom");
    hasErrors   = true;
  }
  else if(props.validation !== "10" && props.validation.toLowerCase() !== "ten"){
    errors.push("Please double check your answer to the human validation");
    hasErrors   = true;
  }

  return {
    validation  : errors,
    hasErrors   : hasErrors,
  };
  
}