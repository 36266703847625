import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import PropTypes from 'prop-types';
import nHelper from 'helpers/number-helper';
import fHelper from 'helpers/financials-helper';
import { InputField } from './';
import { withFieldChanged } from './with-field-changed';
import { deleteRow } from 'store/actions/value-actions';

function ListSection({section, values, onChange, onFieldChanged}){
    const dispatch  = useDispatch();
    
    const renderHeader = (section, fields, data, isCollapsed) => {
        let titleRow    = null;
        
        if(section.title){
            let colSpan     = fields.length;
            let headerField = null;
            let descField   = null;

            if(section.headerField){
                // colSpan         = colSpan - 1;
                const hField    = fHelper.getField(section.headerField); 
                headerField     = <InputField field={hField} data={data} onChangeOverride={onFieldChanged}/>;
                // headerField     = (
                //     <td colSpan="1" className="section-title-field text-right">
                //         <InputField field={hField} data={data} onChangeOverride={onFieldChanged}/>
                //     </td>
                // )
            }

            if(section.desc){
                descField = (
                    <p className="section-desc">
                        {section.desc}
                    </p>
                )
            }

            titleRow    = (
                <tr className="section-header">
                    <td colSpan={colSpan} className="section-title-col">
                        {headerField}
                        <span className="section-title">{section.title}</span>
                        <span className="section-title-desc">{descField}</span>
                    </td>
                    {/* {headerField} */}
                </tr>
            );
        }

        let colHeaders  = null;
        if(!isCollapsed){
            // const colClass  = `field-${f.size || 'sm'}`;
            const cols      =  _.map(fields, f => (<td key={f.id} className={"field-" + (f.size || "sm")}>{f.header}</td>))
            colHeaders      = (
                <tr className="list-col-headers">
                    {cols}
                    <td className="delete-col"></td>
                </tr>
            );
        }

        return (
            <thead>
                {titleRow}
                {colHeaders}
            </thead>
        )
    }

    const renderRow = (fields, sectionId, data, index) => {
        const isEmpty   = data === null;
        data            = data || {};
        let cIndex      = 0;
        const dataProps = {"data-index" : index};

        const cols  = _.map(fields, f => {
            const myIndex   = cIndex++;
            const colClass  = `list-col-${f.type} field-${f.type}`;
            return (
                <td key={myIndex} className={colClass}>
                    <InputField field={f} sectionId={sectionId} rowIndex={index} data={data} index={myIndex} dataProps={dataProps}/>
                </td>
            )
        });

        return (
            <tr key={index}>
                {cols}
                {!isEmpty && 
                    <td className="delete-col">
                        <Tooltip title="Delete this row">
                            <DeleteIcon fontSize="small" onClick={() => dispatch(deleteRow(sectionId, index))}/>
                        </Tooltip>
                    </td>
                }
            </tr>
        );
    }

    const renderSubtotalRow = (fields, rowData) => {
        rowData        = rowData || [];
        let total   = 0;

        const cols  = _.map(fields, f => {
            
            let content     = null;
            let colKey      = `${f.id}-total`;

            if(f.isSummed){
                content      = fHelper.sumField(f, rowData);
                total       += content;

                if(f.type === "currency"){
                    content = nHelper.asCurrency(content);
                }
            }
            else if(fields.indexOf(f) === 0){
                //This is the first field, so add a "Totals" value
                const colClass  = `subtotal-label field-text text-left`;
                return <td key={colKey} className={colClass}>Total</td>
            }

            const fieldClass    = `subtotal-val field-${f.type}`;
            return <td key={colKey} className={fieldClass}>{content}</td>;
        });

        if(total === 0) return null;
        return (
            <tr className="section-subtotal">
                {cols}
            </tr>
        )
    }

    const renderRows = (section, fields, data, isCollapsed) => {
        
        if(isCollapsed){
            return null;
        }

        let rowData     = data[section.id] ? [...data[section.id]] : [];
        const minRows   = 1;       
        if(rowData.length < minRows){
            for(let i = 0; i < minRows; i++) rowData.push(null);
        }
        else{
            rowData.push(null); //always have one blank row at the end
        }

        let index       = 0;
        const rowNodes  = _.map(rowData, d => renderRow(fields, section.id, d, index++));
        const subtotals = renderSubtotalRow(fields, rowData);

        return (
            <tbody onChange={onFieldChanged}>
                {rowNodes}
                {subtotals}
            </tbody>
        );
    }

    const fields        = fHelper.getFields(section.fields);
    const isCondUndef   = fHelper.isConditionUndefined(section.condition, values);
    const isCondNotMet  = !fHelper.isConditionMet(section.condition, values);
    const isCollapsed   = isCondUndef || isCondNotMet;
    const headerRow     = renderHeader(section, fields, values, isCollapsed);
    const body          = renderRows(section, fields, values, isCollapsed);

    return (
        <div className="section-div">
            <table className="table table-bordered table-condensed section-table list-section">
                {headerRow}
                {body}
            </table>
        </div>
    );
}

ListSection.propTypes   = {
    section     : PropTypes.object.isRequired,
    values      : PropTypes.object.isRequired,
    onChange    : PropTypes.func.isRequired
};

export default withFieldChanged(ListSection);