import _ from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

const _getConversations   = state => state.convo.conversations;
const _getSelectedConvo   = state => state.convo.selectedId;
const _getShares          = state => state.share.shares;
const _getInvitations     = state => state.share.invitations;
const _inItemId           = (state, itemId) => itemId;
// const _inItemId           = (state, itemId) => itemId;
// const _inSubItemId        = (state, itemId, subItemId) => subItemId;
const _getFieldComments   = (state, fieldKey) => state.convo.currentComments[fieldKey];


export const selectConversations = createSelector(
  [_getConversations, _getShares, _getInvitations],
  (conversations, shares, invitations) => {
    if(!conversations) return null;

    const results   = _.map(conversations, convo => {
      const result   = prepareConversation(convo, shares, invitations);
      return result;
    });

    return results;
  }
);



export const selectCurrentConversation = createSelector(
  [_getConversations, _getSelectedConvo, _getShares, _getInvitations],
  (conversations, selectedId, shares, invitations) => {
    if(!conversations || !selectedId || selectedId === "0") return null;
    
    const convo   = prepareConversation(conversations[selectedId], shares, invitations);
    return convo;

  }
);

export const selectConversationByRequest = createSelector(
  [_getConversations, _getShares, _getInvitations, _inItemId],
  (conversations, shares, invitations, shareRequestId) => {
    if(!conversations || !shareRequestId) return null;
    const convo   =  _.find(conversations, c => c.requestId === shareRequestId);
    const result  = prepareConversation(convo, shares, invitations);
    return result;
  }
);

export const selectCurrentShare = createSelector(
  [_getSelectedConvo, _getShares],
  (conversationId, shares) => {
    if(!conversationId || conversationId === "0" || !shares) return null;
    //a conversation has the same ID as its share
    //could be either share.id or share.reviewer
    const share = _.find(shares, share => share.reviewer === conversationId) ||
                  _.find(shares, share => share.id === conversationId);  
    return share;
  }
);

export const selectFieldComments = createCachedSelector(
  [_getFieldComments],
  (comments) => {
    console.log(`comment selector called`); //`
    return comments || [];
  }
)(
  (_, fieldKey) => fieldKey //the cache key
);


//-----------------
// Helper function to build the conversation with the necessary properties
function prepareConversation(convo, shares, invitations){
  const share   = _.find(shares, share => share.id === convo.shareId);
  const invite  = _.find(invitations, inv => inv.id === convo.requestId);
  return {
    ...convo,
    share   : share,
    invite  : invite,
  };
}

//-----------------
// Will prepare an item to be displayed / used in a checklist.
//  used by the AssertionItem component to shape the information as necessary
//-----------------
// export const selectCommentsByField = createCachedSelector(
//   [_getConversations, _inItemId],
//   (conversations, convoId) => {
//     if(!conversations || !convoId) return null;
    
    
//   }
// )(
//   (_, itemId) => itemId, //key for re-reselect caching
// );

// export const selectCurrentConversation = createSelector(
//   [_getConversations, _getCurrentConversation],
//   (conversations, convoId) => {
//     if(!conversations) return null;
    
//   }
// )