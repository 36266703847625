import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { selectProfile } from 'store/selectors/app-selectors';
import ReviewerView from '../reviewer/reviewer-view';
import FinancialsView from '../financials/financials-view';

const AppView = () => {
  const classes   = buildStyles();
  const profile   = useSelector(selectProfile);

  return (
    <Grid id="app-view" container justifyContent="center" className={classes.appViewroot}>
      {profile.isReviewer && <ReviewerView />}
      {profile.isUser && <FinancialsView />}
    </Grid>
  );
}

export default AppView;

const buildStyles   = makeStyles(theme => ({
  appViewroot  : {
    // padding     : theme.spacing(1),
  },
  
}));