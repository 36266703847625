import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AuthProviders from './auth-providers';
import StandardLogin from './standard-login';
import TextDivider from '../../../components/text-divider';
import { signIn } from 'store/actions/app-actions';
import { useStatus } from 'redux-action-status';
// import { StatusCard } from 'components';

function SignIn({changeTab}){

  const classes       = useStyles();
  const dispatch      = useDispatch();
  const user          = useSelector(state => state.app.user);
  const profile       = useSelector(state => state.app.profile);
  const status        = useStatus("signIn");

  //They've already signed in, just need to enter their invitation
  if(user && !profile && changeTab) changeTab(1);

  const onAuthenticate = async (provider, username, password) => {
    await dispatch(signIn(provider, username, password));    
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <AuthProviders header="Sign in with" onAuth={onAuthenticate} isWorking={status.isWorking}/>
      <TextDivider text="or" cols={8} />
      <StandardLogin onAuth={(uname, pwd) => onAuthenticate(null, uname, pwd)} onToggle={() => changeTab(1)} isWorking={status.isWorking}/>

      {/* <StatusCard status="error" message={error} isVisible={Boolean(error)} /> */}
    </Grid>
  );
}

export default SignIn;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
}));