import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import bgImage from './security2.jpg'; //'./data-security.jpeg'; //'./lock.jpg';
// import { agreeToTos } from 'store/actions/onboarding-actions';
import { getCurrentDateTime } from 'store/reducers/reducer-helpers';
import { updateProfile } from 'store/actions/app-actions';
import { selectProfile } from 'store/selectors/app-selectors';
// import { IfBlock } from 'components';
import { trackEvent, Events } from 'helpers/analytics';

// const attribution   = {
//   name    : "John Salvino",
//   handle  : "@jsalvino",
//   url     : `https://unsplash.com/@jsalvino`,
// };

// const attribution   = {
//   name    : "pikisuperstar",
//   handle  : "@pikisuperstar",
//   url     : `https://www.freepik.com/pikisuperstar`,
// };
const attribution   = {
  url     : "https://www.freepik.com/free-photos-vectors/background",
};
//<a href="https://www.freepik.com/free-photos-vectors/background">Background vector created by pikisuperstar - www.freepik.com</a>


function WelcomeData({classes}){
  // const [value, setValue]   = useState("server");
  const value     = "server";     //NOTE: for now, removing the local option
  const dispatch  = useDispatch();
  const profile   = useSelector(selectProfile);

  const onAgreed  = async () => {
    const isRemote    = Boolean(value === "server");
    trackEvent(isRemote ? Events.choose_remote : Events.choose_local);
    const result  = await dispatch(updateProfile({isRemote: isRemote, tosAcceptedOn: new Date().toUTCString()}));
    if(!result.isOk){
      console.error("Failed to update profile with remote setting.");
    }

    // return await dispatch(agreeToTos());
  }

  return (

    <Grid container className={classes.root}>
      <Grid item sm={4} className={classes.leftCol}>
        <div className={classes.overlay}>
          {/* <span className={classes.attribution}>Image by <a href={attribution.url} target="blank">{attribution.name}</a> on <a href="https://www.freepik.com/free-photos-vectors/background" target="blank">Freepik.com</a></span> */}
          <span className={classes.attribution}>Image from <a href={attribution.url} target="blank">Freepik</a></span>
        </div>
      </Grid>

      <Grid item sm={8} xs={12} className={classes.rightCol}>
        <Grid container className={classes.rightContent}>
          <Grid container className={classes.headerBar}>
            <Typography variant="h2" className={classes.header}>Security &amp; Privacy</Typography>
          </Grid>
          <Typography variant="h4" className={classes.subHeader}>We are serious about your privacy</Typography>
            
          <Grid container className={classes.content}>
            <Typography className={classes.text}>
              <strong>Always Encrypted</strong>
              <br/>Your data is protected with military-grade encryption.
            </Typography>
            <Typography className={classes.text}>
              <strong>Never Sold</strong>
              <br/>We will never sell or share your personal information.
            </Typography>
            <Typography className={classes.text}>
              <strong>You're in control</strong>
              <br/>At any time, you can delete all your data from our servers.
              {/* <br/>You choose where your data lives */}
            </Typography>
            
          </Grid>

          {/* <IfBlock condition={!profile.isReviewer}>
            <Grid container className={classes.content}>
              <FormControl component="fieldset">
                <RadioGroup name="data-location" value={value} onChange={(e) => setValue(e.target.value)}>
                  <FormControlLabel value="server" classes={{label: classes.radioLabel}} className={classes.radioControlLabel} label="Save on the server" control={<Radio color="primary" />}/>
                  <Typography className={classes.radioInfo}>Access your data from any computer, and invite your lawyer to view and comment.</Typography>
                  <FormControlLabel value="local" classes={{label: classes.radioLabel}} className={classes.radioControlLabel} label="Save on my computer" control={<Radio color="primary" />}/>
                  <Typography className={classes.radioInfo}>Saved on your computer, in this browser.  You will need to export your data to use it elsewhere or share it with your lawyer.</Typography>
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid container className={classes.content}>
              <Typography className={classes.fyi}>(You can change this setting at any time in your Profile)</Typography>
            </Grid>

          </IfBlock> */}

          <Grid container className={classes.tos}>
            <Typography className={classes.text}>
              By clicking continue, you agree to our <a href="/legal/terms-of-service" target="_blank">Terms of Service</a> and our <a href="/legal/terms-of-service#privacy" target="_blank">Privacy Policy</a>.
            </Typography>
          </Grid>

          <Button className={classes.agreeButton} elevation={0} variant="contained" color="primary" onClick={onAgreed}>Continue</Button>
        </Grid>
      </Grid>
    </Grid>

  );
}

const styles  = (theme => ({
  root        : {
    height          : "100%",
  },
  leftCol   : {
    backgroundImage     : `url(${bgImage})`,
    backgroundSize      : "cover",
    backgroundPosition  : "50%",   
    position            : "relative", 
    borderRadius        : "4px 0 0 4px",
    padding             : "1px 0 1px 1px",
  },
  rightCol  : {
    borderRadius        : "0 4px 4px 0",
  },
  attribution : {
    fontSize        : 14,
    fontWeight      : 400,
    position        : "absolute",
    right           : "10px",
    bottom          : "10px",
    color           : `${theme.palette.grey[50]}66`,
    "& a"           : {
        color       : `${theme.palette.grey[50]}66`,
    }
  },
  rightContent : {
    height          : "100%",
    position        : "relative",
    alignContent    : "flex-start",
  },
  agreeButton   : {
    position        : "absolute",
    bottom          : "15px",
    right           : "13px",
  },
  headerBar   : {
    borderTop   : `10px solid ${theme.palette.primary.dark}`,
    background  : theme.palette.primary.main,
    padding     : 15,
    borderRadius: "0 4px 0 0",
  },
  header  : {
    fontSize        : 28,
    fontWeight      : 400,
    color           : theme.palette.primary.contrastText,
    marginBottom    : 0,
  },
  subHeader   : {
    padding       : theme.spacing(2),
    fontSize      : 22,
    fontWeight    : 500,
    color         : theme.palette.grey[700],
  },
  content   : {
    borderRadius  : 6,
    padding       : theme.spacing(2),  
    paddingTop    : theme.spacing(1),  
  },
  tos     : {
    padding       : theme.spacing(2),  
    paddingTop    : theme.spacing(4),  
  },
  text   : {
    fontSize      : 18,
    fontWeight    : 300,
    marginTop     : theme.spacing(1),
    marginBottom  : theme.spacing(1),
    color         : theme.palette.grey[600],
    "& strong": {
      color       : theme.palette.grey[700],
      fontSize    : 22,
      fontWeight  : 500,
    }
  },
  radioControlLabel   : {
    marginTop       : theme.spacing(2),
    "& .MuiButtonBase-root"   : {
      padding     : `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    }
  },
  radioLabel  : {
    fontSize      : 17,
    color         : theme.palette.grey[700],
  },
  radioInfo   : {
    fontSize      : 14,
    marginBottom  : theme.spacing(2),
    color         : theme.palette.grey[500],
    margin        : `0 ${theme.spacing(4)}px`, //`
    marginLeft    : theme.spacing(5),
  },
  fyi   : {
    fontSize      : 16,
    // display       : "inline",
    // marginBottom  : 10,
    // color         : theme.palette.primary.light,
    marginTop     : theme.spacing(3),
    color         : theme.palette.grey[500],
    // margin        : `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
    "& svg"   : {
      marginBottom  : theme.spacing(-1),
      marginRight   : theme.spacing(0.5),
      opacity       : 0.75,
      // fontSize      : 14,
    }
  }
}));


export default withStyles(styles)(WelcomeData);