import _ from 'lodash';
import { trackEvent, Events } from 'helpers/analytics';
import { getNowString } from './action-helpers';
import { SHARE_CODES } from 'config/share-config';
import { startStatus, finishStatus } from 'redux-action-status';

const REQUESTS_LOADED = "INVITES_REQUESTS_LOADED";
const REQUEST_CREATED = "INVITE_REQUEST_CREATED";
const REQUEST_DELETED = "INVITE_REQUEST_DELETED";
const RECEIVED_LOADED = "INVITES_RECEIVED_LOADED";
const RECEIVED_UPDATED= "INVITE_RECEIVED_UPDATED";
const SHARES_LOADED = "SHARES_LOADED", SHARE_CREATED = "SHARE_CREATED", SHARE_DELETED = "SHARE_DELETED";

export const SHARE_ACTIONS  = {
  RECEIVED_LOADED,
  
  REQUESTS_LOADED, REQUEST_CREATED, REQUEST_DELETED, RECEIVED_UPDATED,
  SHARES_LOADED, SHARE_CREATED, SHARE_DELETED,
};

const INVITE_FIELDS   = ["firstName", "lastName", "email", "message", "requestType"];

export const initializeSharing = () => async(dispatch, getState) => {

  const profile   = getState().app.profile;
  if(!profile) return null;
  
  await dispatch(startStatus("sharing"));

  const p1  = dispatch(loadSentInvites());
  const p2  = dispatch(loadReceivedInvites());
  const p3  = dispatch(loadShares());
  const results   = await Promise.all([p1, p2, p3]);
  
  await dispatch(finishStatus("sharing"));

  return {
    status  : "ok",
    isOk    : true,
    data    : results,
  };
}

//---------------
// Load a user's sent invitations
export const loadSentInvites = () => async(dispatch, getState) => {
  
  const state  = getState();
  
  const result  = await dispatch({
    type      : REQUESTS_LOADED,
    firebase  : {
      type        : "getList",
      collection  : "shareRequests",
      query       : ["senderId", "==", state.app.profile.uid],
      // order       : ["invitedAt", "desc"],
      hydrate     : true,
    },
    statusKey   : "requests",
  });

  return result;
}

//---------------
// Load a user's received invitations
export const loadReceivedInvites = (filter) => async(dispatch, getState) => {
  //Make sure we're not already loading the invites...
  const state  = getState();

  const result  = await dispatch({
    type      : RECEIVED_LOADED,
    firebase  : {
      type        : "getList",
      collection  : "shareRequests",
      query       : ["email", "==", state.app.profile.email],
      // order       : ["invitedAt", "desc"],
      hydrate     : true,
    },
    statusKey : "received",
  });

  return result;
}

//---------------------
//#region Shares (CRD)
export const loadShares = () => async(dispatch, getState) => {
  
  const profile   = getState().app.profile;
  const query     = (profile.role === "user") ? ["sharer", "==", profile.uid] : ["reviewer", "==", profile.uid];

  const result  = await dispatch({
    type      : SHARES_LOADED,
    firebase  : {
      type        : "getList",
      collection  : "shares",
      query       : query,
      // order       : ["sharedAt", "desc"],
      hydrate     : true,
    },
    statusKey   : "shares",
  });

  return result;
}

export const createShare = (invite) => async(dispatch, getState) => {
  
  const profile   = await getState().app.profile;

  const share   = {
    sharer    : profile.uid,
    reviewer  : invite.senderId,
    sharedAt  : getNowString(),
    requestId : invite.id,
  };

  const result  = await dispatch({
    type      : SHARE_CREATED,
    // failType  : SHARES_FAILED,
    firebase  : {
      type        : "create",
      collection  : "shares",
      value       : share,
    },
    statusKey   : "shares",
  });

  return result;
}

export const deleteShare = (id) => async(dispatch, getState) => {
  const result  = await dispatch({
    type      : SHARE_DELETED,
    // failType  : SHARES_FAILED,
    firebase  : {
      type        : "deleteSingle",
      collection  : "shares",
      key     : id,
    },
    id        : id,
    statusKey : "shares",
  });

  return result;
}
//#endregion

//#region Invitations
//---------------
// Invite a user to the application
export const sendInvite = (model) => async(dispatch, getState) => {
  
  trackEvent(Events.invite);  //Track this in analytics

  const state   = getState();
  const profile = state.app.profile;

  const safeModel   = {
    ..._.pick(model, INVITE_FIELDS),
    senderName    : profile.displayName,
    senderEmail   : profile.email,
    senderId      : profile.uid,
    senderType    : profile.role,
    invitedAt     : getNowString(),
    invitationId  : null,
    sentAt        : null,
  };

  const result  = await dispatch({
    type    : REQUEST_CREATED,
    firebase: {
      type        : "create",
      collection  : "shareRequests",
      value       : safeModel,
    },
    statusKey : "requests",
  });

  //TODO: create an invitation for this share, so the user can log in...
  //TODO: Send the email about the invitation, then update the DB with the details
  
  return result;
}

export const acceptInvite = (invite) => async(dispatch, getState) => {
  //+++++
  //NOTE: must also add a reviewer to the Values structure.  See addReviewer action in values-reducer.js
  //      that is not done here, so requires a sparate action from value-actions::addReviewer.
  //+++++
  const state     = await getState();
  const profile   = state.app.profile;
  
  const share   = {
    sharer    : profile.uid,
    reviewer  : invite.senderId,
    sharedAt  : getNowString(),
    requestId : invite.id,
  };

  const shareResult  = await dispatch({
    type      : SHARE_CREATED,
    firebase  : {
      type        : "create",
      collection  : "shares",
      value       : share,
    },
    statusKey   : "shares",
  });

  if(shareResult.isOk){
    //Now, need to update the invitation
    const changes   = {status: SHARE_CODES.accepted, modifiedAt: getNowString()};
  
    await dispatch({
      type      : RECEIVED_UPDATED,
      firebase  : {
        type        : "updateSingle",
        collection  : "shareRequests",
        key         : invite.id,
        value       : changes,
      },
      statusKey   : "received",
    });    
  }

  return shareResult;

}

export const rejectInvite = (inviteId, shareId) => async(dispatch, getState) => {
  //+++++
  //NOTE: may also need to remove a reviewer from the Values structure.  See removeReviewer action in values-reducer.js
  //+++++
  let shareResult   = {};
  if(shareId){
    
    shareResult  = await dispatch({
      type      : SHARE_DELETED,
      firebase  : {
        type        : "deleteSingle",
        collection  : "shares",
        key         : shareId,
      },
      id   : shareId,
      statusKey   : "shares",
    });
  }

  if(!shareId || shareResult.isOk){
    //Now, need to update the invitation
    const changes   = {status: SHARE_CODES.rejected, modifiedAt: getNowString()};
  
    await dispatch({
      type      : RECEIVED_UPDATED,
      firebase  : {
        type        : "updateSingle",
        collection  : "shareRequests",
        key         : inviteId,
        value       : changes,
      },
      id        : inviteId,
      statusKey : "received",
    });
  }

  return shareResult;

}

//---------------
// Update an invitation
export const updateReceivedInvite = (id, changes) => async(dispatch, getState) => {
  //_.pick to whitelist the changes that are allowed
  const myChanges   = _.pick(changes, ["viewedAt", "status", "modifiedAt"]);
  
  const result  = await dispatch({
    type      : RECEIVED_UPDATED,
    firebase  : {
      type        : "updateSingle",
      collection  : "shareRequests",
      key         : id,
      value       : myChanges,
    },
    id        : id,
    statusKey : "received",
  });

  return result;
}

//---------------
// Delete a sent invitation (request).  When someone wants to withdraw an
// invitation they've sent
export const deleteSentInvite = (id) => async(dispatch, getState) => {
  
  const result  = await dispatch({
    type      : REQUEST_DELETED,
    firebase  : {
      type        : "deleteSingle",
      collection  : "shareRequests",
      key         : id,
    },
    id        : id,
    statusKey : "requests",
  });

  return result;
}
//#endregion