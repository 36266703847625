import React from 'react';
import _ from 'lodash';
import { CurrencyField, InputField } from './';

export const SectionRow2 = ({field1, data, onChangeDetails}) => {
    return (
        <tr>
            <FieldNode field={field1} data={data} onChangeDetails={onChangeDetails}></FieldNode>
        </tr>
    );
}

const FieldNode = ({field, data, onChangeDetails}) => {
    if(field.type == "content"){
        return (
            <td colSpan="2">{field.label}</td>
        );
    }
    if(field.type == "checkbox"){
        const cbField   = _.omit(field, ["label"]);

        return (
            <React.Fragment>
                <td>{field.label}</td>
                <td><InputField field={cbField} data={data} onChangeOverride={() => {}}></InputField></td>
            </React.Fragment>
        );
    }
    else{
        //For now, everything else is currency...
        return <CurrencyField field={field} data={data} onChangeDetails={onChangeDetails}></CurrencyField>;
    }
}