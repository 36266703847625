import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Tabs } from 'components';

const SubHeader = ({classes, regions, saveData, reposition}) => {
  return (
    <Grid container className={classes.subHeader} alignItems="center">
      <Grid item sm={11}>
          <Tabs items={regions} domain="app" saveData={saveData} onChanged={() => reposition()}/>
      </Grid>               
    </Grid>
  );
}

SubHeader.propTypes     = {
    regions     : PropTypes.arrayOf(PropTypes.object).isRequired,
};

const styles    = (theme => ({
    subHeader   : {
        height          : 60,
        minHeight       : 60,
        borderBottom    : "1px solid #dee2e6",
        background      : theme.palette.common.white,
        color           : "#808080",
        padding         : "0 20px",
    },
    btn    : {
        padding         : "3px 6px",
        textTransform   : "none",
        fontWeight      : 400,
        minWidth        : "24px",
        "& svg"     : {
            fontSize        : "1.5rem",
            // marginRight      : theme.spacing(1),
        }
      }
}));

export default withStyles(styles)(SubHeader);