import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { CurrencyInput } from './';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import rHelper from 'store/reducers/reducer-helpers';
import nHelper from 'helpers/number-helper';

const NEW_ITEM  = {label: "", value: 0};

class DetailModal extends React.Component {
    constructor(props){
        super(props);

        this.onTextChanged  = this.onTextChanged.bind(this);
        this.onSave         = this.onSave.bind(this);
        // this.onCancel       = this.onCancel.bind(this);

        this.state  = {
            isOpen  : false,
            items   : []
        };
    }

    static getDerivedStateFromProps(props, state){
        if(props.isOpen && !state.isOpen){
            return {
                isOpen  : props.isOpen,
                items   : props.details && props.details.items ? props.details.items : []
            };
        }
        else if(state.isOpen && !props.isOpen){
            return {
                isOpen  : false,
                items   : []
            };
        }

        return null;
    }

    onTextChanged(event){
        const { id, value }     = event.target;
        const items = this.state.items;
        const parts = id.split("-");
        const index = parseInt(parts[1]);
        const isNew = items.length <= index;
        const orig  = isNew ? NEW_ITEM : items[index];
        const val   = parts[0] == "label" ? value : nHelper.asFloat(value);

        let updated     = {...orig, [parts[0]] : val};
        if(updated.label == "" && updated.value == 0){
            //They've cleared out the row... make sure we don't have an extra...
            updated     = null;
        }

        const newItems  = _.compact(rHelper.immAddOrReplace(items, updated, orig));
        this.setState({items: newItems});

        event.stopPropagation();
    }

    onSave(evt){
        const { field, onClose }   = this.props;
        const items     = _.compact(this.state.items);
        let data        = {total    : this.props.total, details : {field : field.id, items : null} };
        
        if(items && items.length > 0){
            data.total   = _.sumBy(items, itm => itm ? itm.value : 0);
            data.details.items   = items;
        }

        return onClose(data);
    }

    renderRow(item, index){
        const inputId   = `label-${index}`;
        const valueId   = `value-${index}`;
        const label     = item ? item.label : "";
        const value     = item ? item.value : 0;

        return (
            <tr key={index}>
                <td className="col-label">
                    <input id={inputId} type="text" className="input-md form-control" value={label} onChange={this.onTextChanged}></input>
                </td>
                <td className="col-value">
                    <CurrencyInput fieldKey={valueId} value={value} onChange={this.onTextChanged}/>
                </td>
            </tr>
        );
    }

    getRowItems(items){
        let result  = [...items];
        if(result.length < 1){
            for(let i = 0; i < 1 -  items.length; i++) result.push(null);
        }
        else{
            result.push(null);      //add one blank row at the bottom
        }

        return result;
    }

    render(){
        if(!this.props.isOpen) return null;        //don't show if we're not open
        
        const { field, total, onClose, classes } = this.props;
        const items     = this.getRowItems(this.state.items);
        const rows      = items.map((item, index) => { return this.renderRow(item, index)});
        const myTotal   = _.sumBy(items, itm => itm ? itm.value : 0);
        const warning   = (total && myTotal && total !== myTotal) ? <div className="alert alert-warning"><span>Existing total of {nHelper.asCurrency(total)} will be overwritten on save</span></div> : null;

        return (
            <Dialog open={true} onClose={onClose} classes={{paper: classes.dialogPaper}}>
                <DialogTitle className={classes.title}>Details for {field.label}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.explain}>
                        You may enter line-item details for this field here.  New rows will be added as you type.
                    </DialogContentText>
                    <Grid container>                    
                        {/* <div className="row">
                            <div className="col-md-12">
                                <p className="explain">You may enter line-item details for this field here.  New rows will be added as you type.</p>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-condensed table-bordered section-table">
                                    <tbody>
                                    {rows} 
                                    <tr>
                                            <td className="col-label">
                                                <h5>Total</h5>
                                            </td>
                                            <td className="col-value">
                                                <CurrencyInput fieldKey="total" isDisabled={true} value={myTotal}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {warning}
                            </div>
                        </div>
                        <Grid item xs={12} container alignItems="flex-end">
                            <button type="button" className="btn btn-default btn-md" onClick={() => {this.setState({items: []})}}>Clear</button>
                            <button type="button" className="btn btn-default btn-md" onClick={() => onClose()}>Cancel</button>
                            <button type="button" className="btn btn-primary btn-md" onClick={this.onSave}>Save</button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

function mapState(state, myProps){
    return {
    };
}

function mapActions(dispatch){
    return {
        actions     : null //bindActionCreators({updateFieldValue}, dispatch)
    };
}

DetailModal.propTypes   = {
    isOpen  : PropTypes.bool,
    field   : PropTypes.object.isRequired,
    total   : PropTypes.number,
    details : PropTypes.object,
    onClose : PropTypes.func.isRequired
}

const styles = (theme => ({
    title   : {
        "& h2" : {
            fontSize    : "1.3rem",
        },
    },
    explain     : {
        fontSize    : "1rem",
        color       : theme.palette.grey[400],
    },
    dialogPaper     : {
        marginTop   : "-120px",
    }
}));

export default withStyles(styles)(connect(mapState, mapActions)(DetailModal));