
export const tryNormalize = (error) => {
  if(error.isNormalized) return error;
  return normalizeUnhandledError(error);
}

export const normalizeUnhandledError = (error) => {
  return {
      status      : 99,
      statusText  : "Application Error",
      message     : "Application Error",
      details     : error.message,
      validation  : null,
      error       : error,
      response    : null,
      isNormalized: true,
      isUnhandled : true
  };
};

export const normalizeNetworkError = (response, err) => {
  return {
      status      : response.status,
      statusText  : response.statusText,
      message     : err.error ? err.error.message : response.statusText,
      details     : err.error ? err.error.details : err.message,
      validation  : err.error ? err.error.validationErrors : null,
      error       : err.error || err,
      response    : response,
      isNormalized: true,
      isUnhandled : false
  };
};

export const normalizeAjaxError = (response) => {
  const err       = response.error;
  return {
      satus       : err.code,
      statusText  : err.message,
      message     : err.message,
      details     : (err.details || err.message),
      validation  : null,
      error       : err,
      response    : response,
      isNormalized: true,
      isUnhandled : false
  };
};

export default {
  tryNormalize            : tryNormalize,
  normalizeNetworkError   : normalizeNetworkError,
  normalizeAjaxError      : normalizeAjaxError,
  normalizeUnhandledError : normalizeUnhandledError
};
