import _ from 'lodash';

// Create our number formatter.
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});
const currencyFormatterWithCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // minimumFractionDigits: 0,
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});

//   formatter.format(2500); /* $2,500.00 */

const asCurrency    = (val) => {
  if(_.isNumber(val) && val !== 0){
      return currencyFormatter.format(val); //`\$${val.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  }
  else{
      return "";
  }
}

const asCurrencyWithCents = (val) => {
  if(_.isNumber(val) && val !== 0){
    return currencyFormatterWithCents.format(val); //`\$${val.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}
else{
    return "";
}
}

const asFloat   = (val) => {
  if(!val || val == "") return 0;
  if(_.isString(val)){
      val     = val.replace("$", "").replace(",", "");
  }
  return val ? parseFloat(val) : 0;
}

const onlyNumbers   = (evt) => { 
  const keyCode   = evt.keyCode;
  if(!(OK_KEYS.indexOf(keyCode) >= 0 || isNumberKey(evt)))
      evt.preventDefault(); 
};

const OK_KEYS = [8, 9, 35, 36, 37, 39, 46];
const isNumberKey = (evt) => {
  return (evt.keyCode >= 96 && evt.keyCode <= 105) || (evt.shiftKey == false && (evt.keyCode >= 48 && evt.keyCode <= 57));
}

const helper =  {
  asCurrency      : asCurrency,
  asCurrencyWithCents,
  asFloat         : asFloat,
  onlyNumbers     : onlyNumbers
};

export default helper;