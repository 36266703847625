import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useAnchor } from 'helpers/hooks';

export default function ItemMenu({item, options, isWorking, onMenuClick, onMenuClosed}){
  const classes   = buildStyles();
  const [anchor, isOpen, onClick, onClose]  = useAnchor();

  function menuClicked(action){
    onMenuClosed();
    onClose();
    onMenuClick(item, action);
  }

  function closeMenu(){
    onMenuClosed();
    onClose();
  }

  return (
    <>
      <IconButton size="small" onClick={onClick}>
        <MoreVertIcon fontSize="small"/>
      </IconButton>

      <Menu anchorEl={anchor} open={isOpen} onClose={closeMenu} disabled={isWorking}>
        {_.map(options, menuItem => {
          return <MenuItem key={menuItem.id} onClick={() => menuClicked(menuItem.action)} disabled={isWorking} fontSize="small" className={classes.menuItem}>{menuItem.label}</MenuItem>;
        })}
      </Menu>

    </>
  );
}

const buildStyles   = makeStyles(theme => ({
  menuItem  : {
    fontSize    : 16,
  }
}));