import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import DataTableField from './data-table-field';
import DataTableListSection from './data-table-list-section';

const DataTableSection = ({profile, section, values, isReviewer}) => {

  const classes   = buildStyles();

  let rows          = null;
  const isList      = section.type === "list";
  
  if(isList)
    return <DataTableListSection section={section} values={values} profile={profile}/>;
  else{
    const fields  = section.headerField ? [section.headerField, ...section.fields] : section.fields;
    rows    = _.map(fields, f => <DataTableField key={f} profile={profile} fieldKey={f} values={values} />);
  }

  return (
    <table className={classes.sectionTable}>
      {isList &&  /* only show the section header if it's a list */
        <thead>
          <tr>
            <th colSpan={3} className={classes.sectionTitle}>
              <span>{section.title}</span>
            </th>
          </tr>
        </thead>
      }
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

export default DataTableSection;

const buildStyles   = makeStyles(theme => ({
  sectionTable  : {
    width         : "100%",
  },
  sectionTitle  : {
    fontWeight    : 300,
    fontSize      : "16px",
    color         : theme.palette.primary.main, //"#aaa",
    paddingTop     : theme.spacing(2),
  },
  valueCol  : {
    width     : 200,
    minWidth  : 200,
  },
  commentCol : {
    width     : 50,
    minWidth  : 50,
  }
}));