import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams, } from 'react-router-dom';
import { selectProfile } from 'store/selectors/app-selectors';
import { selectShareByRequest } from 'store/selectors/client-selectors';
import { selectFieldComments, selectCurrentShare, selectCurrentConversation } from 'store/selectors/conversation-selectors';
import { selectValue, selectRowValue } from 'store/selectors/value-selectors';

// import { selectStatus } from 'redux-action-status/status-selectors';

// export function useShare(){
//   const { shareRequestId }   = useParams();
//   const share         = shareRequestId ? useSelector(state => selectShareByRequest(state, shareRequestId)) : null;
//   return share;
// }

export function useCurrentShare(){
  //TODO: Need to fix this so it is standardized by the current share
  const { shareRequestId }   = useParams();
  const shareByRequest  = useSelector(state => selectShareByRequest(state, shareRequestId));
  const shareById       = useSelector(selectCurrentShare);
  // const share   = shareRequestId ? useSelector(state => selectShareByRequest(state, shareRequestId)) : useSelector(selectCurrentShare);
  return shareByRequest || shareById;
}


export function useConversation(){
  const conv  = useSelector(selectCurrentConversation);
  // const conversation  = id ? useSelector(state => selectConversationByRequest(state, shareRequestId)) : null;
  return conv;

  // const { shareRequestId }    = useParams();
  // const conversation  = shareRequestId ? useSelector(state => selectConversationByRequest(state, shareRequestId)) : null;
  // return conversation;
}

export function useProfile(){
  const profile     = useSelector(selectProfile);
  return profile;
}

export function useFieldComments(fieldKey){
  // console.log(`comment hook: ${fieldKey}`); //`
  const comments    = useSelector(state => selectFieldComments(state, fieldKey));
  const hasComments = Boolean(comments && comments.length > 0);
  return [hasComments, comments];
}

export function useValue(fieldKey){
  const value   = useSelector(state => selectValue(state, fieldKey));
  return value;
}

export function useRowValue(fieldKey, rowIndex){
  const value   = useSelector(state => selectRowValue(state, fieldKey, rowIndex));
  return value;
}

export function useConditionValue(condition){
  let key           = null;
  if(condition){
    const parts = condition.split(":");
    key         = parts[0];       
  }

  const condValue = useValue(key);
  if(condValue !== null && condValue !== undefined){
    return condValue.toString();
  } 

  return null;
}