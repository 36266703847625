import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import fHelper from 'helpers/financials-helper';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import DoneAllIcon from '@material-ui/icons/DoneAll';
import DataTable from './data-table';
import numberHelper from 'helpers/number-helper';

const ReviewSection = ({regions, region, section, fields, values, classes, onConfirmed}) => {

  const myRegions   = _.filter(regions, r => r.type !== "summary" && r !== region && r.id !== "docs");
  const rows       = _.map(myRegions, reg => {
    return (
      <React.Fragment key={reg.id}>
        <tr>
          <td colSpan={2} className="region-title">
            <span><Link to={reg.path}>{reg.title}</Link></span>
          </td>
        </tr>
        {_.map(reg.sections, sec => <SectionSummary key={sec.id} section={sec} values={values}/>)}
      </React.Fragment>
    );
  });

  return (
    <Grid item container direction="row" className={classes.root}>
      <Grid item xs={12} container justifyContent="center">
        <Typography className={classes.instructions}>
          Please review the information below for accuracy and completeness.  <span className="bold">You are responsible for fully and correctly reporting your financial details.</span> Click any section to expand it.
        </Typography>
      </Grid>
      <DataTable isFirstExpanded={true}/>
    </Grid>
  );
}

const styles = (theme => ({
  root    : {
    marginBottom  : theme.spacing(3),
    borderWidth   : "0 0 1px 0",
    borderStyle   : "solid",
    borderColor   : theme.palette.grey[300],
    paddingBottom : theme.spacing(3),
  },
  
  instructions  : {
    padding       : `${theme.spacing(2)}px ${theme.spacing(2)}px`, //`
    fontSize      : 16,
    textAlign     : "center",
    "& .bold"     : {
      fontWeight    : 600,
    },
    "& .italic"   : {
      fontStyle     : "italic",
    },
    marginBottom  : theme.spacing(2),
  },
  navButton     : {
    margin          : theme.spacing(2),
    "& svg"     : {
      marginRight   : theme.spacing(1),
    }
  },
  summaryTable    : {
    marginBottom  : theme.spacing(1),
    width         : "100%",
    "& .region-title" : {
      paddingTop      : theme.spacing(2),      
    },
    "& .section-title" : {
      fontWeight    : 300,
      fontSize      : "16px",
      color         : "#aaa",
      paddingTop     : theme.spacing(2),
    },
    "& .field-row" : {
      borderBottom  : `1px dotted ${theme.palette.grey[300]}`,

      "& .bold"   : {
        fontWeight  : 700,
      },
    },
    "& .multi-value-table"  : {
      width       : "calc(95% - 30px)",
      margin      : "0 15px",

      "& .multi-label"  : {
        width   : "84%",
      }
    },
    "& .list-table"   : {
      width       : "calc(95% - 30px)",
      margin      : "0 15px",
    }
  },
}));

export default withStyles(styles, {theme: true})(ReviewSection);

const SectionSummary = ({section, values}) => {
  
  let rows          = null;
  const isList      = section.type === "list";

  if(isList)
    rows    = <ListSectionSummary section={section} values={values} />
  else
    rows    = _.map(section.fields, f => <FieldSummary key={f} fieldKey={f} values={values}/>);

  return (
    <React.Fragment>
      {isList &&  /* only show the section header if it's a list */
        <tr>
          <td colSpan={2} className="section-title">
            <span>{section.title}</span>
          </td>
        </tr>
      }
      {rows}
    </React.Fragment>
  );
}

const ListSectionSummary = ({section, values}) => {
  const rows    = values[section.id];
  const fields  = _.map(section.fields, f => fHelper.getField(f, false));
  let key       = 0;

  return (
    <tr>
      <td colSpan={2} className="section-list">
        <table className="list-table">          
          <tbody>
            {(!rows || rows.length === 0) && 
              <tr>
                <td> - none - </td>
              </tr>
            }
            {_.map(rows, r => {
              return (
                <tr key={key++}>
                  {_.map(fields, f => {
                    return (<td key={key++} title={f.header}>{getFieldValue(f, r[f.id])}</td>)
                  })
                }
                </tr>
              )
            })}
          </tbody>
        </table>
      </td>
    </tr>
  )
}

const FieldSummary = ({fieldKey, values}) => {
  const field   = fHelper.getField(fieldKey, false);
  
  if(!field) return null;
  else if(field.params && field.params.length > 1) return <MultiFieldSummary field={field} values={values} />;
  const isPField  = field.params && field.params.length > 0;

  const type    = isPField ? field.params[0].type : field.type;
  let value     = isPField ? values[field.params[0].id] : values[field.id];
  value         = getFieldValue(field, value, type);
  const hasValue  = !!value;

  return(
    <tr className="field-row">
      <td className={clsx({"bold": hasValue})}>{field.label}</td>
      <td>{value}</td>
    </tr>
  );
}

const MultiFieldSummary = ({field, values}) => {
  const p0    = field.params[0];
  const v0    = getParamValue(p0, values);
  let hasValue  = !!v0;

  const firstRow  = p0.label ? 
    (<td colSpan="2">{field.label}</td>) : 
    ( <React.Fragment>
        <td className={clsx({"bold": hasValue})}>{field.label}</td>
        <td>{v0}</td>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <tr className="field-row-multi">
        {firstRow}
      </tr>
      <tr className="field-row">
        <td colSpan={2}>
          <table className="multi-value-table">
            <tbody>
              {_.map(field.params, p => {
                const vField  = getParamValue(p, values);
                hasValue      = !!vField;
                return ( p.label && 
                  <tr key={p.id} className="field-row">
                    <td className={clsx("multi-label", {"bold": hasValue})}>{p.label}</td>
                    <td>{vField}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </td>
      </tr>
    </React.Fragment>
  );
}

function getParamValue(p, values){

  if(p.options){
    const v   = values[p.id];
    const opt = _.find(p.options, o => o.id == v);
    return opt ? opt.label : "";
  }
  else{
    let value =  values[p.id];
    if(p.type === "currency") value = numberHelper.asCurrency(value);
    return value;
  }
}

function getFieldValue(field, value, type){
  const myType  = type !== undefined ? type : field.type;

  if(field.options){
    const oVal  = _.find(field.options, o => o.id == value);
    return oVal ? oVal.label : field.options[0].label;
  }
  else if(myType === "currency" || !myType){
    return numberHelper.asCurrency(value);
  }
  else{
    return value;
  }
}

