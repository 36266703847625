import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

const SubscribedCard = ({license, onRequestRefund, isWorking}) => {
  const classes   = buildStyles();

  return (
    <Card variant="outlined" className={classes.settingCard}>
      <CardContent>
        <Typography className={classes.cardHeader}>Thank you for your purchase</Typography>
        <Typography className={classes.cardSubHeader}>You have purchased an individual premium license</Typography>
        
        <Grid container alignItems="center" justifyContent="center">
          <div className={classes.checkWrapper}>
            <CheckCircleIcon className={classes.checkAvatar} color="secondary"/>
          </div>
        </Grid>   

        <Typography className={classes.commandSubText}>This license gives you access to all the features of FigureEx, with unlimited document downloads</Typography>
        <Grid container justifyContent="center">
          <Button color="secondary" variant="outlined" className={classes.commandButton} endIcon={<SentimentVeryDissatisfiedIcon/>} onClick={onRequestRefund}>Request a Refund</Button>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SubscribedCard;


const buildStyles   = makeStyles(theme => ({
  settingCard   : {
    marginBottom  : theme.spacing(2),
  },
  cardHeader   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  checkWrapper: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius  : "50%",
    height        : "50px",
    width         : "50px",
    lineHeight    : "50px",
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
    marginTop  : theme.spacing(4),
    marginBottom  : theme.spacing(4),
  },
  checkAvatar: {
    color: "#f4f4f4",
    height: 48,
    width: 48,
  },
  cardSubHeader: {
    fontSize: 18,
    fontWeight: 400,
    // marginBottom: theme.spacing(3),
    color         : theme.palette.grey[600],
    textAlign: "center",
  },
  commandText   : {
    fontSize      : 20,
    fontWeight    : 600,
    display: "inline",
    marginBottom: theme.spacing(2),
  },
  commandSubText  : {
    fontSize      : 15,
    fontWeight    : 300,
    textAlign     : "center",
    marginBottom  : theme.spacing(4),
    color         : theme.palette.grey[500],
    "& strong"  : {
      color       : theme.palette.primary.main,
      fontWeight  : 700,
    }
  },
  commandButton : {
    fontSize      : 14,
    fontWeight    : 500,
    textAlign     : "center",
  },
}));