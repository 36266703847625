import { combineReducers } from 'redux';
import onboarding from './onboarding-reducer';
import app from './app-reducer';
import { statusReducer } from 'redux-action-status';
import values from './values-reducer';
import share from './share-reducer';
import client from './client-reducer';
import convo from './conversation-reducer';
import admin from './admin-reducer';
import {reducer as toastrReducer} from 'react-redux-toastr';

const rootReducer = combineReducers({
    app,
    status: statusReducer,
    onboarding,
    values,
    share,
    client,
    convo,
    admin,
    toastr : toastrReducer
});

export default rootReducer;