import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const ReviewerSubscription = () => {

  const classes   = buildStyles();

  return (
    <Grid id="data-view" container justifyContent="center" className={classes.root}>
      
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" className={classes.title}>Subscription</Typography>
        <Typography variant="h6" color="secondary" className={classes.subTitle}>Subscription details</Typography>
      </Grid>

    </Grid>
  );
}

export default ReviewerSubscription;

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
}));