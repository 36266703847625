import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { createComment } from 'store/actions/conversation-actions';
import { IfBlock, ErrorAlert, WaitButton } from 'components';
import { formatDateFromNow } from 'helpers/general-helpers';
import { useStatus } from 'redux-action-status';
// import { selectCurrentShare } from 'store/selectors/conversation-selectors';
import { useFieldComments, useCurrentShare } from 'helpers/selector-hooks';

const DataTableFieldComments = ({profile, fieldKey}) => {
  const classes   = buildStyles();
  const dispatch  = useDispatch();
  const status    = useStatus("comments");
  const share     = useCurrentShare();  //useSelector(selectCurrentShare);
  const [has, comments]  = useFieldComments(fieldKey);
  const orderedComments = _.orderBy(comments, [h => new Date(h.createdAt)], ["desc"]);
  const [props, setProps]   = useState({content: "", });

  function onInputChanged(e){
    const prop    = e.currentTarget.id;
    const value   = e.currentTarget.value;
    setProps({...props, [prop]: value});
  }

  async function onSaveClicked(){
    console.log("Save the comment");
    if(!share) return null;   //can't save it if there's no conversation
    const result  = await dispatch(createComment(share.id, fieldKey, props.content));
    if(result.isOk){
      setProps({...props, content: ""});
    }
  }

  return (
    <Grid container className={classes.commentRoot}>
      <IfBlock condition={!share}>
        <Grid container justifyContent="center" alignItems="center" className={classes.noConversationGrid}>
          <Typography className={classes.infoText}>Comments are hidden.  Select a conversation above to show comments.</Typography>
        </Grid>
      </IfBlock>
      <IfBlock condition={!!share}>
        <Grid item sm={6} container className={classes.historyGrid}>
          <CommentHistory profile={profile} classes={classes} comments={orderedComments}/>
        </Grid>
        <Grid item sm={6} container direction="column" alignItems="flex-start" className={classes.currentGrid}>
          <Grid item container>
            <TextField id="content" value={props.content} onChange={onInputChanged} disabled={status.isWorking} fullWidth size="small" variant="outlined" placeholder="Enter your comment here" multiline rows={3} className={classes.commentTextField} classes={{root: classes.inputRoot}} inputProps={{className: classes.commentInput}}/>
          </Grid>
          <Grid item container>
            <Grid item xs={8}>
              <ErrorAlert error={status.error} className={classes.commentError}/>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end" alignItems="flex-start">
              <WaitButton id="saveButton" isWaiting={status.isWorking} onClick={onSaveClicked} size="small" variant="contained" color="primary" title="Save your comment" className={classes.miniButton}>Save</WaitButton>          
            </Grid>
          </Grid>
        </Grid>
        
      </IfBlock>
    </Grid>
  );
}

export default DataTableFieldComments;

const buildStyles   = makeStyles(theme => ({
  commentRoot  : {
    // padding     : theme.spacing(1),
  },
  noConversationGrid  : {
    height        : 50,
    width         : "100%",
  },
  infoText      : {
    fontSize      : 15,
    fontWeight    : 500,
    fontStyle     : "italic",
  },
  commentError  : {
    marginTop     : theme.spacing(0.5),
  },
  currentGrid   : {
    paddingRight  : theme.spacing(0.5),
  },
  historyGrid   : {
    maxHeight       : 200,
    overflowY       : "auto",
    paddingLeft     : theme.spacing(0.5),
  },
  existingCommentGrid   : {
    flexWrap    : "nowrap",
    borderBottom  : `1px solid ${theme.palette.grey[200]}`, //`
    marginBottom  : theme.spacing(0.5),
  },
  inputGrid   : {
    flexGrow    : 1,
    padding     : `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
  },
  existingTextGrid : {
    padding     : `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`, //`
    border      : `1px solid ${theme.palette.grey[300]}`, //`
    borderRadius  : 3,
    background    : theme.palette.common.white,
  },
  commentTextField  : {
    background  : theme.palette.common.white,
  },
  inputRoot     : {
    "& .MuiInputBase-root"  : {
      padding     : `${theme.spacing(.5)}px ${theme.spacing(1)}px`, //`
    }
  },
  commentInput  : {
    fontSize    : 14,
    // padding     : 4,
  },
  miniButton   : {
    marginTop   : theme.spacing(0.5),
    fontSize    : 12,
    boxShadow   : "none",
  },
  existingText   : {
    fontSize    : 13,
    display     : "inline-block",
  },
  iconCol     : {
    width         : 27,
    marginRight   : theme.spacing(0.5),
  },
  avatar      : {
    height        : 26.667,
    width         : 26.667,
    borderRadius  : "50%",
  }
}));

function CommentHistory({comments, profile, classes}){
  return (
    <Grid container direction="column" className={classes.commentColumn}>
      {_.map(comments, c => <Comment key={c.id} profile={profile} item={c} classes={classes} />)}
    </Grid>
  )
}

function Comment({profile, item, classes}){
  const isMine      = item.createdBy === profile.uid;
  const direction   = isMine ? "row" : "row-reverse";
  const color       = isMine ? "secondary" : "primary";
  const textStyles  = isMine ? {textAlign: "start", marginRight: 27} : {textAlign: "end", marginLeft: 27};
  const iconTip     = `${isMine ? "You" : "Them"}, ${formatDateFromNow(item.createdAt)}`;  //TODO: get the name of the sharer //`
  const img         = isMine && profile.photo ? <img className={classes.avatar} src={profile.photo}/> : <AccountCircleIcon fontSize="small" color={color}/>;
  return (
    <Grid item container direction={direction} className={classes.existingCommentGrid}>
      <Grid item className={classes.iconCol}>
        <Tooltip title={iconTip}>
          {img}
        </Tooltip>
      </Grid>
      <Grid item className={classes.existingTextGrid} style={textStyles}>
        <Typography className={classes.existingText}>{item.content}</Typography>
      </Grid>      
    </Grid>
  );
}