import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { TextDivider } from 'components';
import { readFileAsJson } from 'helpers/file-helper';
import { loadRawData, saveDataLocal } from 'store/actions/value-actions';
import { trackEvent, Events } from 'helpers/analytics';

const EmptyPanel = () => {
  const dispatch  = useDispatch();
  const classes   = buildStyles();

  function onFileChange(e){
    const file  = e.target.files[0];
    openFile(file);
  }

  async function openFile(file){
    //TODO: give them the details of the file they're loading before the load (and overwrite)?
    const json  = await readFileAsJson(file);
    const result  = await dispatch(loadRawData(json));

    if(result && result.isOk){
      trackEvent(Events.open_existing);
    }
  }

  async function startFresh(){
    //Saving the data will clear the isEmpty flag, and establish a settig in the local storage
    dispatch(saveDataLocal(true));  //force the save
  }

  return (
        <Grid id="empty-panel" container justifyContent="center" alignItems="flex-start" className={classes.emptyRoot}>
          
          <Card id="empty-card" elevation={0} variant="outlined" className={classes.emptyCard}>
            <Grid container justifyContent="center" alignItems="center" className={classes.cardHeader}>
              <Typography className={classes.title}>Welcome Back</Typography>
            </Grid>
            <Grid container justifyContent="center" className={classes.cardBody}>
              <Grid item xs={12}>
                <Typography className={classes.text}>It looks like you've been here before, but we don't have your data stored.  Please select from the options below.</Typography>
              </Grid>
              <Grid item container justifyContent="center">
                <input accept=".json" className={classes.input} style={{ display: 'none' }} id="openButton" type="file" onChange={onFileChange} />
                <label htmlFor="openButton">
                  <Tooltip title="Open a saved file that you previously downloaded from FigureEx">
                    <Button variant="outlined" component="span" color="primary" className={classes.actionButton}>
                      Open a saved file
                    </Button>
                  </Tooltip>
                </label> 
              </Grid>
              
              <TextDivider text="or" cols={6}/>

              <Grid item container justifyContent="center">
                <Tooltip title="Begin filling out your financials from scratch">
                  <Button onClick={startFresh} variant="outlined" component="span" color="primary" className={classes.actionButton}>
                    Start fresh
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            
          </Card>

        </Grid>
  );
}

export default EmptyPanel;

const buildStyles   = makeStyles(theme => ({
  emptyRoot  : {
    paddingTop  : "100px",
    width     : "100%",
    height    : "100%",    
  },
  emptyCard   : {
    minHeight     : 300,
    minWidth      : 300,
    maxWidth      : "60%",
  },
  cardHeader  : {
    background    : `${theme.palette.primary.main}33`, //`,
    borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
    padding       : `${theme.spacing(1.5)}px ${theme.spacing(0)}px`, //`
    textAlign     : "center",
    color         : theme.palette.primary.dark,
  },
  cardBody  : {
    padding   : theme.spacing(2),
  },
  title   : {
    fontSize    : 20,
    fontWeight  : 500,
  },
  text  : {
    fontSize  : 17,
    fontWeight: 400,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
  }, 
  actionButton  : {
    marginTop   : theme.spacing(1),
    marginBottom: theme.spacing(1),
    // width       : 250,
  },
}));