import _ from "lodash";
// import cookie from 'react-cookie';
import ErrorHelper from '../../helpers/error-helpers';
import {addQueryParameters} from '../actions/action-helpers';

//Get the base url from the window location...
const BASE_URL      = `${window.location.origin}/api/`;
const CD_HEADER             = "Content-Disposition";

const DEFAULT_HEADERS = {
    "Accept"        : "application/json",
    // "X-XSRF-TOKEN"  : cookie.load("XSRF-TOKEN"),
    "Content-Type"  : "application/json"
};


function isJson(response){
    const cType     = response.headers.get("Content-Type");
    return cType.indexOf("application/json") >= 0;
}

function isAttachment(response){
    const cd    = response.headers.get(CD_HEADER);
    return cd.indexOf("attachment") >= 0;
}

//----------------------
// Deals with a JSON response from the server to the fetch request.
//----------------------
const handleJsonResponse = async (response) => {
    if(response.ok){
        const json  = await response.json();
        
        if(json.success){
            return json;
        }
        else{
            const result    = ErrorHelper.normalizeAjaxError(json);
            throw result;
        }
    }
    else{
        const message   = await response.text();
        const err       = JSON.parse(message);
        const result    = ErrorHelper.normalizeNetworkError(response, err);
        throw result;
    }
}

//----------------------
// Deals with an Attachment response from the server to the fetch request.
//----------------------
const handleFileResponse = async (response) => {
    if(response.ok){
        console.log("File Response was OK");
        const cd    = response.headers.get(CD_HEADER)

        if(cd){
            let fileName    = null;
            const cdParts   = cd.split(';');
            const fname     = _.find(cdParts, cdp => cdp.indexOf("filename=") >= 0);
            if(fname){
                fileName    = fname.trim().substr("filename=".length);
            }

            if(fileName){ // && settings && settings.mimeType){
                var linkElement = document.createElement('a');
                try {
                    var blob = await response.blob()
        
                    //To get the file to download, need to create a virtual link pointed to the
                    // file url and "click" it.
                    var url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    linkElement.setAttribute("download", fileName);
        
                    var clickEvent = new MouseEvent("click", {
                        "view": window,
                        "bubbles": true,
                        "cancelable": false
                    });
                    linkElement.dispatchEvent(clickEvent);

                    return true;    //indicate it worked

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
}

//----------------------
// Prepares the request object by adding headers, options and body as necessary.
//----------------------
const prepareRequest = (verb, value, headers) => {
    const myHeaders     = headers ? _.merge({}, DEFAULT_HEADERS, headers) : DEFAULT_HEADERS;
    const method        = verb.toUpperCase();

    let options         = {
        method      : method,
        // credentials : 'include',
        headers     : myHeaders
    };

    if(value && (method === 'PUT' || method === 'POST')){
        options.body    = JSON.stringify(value);
    }

    return options;
}

//----------------------
// Prepares the URL for the API - pre-pends the base address and deals
// with pagination and query parameters.
//----------------------
const prepareUrl = (url, pagination, params) => {

    let workingUrl      = addQueryParameters(url, pagination, params);

    //Does it need the base url pre-pended?
    if(workingUrl.indexOf("http") !== 0){
        //Make sure there's not a leading /
        if(workingUrl.indexOf("/") === 0){
            workingUrl = workingUrl.substr(1);
        }
        return BASE_URL + workingUrl;
    }
    else{
        return workingUrl;
    }
};

//----------------------
// The main entrypoint - takes the fetch structure from an Action and
// executes the api request
//----------------------
async function callApi(fetchInfo){ 
    const url       = fetchInfo.url;
    const verb      = fetchInfo.verb || "GET";
    const value     = fetchInfo.data || null;    

    const init      = prepareRequest(verb, value);
    const fullUrl   = prepareUrl(url, fetchInfo.pagination, fetchInfo.params);
    const request   = new Request(fullUrl, init);

    let response    = await fetch(request);

    if(isJson(response)){
        response        = await handleJsonResponse(response);
    }
    else if(isAttachment(response)) { 
        response        = await handleFileResponse(response);
    }

    //Otherwise, not a supported type... just return the raw response
    
    return response;

    // response        = await handleResponse(response);
    // return response;
}

//----------------------
// Module Export
export default {
    fetch         : callApi
};

