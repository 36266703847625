import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IfBlock, ErrorAlert, WaitContainer, InviteDialog } from 'components';
import SharingRequestItem from './parts/sharing-request-item';
import MySharesList from './parts/my-shares-list';
import { loadReceivedInvites, loadShares, updateReceivedInvite } from 'store/actions/share-actions';
import { getNowString } from 'store/actions/action-helpers';
import { isNotViewed } from 'config/share-config';
import { useStatusMulti } from 'redux-action-status';

const MySharing = () => {
  const classes     = buildStyles();
  const status      = useStatusMulti(["requests", "shares"]);
  const invites     = useSelector(state => state.share.invitations);
  const shares      = useSelector(state => state.share.shares);
  const [props, setProps]     = useState({isInviting: false, isRefresh: false});
  const dispatch    = useDispatch();
  
  //Load the data...
  useEffect(() => {
    async function init(){
      const isForced  = props.isRefresh;
      setProps({...props, isRefresh: false})
      if(isForced || !invites) await dispatch(loadReceivedInvites());
      if(isForced || !shares) await dispatch(loadShares());
    };
    if(props.isRefresh || !invites || !shares) init();   //should have current items already, so only re-load by request
  }, [props.isRefresh]);

  //Also, mark these invites as viewed
  useEffect(() => {
    async function viewed(){
      const notViewed   = _.filter(invites, isNotViewed);
      notViewed.forEach(async (invite) => {
        await dispatch(updateReceivedInvite(invite.id, {viewedAt: getNowString()}));
      });
    }
    if(invites && _.some(invites, i => !i.viewedAt)) viewed();
  }, [invites]);

  

  const pending     = _.filter(invites, i => !i.status || i.status === 0);
  const history     = _.filter(invites, i => i.status !== 0);
  const hasPending  = Boolean(pending.length > 0);
  const hasHistory  = Boolean(history.length > 0);

  return (
        <Grid id="invite-list" container className={classes.inviteList}>
          
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <Typography variant="h5" color="secondary" className={classes.title}>Your Sharing</Typography>
              <Typography variant="h6" className={classes.info}>Choose who will have <strong>read-only</strong> access to your data</Typography>
            </Grid>
            <Grid item xs={4} container alignItems="center" justifyContent="flex-end">
              <Button size="small" color="secondary" variant="contained" className={classes.shareButton} onClick={() => setProps({...props, isInviting: true})}>Share with your lawyer</Button>
              <IconButton size="small" color="secondary" title="Refresh the client list" className={classes.shareButton} onClick={() => setProps({...props, isRefresh: true})}>
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>

          <InviteDialog type="lawyer" isOpen={props.isInviting} onClose={() => setProps({isInviting: false})}/>
          <ErrorAlert error={status.error} title="Failed to load Client List" className={classes.errorCard}/>

          <IfBlock condition={!hasPending && !hasHistory}>
            <Grid item xs={12} container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{padding: "32px",}}>
              <Typography className={classes.emptyText}>You do not have any share requests at this time.</Typography>
              <Typography className={classes.emptyText}>If you'd like to share your data with your Lawyer, click the button above.</Typography>
            </Grid>
          </IfBlock>

          <IfBlock condition={hasPending}>
            <Card id="sharing-card" elevation={0} variant="outlined" className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Grid container className={classes.cardGrid}>
                  
                  <Grid item container className={classes.pendingRow}>
                    <WaitContainer isWaiting={status.isWorking}>
                      {_.map(pending, p => <SharingRequestItem key={p.id} invite={p} />)}
                    </WaitContainer>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </IfBlock>

          <MySharesList invites={invites} shares={shares} isWorking={status.isWorking} />
        </Grid>
  );
}

export default MySharing;

const buildStyles   = makeStyles(theme => ({
  inviteList  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
  },
  info  : {
    fontSize      : 15,
    fontWeight    : 400,
    color         : theme.palette.grey[500],
    marginBottom  : theme.spacing(2),
  },
  card  : {
    width       : "100%",
    marginRight : theme.spacing(2),
    // minHeight   : 300,
    marginBottom: theme.spacing(4),
  },
  cardContent   : {
    padding     : 0,
  },
  cardHeader    : {
    background      : theme.palette.grey[100],
    height          : 45,
    padding         : `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  shareButton   : {
    fontSize        : 15,
    fontWeight      : 500, 
    textTransform   : "none",
    marginRight     : theme.spacing(2),   
  },
  emptyText     : {
    fontSize        : 17,
    fontWeight      : 500,
    fontStyle       : "italic",
    color           : theme.palette.grey[500],
  },
  actionButton  : {
    fontSize        : 15,
    fontWeight      : 500,
    textTransform   : "none",
  },
  pendingRow     : {
    minHeight       : 45,
    padding         : theme.spacing(2),
  },
  errorCard   : {
    width           : "100%",
    marginRight     : theme.spacing(2),
  },
  inviteTable   : {
    borderCollapse  : "collapse",
    width           : "100%",
    "& .table-header"   : {
      background    : theme.palette.grey[200],
      borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
      height        : 45,
      padding       : `${theme.spacing(0.5)}px ${theme.spacing(1)}px`, //`
    },
    "& th"  : {
      fontSize    : 12,
      fontWeight  : 500,
      textAlign   : "left",
      border      : "none",
      padding     : theme.spacing(1),
      color       : theme.palette.grey[700],
      "&.active"  : {
        fontWeight    : 600,
        color         : theme.palette.grey[900],
      }
    }
  },
}));