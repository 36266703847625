import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from "moment";
import { STATES } from 'config/locations-config';
import { LicenseInfo } from 'helpers/payments-helper';

const EMPTY_PROFILE   = {
  displayName     : "Guest",
  email           : null,
  role            : "guest",
  photo           : null,
  isInitialized   : false,
  isAuthenticated : false,
};

const FREE_ACCOUNT = {
  status: 0,
  statusDescription: "free",
  expirationDate: null,
  paymentAmount: 0,
};

const _getLocationKey   = state => state.app.profile?.locationKey;
const _getProfile       = state => state.app.profile;
const _getIsAuthed      = state => state.app.isAuthenticated;   //defaults to null so we know when it's initialized
const _getCustomer      = state => state.app.customer;

export const selectLocation = createSelector(
    [_getLocationKey],
    (locationKey) => {
        if(!locationKey) return null;
        
        const state     = _.find(STATES, s => s.id === locationKey);
        
        if(state){
          return {
            state     : state,
            name      : `${state.name}`
          };
        }
        else{
          return {};
        }
    }
);

export const selectProfile = createSelector(
  [_getIsAuthed, _getProfile],
  (isAuthed, profile) => {
    if(isAuthed === null) return EMPTY_PROFILE;
    else if(!profile) return {...EMPTY_PROFILE, isInitialized: true};   //we've initialized, but user is not logged in


    const email  = profile.displayName === profile.email ? null : profile.email;
    return {
      uid             : profile.uid,
      displayName     : profile.displayName,
      email           : email,
      role            : profile.role,
      locationKey     : profile.locationKey,
      isTosAccepted   : Boolean(profile.tosAcceptedOn),
      photoUrl        : profile.photoURL,
      photo           : profile.photo ? URL.createObjectURL(profile.photo) : null,
      isRemote        : Boolean(profile.isRemote),
      isUser          : profile.isUser, //Boolean(profile.role === "user"),
      isReviewer      : profile.isReviewer, //Boolean(profile.role === "reviewer"),
      isInitialized   : Boolean(isAuthed !== null),
      isAuthenticated : Boolean(isAuthed === true),
      hasDataChoice   : Boolean("isRemote" in profile),
      hasLocation     : profile.isReviewer || Boolean(profile.locationKey),   //reviewer doesn't need location, so say they have one...
      isAdmin         : Boolean(profile.isAdmin),
    };

  }
);

export const selectCustomer = createSelector(
  [_getCustomer],
  (customer) => {
    if(!customer || !customer.payments) return {...FREE_ACCOUNT};
    else {
      // const checkpoint = new moment().subtract(6, "months").toDate();
      
      const licenses = customer.payments
        .filter(pmt => pmt.status === "succeeded")
        .map(payment => {

          const pid = payment.items[0].price.id;
          const paidDate = new Date(payment.created * 1000);
          const info = LicenseInfo[pid];

          return {
            id: payment.id,
            ...info,
            created: paidDate,
            isExpired: moment().subtract(info?.months ?? 0, "months").isAfter(paidDate),
            isLifetime: info?.isLifetime,
            expiresOn: moment(paidDate).add(info?.months ?? 0, "months").toDate(),
          };
      });

      // const paymentsInfo = customer.payments.map(pmt => ({
      //   id: pmt.id,
      //   status: pmt.status,
      //   created: new Date(pmt.created * 1000),
      //   amount: pmt.amount / 100,
      //   items: pmt.items.map(itm => ({id: itm.id, description: itm.description, price: itm.price})),
      // }));
      
      // const currentPayment = paymentsInfo.find(pi => pi.status === "succeeded" && pi.created >= checkpoint);
      const isLicensed = licenses.some(lic => !lic.isExpired);

      return {
        id: customer.id,
        // isPaid: isLicensed, //!!currentPayment,
        stripeId: customer.stripeId,
        // status: currentPayment ? 100 : 0,
        // statusDescription: currentPayment ? "paid" : "free",
        // expirationDate: currentPayment ? moment(currentPayment?.created).add(6, "months").toDate() : null,
        // paymentAmount: currentPayment?.amount,
        // payments: paymentsInfo,
        licenses,
        isLicensed
      };
    }
  }
);