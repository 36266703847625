import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';


function MenuListItem({icon, text, onClick, className}){
  const classes   = buildStyles();
  return (
    <MenuItem onClick={onClick} className={className}>
      <ListItemIcon>
        <Icon fontSize="small" color="secondary">{icon}</Icon>
      </ListItemIcon>
      <Typography color="secondary" className={classes.menuItem}>{text}</Typography>
    </MenuItem>
  );
}

export default MenuListItem;

const buildStyles   = makeStyles(theme => ({
  menuItem    : {
    fontSize    : "1rem",
  }
}));