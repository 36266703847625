import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';

const buildStyles  = makeStyles(theme => ({
  icon  : props => ({
      color     : props.disabled ? "#ccc" : "#0000008A",
  }),
  underline : {
    borderBottom: `1px solid ${theme.palette.primary.main}a5`,
    '&:after': {
      // The source seems to use this but it doesn't work
      borderBottom: `1px solid ${theme.palette.primary.main}a5`,
    },
  }
}));

const AtkSelect = ({children, disabled, ...props}) => {
  const classes   = buildStyles({ disabled : disabled });

  return (
  <Select 
    input={<FilledInput classes={{
      underline: classes.underline,
    }}
  />} 
  disabled={disabled} 
  inputProps={{
        classes: {
          // input : classes.input,
          icon  : classes.icon
        }
      }}
      {...props} 
  >
    {children}

  </Select>
  );

}

export default AtkSelect;