import React, { useState } from 'react';
// import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { formatDateString } from 'helpers/general-helpers';
import { usePrepareModel } from 'helpers/hooks';
import { acceptInvite, rejectInvite } from 'store/actions/share-actions';
import { addReviewer } from 'store/actions/value-actions';
import { selectProfile } from 'store/selectors/app-selectors';
import MoveDataDialog from '../move-data-dialog';
import { useStatusMulti } from 'redux-action-status';

//NOTE: This represents a pending share request, so only handles scenarios for pending requests

const SharingRequestItem = ({invite}) => {
  const classes   = buildStyles();
  const url       = `/profiles/${invite.id}`; //`
  const data      = usePrepareModel(invite);
  const profile   = useSelector(selectProfile);
  const status    = useStatusMulti(["requests", "shares"]);
  const [isMoving, setMoving]   = useState(false);
  const dispatch  = useDispatch();
  
  if(!data) return null;

  // const isWorking = iStatus.isWorking || sStatus.isWorking;
  const onAccept  = async () => {

    if(!profile.isRemote){
      setMoving(true);
      return;
    }
    else{
      await doAccept();
    }
  }

  const onReject  = async () => {
    await dispatch(rejectInvite(invite.id));
  }

  async function onMoveDataClosed(isMoved){
    setMoving(false);
    if(isMoved === true){
      //TODO: accept the invite
      await doAccept();
    }
  }

  async function doAccept(){
    const result  = await dispatch(acceptInvite(invite));
    if(result.isOk){
      await dispatch(addReviewer(invite.senderId));
    }
  }

  const emailUrl  = `mailto:${data.senderEmail}`;   //`

  return (
    <Grid className={classes.inviteRow} container alignItems="center">
      <MoveDataDialog isOpen={isMoving} onClose={onMoveDataClosed} forShare={true} />
      <Grid item container className={classes.contentCol}>
        <Typography className={classes.inviteText}>
          <Link to={url}><strong>{data.senderName}</strong></Link> requested access to view your data
        </Typography>
        <Typography className={classes.inviteSubText}>
          Would you like to grant {data.senderName} <strong>read-only</strong> access to view your data and add comments? <strong>They will not be able to edit your data.</strong>
        </Typography>
      </Grid>
      <Grid item className={classes.actionsCol} container alignItems="center" justifyContent="flex-end">
        <Button size="small" color="secondary" variant="outlined" className={classes.actionButton} disabled={status.isWorking} onClick={onAccept}>Accept</Button>
        <Button size="small" variant="outlined" className={classes.actionButton} disabled={status.isWorking} onClick={onReject}>Reject</Button>
      </Grid>
      <Grid item sm={12} container className={classes.detailsGrid}>
        <table className={classes.detailsTable}>
          <tbody>
            <tr>
              <td className={classes.detailsLabel}>Requested On:</td>
              <td className={classes.detailsValue}>{formatDateString(data.invitedAt)}</td>
            </tr>
            <tr>
              <td className={classes.detailsLabel}>Sender Email:</td>
              <td className={classes.detailsValue}><a href={emailUrl} target="_blank" rel="noopener noreferrer">{data.senderEmail}</a></td>
            </tr>
            <tr>
              <td className={classes.detailsLabel}>Message: </td>
              <td className={classes.detailsValue}>{data.message}</td>
            </tr>
          </tbody>
        </table>        
      </Grid>
    </Grid>    
  );
}

export default SharingRequestItem;

const buildStyles   = makeStyles(theme => ({
  inviteRow   : {
    // padding   : `${theme.spacing(1.5)}px ${theme.spacing(1)}px`, //`,
    "&:not(:last-child)"   : {
      borderBottom  :  `1px solid ${theme.palette.grey[300]}`, //`
      marginBottom  : theme.spacing(2),
    }
  },
  contentCol  : {
    width       : "calc(100% - 180px)",
  },
  inviteText  : {
    fontSize    : 18,
    fontWeight  : 400,
    color       : theme.palette.grey[800],
    marginBottom  : theme.spacing(2),
    "& strong"  : {
      fontSize    : 17,
      fontWeight  : 600,
    },
    "& a"  : {
      textDecoration  : "none",
      color           : theme.palette.secondary.main,
      "&:hover"   : {
        textDecoration  : "underline",
      },
    },  
  },
  inviteSubText   : {
    fontSize    : 15,
    fontWeight  : 400,
    color       : theme.palette.grey[600],
  },
  deleteRow   : {
    background    : theme.palette.error.light,
    // color         : theme.palette.error.dark,
  },
  actionsCol  : {
    width       : 180,
    padding     : `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`          
  },
  actionButton  : {
    marginLeft  : theme.spacing(0.5),
    fontSize    : 15,
    fontWeight  : 600,
    display     : "inline-block",
  },
  detailsGrid   : {
    padding      : `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
  },
  detailsLabel  : {
    fontSize    : 15,
    fontWeight  : 400,
    color       : theme.palette.grey[500],
    padding     : `${theme.spacing(0.5)}px ${theme.spacing(2)}px`, //`,
  },
  detailsValue  : {
    fontSize    : 16,
    fontWeight  : 500,
    color       : theme.palette.grey[800],
  }
}));