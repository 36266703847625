import _ from 'lodash';
import { getNowString } from './action-helpers';

const WAITLIST_LOADED = "WAITLIST_LOADED";
const WAITLIST_ITEM_DELETED   = "WAITLIST_ITEM_DELETED";
const WAITLIST_ITEM_UPDATED   = "WAITLIST_ITEM_UPDATED";
const INVITATIONS_LOADED  = "INVITATIONS_LOADED";
const INVITATION_CREATED = "WAITLIST_ITEM_INVITED";
const PROFILES_LOADED     = "PROFILES_LOADED";
const ADMINS_LOADED       = "ADMINS_LOADED";
const FEEDBACK_LOADED     = "FEEDBACK_LOADED";
const AUDITLOG_LOADED     = "AUDITLOG_LOADED";
const SETTINGS_UPDATED = "SETTINGS_UPDATED";

export const ADMIN_ACTIONS  = {
  WAITLIST_LOADED,
  WAITLIST_ITEM_DELETED,
  WAITLIST_ITEM_UPDATED,
  INVITATIONS_LOADED,
  INVITATION_CREATED,
  PROFILES_LOADED,
  ADMINS_LOADED,
  FEEDBACK_LOADED,
  AUDITLOG_LOADED,
  SETTINGS_UPDATED,
};

export const loadWaitlist = () => async(dispatch, getState) => {

  const result  = await dispatch({
    type    : WAITLIST_LOADED,
    firebase  : {
      type          : "getList",
      collection    : "waitlist",
      query         : null,
      order         : ["date", "asc"],
      hydrate       : true,
    },
    statusKey     : "waitlist",
  });

  return result;
}

export const loadInvitations = () => async(dispatch, getState) => {

  const result  = await dispatch({
    type    : INVITATIONS_LOADED,
    firebase  : {
      type          : "getList",
      collection    : "invitations",
      query         : null,
      order         : ["issuedOn", "asc"],
      hydrate       : true,
    },
    statusKey     : "invitations",
  });

  return result;
}

export const loadProfiles = () => async(dispatch, getState) => {

  const result  = await dispatch({
    type    : PROFILES_LOADED,
    firebase  : {
      type          : "getList",
      collection    : "profiles",
      query         : null,
      order         : ["createdOn", "asc"],
      hydrate       : true,
    },
    statusKey     : "profiles",
  });

  return result;
}

export const loadAdmins = () => async(dispatch, getState) => {

  const result  = await dispatch({
    type    : ADMINS_LOADED,
    firebase  : {
      type          : "getList",
      collection    : "admins",
      query         : null,
      order         : ["name", "asc"],
      hydrate       : true,
    },
    statusKey     : "users",
  });

  return result;
}

export const loadFeedback = () => async(dispatch, getState) => {

  const result  = await dispatch({
    type    : FEEDBACK_LOADED,
    firebase  : {
      type          : "getList",
      collection    : "feedback",
      query         : null,
      order         : ["sentAt", "desc"],
      hydrate       : true,
    },
    statusKey     : "feedback",
  });

  return result;
}

export const loadAuditLog = (startDate, endDate, eventType) => async(dispatch, getState) => {

  const result  = await dispatch({
    type    : AUDITLOG_LOADED,
    firebase  : {
      type          : "getList",
      collection    : "audit_log",
      //TODO: add support for filter range
      query         : ["timestamp", ">", "5/28/2020"],
      order         : ["timestamp", "desc"],
      hydrate       : true,
    },
    statusKey     : "auditLog",
  });

  return result;
}
// export const loadProfile = (id) => async(dispatch, getState) => {
  
//   if(!getState().admin.profiles){
//     dispatch(loadProfiles());
//   }

//   if(!getState().admin.admins){
//     await dispatch(loadAdmins());
//   }

//   return result;
// }

export const deleteWaitlistItem = (id) => async(dispatch, getState) => {
  const result  = await dispatch({
    type  : WAITLIST_ITEM_DELETED,
    firebase  : {
      type        : "deleteSingle",
      collection  : "waitlist",
      key         : id,
    },
    id            : id,
    statusKey     : "waitlist",
  });

  return result;
}

export const inviteFromWaitlist = (model) => async(dispatch, getState) => {
  
  const wlId          = model.waitlistId;
  const inviteModel   = {
    issuedOn        : new Date().toISOString(),
    issuedBy        : getState().app.profile.uid,
    issuedTo        : [model.firstName, model.lastName].join(" ").trim(),
    issuedToEmail   : model.email,
    message         : model.message,
    waitlistId      : wlId,
  };

  //create the invitation
  const invitation  = await dispatch({
    type  : INVITATION_CREATED,
    firebase  : {
      type        : "create",
      collection  : "invitations",
      value       : inviteModel
    },
    statusKey     : "invitations",
  });

  if(!invitation.isOk){
    return invitation;
  }

  //TODO: Send the invitation

  //update the waitlist item
  const updates   = { invitedOn: getNowString(), invitationId: invitation.data.id };
  const waitlist  = await dispatch({
    type  : WAITLIST_ITEM_UPDATED,
    firebase  : {
      type        : "updateSingle",
      collection  : "waitlist",
      key         : wlId,
      value       : updates
    },
    waitlistId    : wlId,
    statusKey     : "waitlist",
  });

  return waitlist;
}

export const createInvitation = (model) => async(dispatch, getState) => {
  
  const inviteModel   = {
    issuedOn        : new Date(), //.toISOString(),
    issuedBy        : getState().app.profile.uid,
    issuedTo        : [model.firstName, model.lastName].join(" ").trim(),
    issuedToEmail   : model.email,
    message         : model.message,
  };

  //create the invitation
  const invitation  = await dispatch({
    type  : INVITATION_CREATED,
    firebase  : {
      type        : "create",
      collection  : "invitations",
      value       : inviteModel
    },
    statusKey     : "invitations",
  });

  //TODO: Send the invitation
  return invitation;  
}

export const updateSettings = (changes) => async (dispatch, getState) => {
  //TODO: Change this to a whitelist?
  const original = getState().app.settings;
  const updates = {
    ...original,
    ...changes,
  };;

  return dispatch({
      type        : SETTINGS_UPDATED,
      firebase    : {
          type        : "updateSingle",
          collection  : "settings",
          key         : "onboarding",
          value       : updates,
      },
      changes,
  });
}