import React from 'react';
import _ from 'lodash';
import { InputField } from './';

const REGEXP = /{\w+}/g;

export const QuestionRow = ({field, data, colProps}) => {
  return (
      <tr className="question-row">
          <td {...colProps}>
              <Question field={field} data={data}/>
          </td>
      </tr>
  );
}

export const Question = ({field, data}) => {
    const {id, label, params}    = field;
    let pIndex          = 0;
    const matches       = label.match(REGEXP);
    
    const parts         = _.map(label.split(REGEXP), (part, index) => {
        let node        = null;
        if(pIndex < params.length){
            const match = matches[pIndex++];
            const mId   = match.replace("{", "").replace("}","");
            const p     = _.find(params, {id: mId});
            node        = <InputField key={p.id} field={p} data={data}/>;
        }
        return (
            <React.Fragment key={`${id}-label-${index}`}>
                <span>{part}</span>
                {node}
            </React.Fragment>
        );        
    });

    return (
        <React.Fragment>
            {parts}
        </React.Fragment>
    )
    
}