import _ from 'lodash';
import { createSelector } from 'reselect';

const _getShares          = state => state.share.shares;
const _getInviteRequests  = state => state.share.requests;
// const _getConversations   = state => state.convo.conversations;
const _inItemId           = (state, itemId) => itemId;


export const selectInvites = createSelector(
  [_getInviteRequests, _getShares],
  (invites, shares) => {
    if(!invites) return null;
    if(!shares) return invites;

    const merged  = _.map(invites, inv => {
      if(inv.status === 100){
        const share   = _.find(shares, share => share.requestId === inv.id);
        return {
          ...inv,
          share   : share,
        };
      }
      return inv;   //not accepted, so no share to add to the invite
    });

    return merged;
  }
);

export const selectShareByRequest = createSelector(
  [_getShares, _inItemId],
  (shares, shareRequestId) => {
    if(!shares || !shareRequestId) return null;
    return _.find(shares, share => share.requestId === shareRequestId);    
  }
);

export const selectRequest = createSelector(
  [_getInviteRequests, _inItemId],
  (requests, requestId) => {
    if(!requests || !requestId) return null;
    return _.find(requests, req => req.id === requestId);    
  }
);

// export const selectConversationByRequest = createSelector(
//   [_getConversations, _inItemId],
//   (conversations, shareRequestId) => {
//     if(!conversations || !shareRequestId) return null;
//     const convo   =  _.find(conversations, c => c.requestId === shareRequestId);

//   }
// )