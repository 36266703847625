
export const BeginImage   = {
  path    : "images/begin.jpg",
  name    : "Danielle MacInnes",
  handle  : "@dsmacinnes",
};

export const Invitation   = {
  path    : "images/invitation.jpg",
  name    : "Kate Macate",
  handle  : "@katemacate",
};
