import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { trackEvent, Events } from 'helpers/analytics';
import { ErrorAlert, StatusCard } from 'components';
import { selectProfile } from 'store/selectors/app-selectors';
// import { updateProfile } from 'store/actions/app-actions';
import { clearDataFromServer, clearLocalData } from 'store/actions/value-actions';

const ClearDataDialog = ({isOpen, onClose}) => {
  const [props, setProps]   = useState({isChecked: false, isClearing: false, error: null}); 
  const profile   = useSelector(state => selectProfile(state));
  const invites   = useSelector(state => state.share.invitations);
  const dispatch  = useDispatch(); 
  const classes   = buildStyles();

  if(!isOpen) return null;

  const isLocked    = profile.isRemote && _.some(invites, inv => inv.status === 100);

  const onClear  = async() => {
    trackEvent(Events.delete_data);

    //clear data from server, then clear data locally
    let result  = {isOk : true };
    if(profile.isRemote){
      result  = await dispatch(clearDataFromServer());
    }

    //insufficient error here means it just didn't exist, most likely...
    // if(result.isOk){
      result  = await dispatch(clearLocalData());
    // }

    if(result.isOk){
      setProps({isChecked: false, isClearing: false, error: null});
      onClose(true);
    }
  }

  const closeDialog = () => {
    if(!props.isClearing){
      setProps({isChecked: false, isClearing: false, error: null});
      onClose(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>Delete your data</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid id="data-view" container justifyContent="center" className={classes.contentRoot} spacing={2}>
          <Grid item xs={12}>
            <ErrorAlert error={props.error} title="Delete Failed" onClose={() => setProps({...props, error: null})} />
            <StatusCard isVisible={isLocked} status="warning">
              <Typography className={classes.contentText}>
                You must revoke access from all reviewers before deleting your data.  You may revoke access on the <Link to="/my/sharing">My Sharing</Link> tab, then return here to delete your data.
              </Typography>
            </StatusCard>
          </Grid>
          <Grid item xs={12}>
            <React.Fragment>
              <Typography className={classes.contentText}>
                This action is permenant, and cannot be undone. 
                {/* <strong>If you just wish to remove your data from the FigureEx servers</strong>, use the Move my data option instead. */}
              </Typography>
              <Typography className={classes.contentText}>
                <strong>It is strongly recommended</strong> that you download your data and store it in a safe location before deleting it.
              </Typography>
              <Typography className={classes.contentText}>
                Once you complete this action, <strong>your data will be permenantly deleted</strong>, and cannot be recovered.  You must check the 'I Agree' box below to continue with this action.
              </Typography>
            </React.Fragment>            
          </Grid>
          <Grid item container>
            <FormControlLabel classes={{label: classes.check}}
              control={
                <Checkbox value="isChecked" color="primary" size="small" disabled={isLocked} checked={props.isChecked} onChange={() => setProps({isChecked: !props.isChecked})}/>
              }
              label="I Agree"
            />
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button id="cancel" size="small" color="default" variant="outlined" onClick={closeDialog} disabled={props.isClearing}>Cancel</Button>
        <Button id="save" size="small" color="primary" variant="outlined" onClick={onClear} disabled={!props.isChecked || props.isClearing || isLocked}>Delete my data</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClearDataDialog;

const buildStyles   = makeStyles(theme => ({
  title   : {
    padding     : theme.spacing(2),
    background  : theme.palette.primary.light,
    color       : theme.palette.primary.contrastText,
  },
  titleText   : {
    fontSize    : 20,
    fontWeight  : 500,
  },
  contentRoot  : {
    padding     : theme.spacing(1),
  },
  notifyText  : {
    fontSize  : 18,
    fontWeight: 400,
    color     : theme.palette.secondary.light,
    marginBottom  : theme.spacing(1.5),
  },
  contentText  : {
    fontSize  : 16,
    fontWeight: 300,
    marginBottom  : theme.spacing(1),
    "& a" : {
      textDecoration  : "none",
      color   : theme.palette.secondary.main,
    },
    "& strong" : {
      fontWeight  : 500,
    }
  },
  check   : {
    fontSize  : 16,
  }
}));