import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ErrorAlert } from 'components';
import { loadSentInvites, loadShares } from 'store/actions/share-actions';
import ClientList from './parts/client-list';
import { useStatus } from 'redux-action-status';

const ReviewerClients = () => {
  const classes   = buildStyles();
  const status    = useStatus("requests");
  const items     = useSelector(state => state.share.requests);
  const dispatch  = useDispatch();
  const [needsRefresh, triggerRefresh]   = useState(false);

  useEffect(() => {
    async function init(){
      triggerRefresh(false)
      const pOne  = dispatch(loadSentInvites());
      const pTwo  = dispatch(loadShares());
      await Promise.all([pOne, pTwo]);
    };
    if(needsRefresh || (!status.isWorking && !status.error && !status.isEmpty && !items)) init();
  }, [needsRefresh]);

  return (
    <Grid id="reviewer-clients" container className={classes.clientList}>
      <ErrorAlert error={status.error} title="Failed to load Client List" className={classes.errorCard}/>
      <ClientList mode="accepted" onRefresh={() => triggerRefresh(true)}/>
      <ClientList mode="pending" onRefresh={() => triggerRefresh(true)}/>      
    </Grid>
  );
}

export default ReviewerClients;

const buildStyles   = makeStyles(theme => ({
  clientList  : {
    margin  : `${theme.spacing(2)}px ${theme.spacing(1)}px`, //`, 

  },
  title   : {
    fontSize    : 15,
    fontWeight  : 700,
    color       : theme.palette.grey[800],
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  card  : {
    marginLeft  : "auto",
    marginRight : "auto",
    width       : "90%",
    minHeight   : 300,
  },
  cardContent   : {
    padding     : 0,
  },
  cardHeader    : {
    background      : theme.palette.grey[100],
    height          : 45,
    padding         : `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  actionButton  : {
    fontSize        : 15,
    fontWeight      : 500,
    textTransform   : "none",
  },
  refreshButton   : {
    float           : "right",
  },
  filterRow     : {
    height          : 45,
    padding         : theme.spacing(2),
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  errorCard   : {
    width           : "80%",
  },
  cardBody      : {
    // marginTop       : theme.spacing(2),
  },
  clientTable   : {
    borderCollapse  : "collapse",
    width           : "100%",
    "& .table-header"   : {
      background    : theme.palette.grey[100],
      borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
      height        : 45,
      padding       : `${theme.spacing(0.5)}px ${theme.spacing(1)}px`, //`
    },
    "& th"  : {
      fontSize    : 12,
      fontWeight  : 500,
      textAlign   : "left",
      border      : "none",
      padding     : theme.spacing(1),
      color       : theme.palette.grey[700],
      "&.active"  : {
        fontWeight    : 600,
        color         : theme.palette.grey[900],
      }
    }
  },
    
}));

