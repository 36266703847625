import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { buildTheme } from 'config/theme-config';
import AppRouter from './app-router';
import { AnalyticsListener } from 'helpers/analytics';
import Helmet from 'react-helmet';

const theme     = buildTheme();

function App({config}) {
  return (
    <Provider store={config.store}>
      <BrowserRouter>
        <AnalyticsListener trackingId={config.trackingId}>
          <MuiThemeProvider theme={theme}>
            <React.Fragment>
              <CssBaseline/>
              
              <Helmet>
                <title>FigureEx - Sworn Financials</title>
              </Helmet>
              
              <AppRouter />

            </React.Fragment>
          </MuiThemeProvider>
        </AnalyticsListener>
      </BrowserRouter>
    </Provider>
  );
}

export default hot(module)(App);