import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route,  useLocation } from 'react-router-dom';
import { loadData } from 'store/actions/value-actions';
// import { initializeSharing } from 'store/actions/share-actions';
import { selectProfile } from 'store/selectors/app-selectors';
import Grid from '@material-ui/core/Grid';
import { redirectAddingReroute, redirectWithQuery } from 'helpers/general-helpers';
import MyView from 'sections/my/my-view';
import WelcomeView from 'sections/welcome/welcome-view';
// import { loadMyConversations } from 'store/actions/conversation-actions';
// import { useStatus } from 'redux-action-status';
import { initializePayments } from 'helpers/payments-helper';

import AppView from 'sections/app/app-view';
import { loadStripeCustomer } from 'store/actions/app-actions';

function AppContainer(){
  const location        = useLocation();
  const profile         = useSelector(selectProfile);
  const isInitialized   = useSelector(state => state.app.isInitialized);
  const isAuthed        = useSelector(state => state.app.isAuthenticated);
  // const sharing         = useStatus("sharing");
  
  const dispatch  = useDispatch();

  //Effect to retrieve values data
  useEffect(() => {
    async function loadValues(){
      await dispatch(loadData());
    }
    if(isAuthed === true && profile.isUser) loadValues();
  }, [isAuthed, profile.hasDataChoice]);

  //Initialize payments
  useEffect(() => {
    async function runAsync(){
      initializePayments();
      await dispatch(loadStripeCustomer()); //load the customer so we have subscription information

      //const customer = await dispatch(loadStripeCustomer());
      // console.log("Stripe Customer: ", customer);
    }

    if(isAuthed) runAsync();
  }, [isAuthed]);

  //Initialize sharing
  // useEffect(() => {    
  //   if(isAuthed && profile?.uid &&!sharing.isInitialized && !sharing.isWorking) {
  //     dispatch(initializeSharing());
  //   }
  // }, [isAuthed, profile, sharing]);

  //Load all the user's conversations (works for either user or reviewer)
  // useEffect(() => {
  //   if(isAuthed && profile?.uid) dispatch(loadMyConversations());
  // }, [isAuthed, profile]);

  const redirect  = checkForRedirect(location, isInitialized, profile);
  if(redirect) return redirect;

  return ( 
    <Grid container>        
      <Switch>
        <Route path="/welcome" component={WelcomeView} />
        <Route path="/my" component={MyView} />
        <Route path={["/", "/app"]} component={AppView} />
      </Switch>
    </Grid>
  );
}

export default AppContainer;

function checkForRedirect(location, isInitialized, profile){

  if(!isInitialized){
    return redirectAddingReroute(location, "/initializing", "/app");
  }

  if(location.pathname !== "/welcome"){
    //Make sure they're signed in...
    if(profile.isInitialized){
      if(!profile.isAuthenticated){
        //Only need to pass along user type query parameter because user isn't authenticated
        return redirectWithQuery(location, "/welcome", ["type"]);
      }
      else if(profile.isUser && (!profile.hasDataChoice || !profile.hasLocation)){
          //still need to go through the welcome flow
          return redirectAddingReroute(location, "/welcome", "/app");
      }
    }
  }

  return null;
}