import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

function NavButton({to, replace, newTab, isIcon, ...otherProps}){
  const history   = useHistory();
  const onNav     = () => {
    if(Boolean(newTab)){
      window.open(to, "_blank");
    }
    else if(Boolean(replace)){
      history.replace(to);
    }
    else{
      history.push(to);
    }
  };

  return Boolean(isIcon) ? <IconButton {...otherProps} onClick={onNav}/> : <Button {...otherProps} onClick={onNav}/>;
}

export default NavButton;