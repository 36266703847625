// import _ from 'lodash';
// import { trackEvent, Events } from 'helpers/analytics';

const CLIENT_LOADING = "CLIENT_LOADING", CLIENT_LOADED = "CLIENT_LOADED", CLIENT_FAILED = "CLIENT_FAILED";

export const CLIENT_ACTIONS = {
  CLIENT_LOADING, CLIENT_LOADED, CLIENT_FAILED,
};

export const loadClientData = (clientId) => async (dispatch, getState) => {
  dispatch({type: CLIENT_LOADING});

  // const profile   = getState().app.profile;

  const result  = await dispatch({
    type      : CLIENT_LOADED,
    failType  : CLIENT_FAILED,
    firebase  : {
      type        : "getSingle",
      collection  : "values",
      key         : clientId,
      hydrate     : true,
    },
  });

  return result;
}