import React from 'react';
import PropTypes from 'prop-types';
import { SectionRow2, QuestionRow, SubtotalRow, InputField, Question } from './';
import fHelper from 'helpers/financials-helper';
import { withFieldChanged } from './with-field-changed';

class StandardSection extends React.Component {
    
    renderBody = (section, data, isCollapsed) => {

        let rows        = [];
        if(!isCollapsed){
            const fields    = fHelper.getFields(section.fields);
            for(var i = 0; i < fields.length; i++){
                const field     = fields[i];
                
                if(!field) continue;

                let row         = null;
                const rowId     = `row-${i}`;

                if(field.type == "question"){
                    const colProps  = {colSpan : "4"};
                    row             = <QuestionRow key={rowId} field={field} data={data} colProps={colProps}/>;
                }
                else{
                    row             = <SectionRow2 key={rowId} field1={field} data={data} onChangeDetails={this.props.onChangeDetails}/>;
                }

                if(row) rows.push(row);
            }
            const myKey         = `${section.id}-subtotal`;
            const totals        = section.noTotals ? null : <SubtotalRow key={myKey} label={"Total " + (section.name || "")} data={data} section={section}></SubtotalRow>;
            if(totals) rows.push(totals);
        }

        return (
            <tbody>
                {rows}
            </tbody>
        );
    }

    renderHeader = (section, data) => {
        if(section.title){
            let titleCols   = 5;
            let headerField = null;
            let subHeader   = null;

            if(section.headerField){
                titleCols         = 2;  //use 2 cols for this field
                const hField    = fHelper.getField(section.headerField);
                if(hField && hField.type == "question"){
                    headerField     = (
                        <td colSpan={titleCols} className="text-right">
                            <Question field={hField} data={data}/>
                        </td>
                    );
                }
                else if(hField){
                    headerField     = (
                        <td colSpan={titleCols} className="text-right">
                            <InputField field={hField} data={data} onChangeOverride={this.props.onFieldChanged}/>
                        </td>
                    )
                }
            }

            if(section.desc){
                subHeader = (
                    <tr className="section-sub-header">
                        <td colSpan="4" className="section-desc">{section.desc}</td>
                    </tr>
                );
            }

            return (
                <thead>
                    <tr className="section-header">
                        <td colSpan={titleCols} className="section-title-col">
                            <span className="section-title">{section.title}</span>
                        </td>
                        {headerField}
                    </tr>
                    {subHeader}
                </thead>
            );
        }
        
        return null;
    }

    render(){
        const { section, values }  = this.props;
        
        const isCollapsed   = !fHelper.isConditionMet(section.condition, values);
        const headerRow     = this.renderHeader(section, values);
        const body          = this.renderBody(section, values, isCollapsed);

        return (
            <div className="section-div">
                <table className="table table-bordered table-condensed section-table standard-section" onChange={this.props.onFieldChanged}>
                    {headerRow}
                    {body}
                </table>
            </div>
        );
    }
}

StandardSection.propTypes   = {
    section     : PropTypes.object.isRequired,
    values      : PropTypes.object,
    onChange    : PropTypes.func.isRequired,
    onChangeDetails : PropTypes.func.isRequired
};

export default withFieldChanged(StandardSection);