import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RefreshIcon from '@material-ui/icons/Refresh';
import { TBodyWrapper, InviteDialog } from 'components';
import ClientListItem from './client-list-item';
import { SHARE_CODES, isPending } from 'config/share-config';
import { selectInvites } from 'store/selectors/client-selectors';
import { useStatus } from 'redux-action-status';

const TITLES = {
  accepted    : "Your Clients",
  pending     : "Sent Invitations",
};

const ClientList = ({mode, onRefresh}) => {
  const classes     = buildStyles();
  const isAccepted  = mode === "accepted";
  const status      = useStatus("requests");
  const items       = useSelector(selectInvites);
  const [props, setProps]   = useState({isInviting: false});
  
  const filtered    = items ? _.filter(items, item => filterFunc(mode)(item)) : [];
  const dateCol     = isAccepted ? "Last Viewed" : "Invited On";
  const isEmpty     = status.isInitialized && filtered.length === 0;

  return (
      <Card id="client-list-card" elevation={0} variant="outlined" className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container className={classes.cardGrid}>
            
            <Grid item container className={classes.cardHeader} alignItems="center">
              <Grid item sm={3}>
                <Typography variant="h5" className={classes.title}>{TITLES[mode]}</Typography>
              </Grid>
              <Grid item sm={9}>
                {isAccepted && <Button size="small" color="secondary" variant="contained" className={classes.actionButton} onClick={() => setProps({...props, isInviting: true})}>Invite Client</Button>}
                <IconButton size="small" color="secondary" title="Refresh the client list" className={classes.refreshButton} onClick={onRefresh}>
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            
            <InviteDialog type="client" isOpen={props.isInviting} onClose={() => setProps({isInviting: false})}/>
            
            <Grid item container className={classes.cardBody}>
              <table id="client-table" className={classes.clientTable}>
                <thead>
                  <tr className="table-header">
                    <th className="active">Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>{dateCol}</th>
                    <th></th>
                  </tr>
                </thead>
                <TBodyWrapper colSpan={5} isWorking={status.isWorking} isEmpty={isEmpty}>
                  {filtered ? _.map(filtered, i => <ClientListItem key={i.id} item={i} mode={mode} />) : null}
                </TBodyWrapper>
              </table>
            </Grid>
          </Grid>

        </CardContent>
      </Card>
  );
}

export default ClientList;

const buildStyles   = makeStyles(theme => ({
  title   : {
    fontSize    : 15,
    fontWeight  : 700,
    color       : theme.palette.grey[800],
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  card  : {
    minHeight   : 300,
    marginBottom  : theme.spacing(3),
    width       : "100%",
  },
  cardContent   : {
    padding     : 0,
  },
  cardHeader    : {
    background      : theme.palette.grey[100],
    height          : 45,
    padding         : `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  actionButton  : {
    fontSize        : 15,
    fontWeight      : 500,
    textTransform   : "none",
  },
  refreshButton   : {
    float           : "right",
  },
  filterRow     : {
    height          : 45,
    padding         : theme.spacing(2),
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  errorCard   : {
    width           : "80%",
  },
  cardBody      : {
    // marginTop       : theme.spacing(2),
  },
  clientTable   : {
    borderCollapse  : "collapse",
    width           : "100%",
    "& .table-header"   : {
      background    : theme.palette.grey[200],
      borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
      height        : 45,
      padding       : `${theme.spacing(0.5)}px ${theme.spacing(1)}px`, //`
    },
    "& th"  : {
      fontSize    : 12,
      fontWeight  : 500,
      textAlign   : "left",
      border      : "none",
      padding     : theme.spacing(1),
      color       : theme.palette.grey[700],
      "&.active"  : {
        fontWeight    : 600,
        color         : theme.palette.grey[900],
      }
    }
  },
    
}));

const filterFunc = (mode) => {
  return (item) => (mode === "accepted") ? item.status === SHARE_CODES.accepted : isPending(item);
}

// function isPending(item){ return (!item.status || item.status === SHARE_CODES.pending); };