import React from 'react';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import nHelp from 'helpers/number-helper';
import fHelp from 'helpers/financials-helper';
import { useValue, useRowValue, useConditionValue } from 'helpers/selector-hooks';

export function InputField({field, sectionId, rowIndex, dataProps, onChangeOverride}){
    if(field.condition){
        return (
            <Condition field={field}>
                <StandardInputField field={field} dataProps={dataProps} onChangeOverride={onChangeOverride} />
            </Condition>
        );
    }
    else{
        if(sectionId!== undefined && rowIndex !== undefined && rowIndex >= 0){
            return (<RowInputField field={field} sectionId={sectionId} rowIndex={rowIndex} dataProps={dataProps} onChangeOverride={onChangeOverride} />);
        }
        else{
            return (<StandardInputField field={field} dataProps={dataProps} onChangeOverride={onChangeOverride} />);
        }
    }
}

export function Condition({field, children}){
    const conditionValue  = useConditionValue(field.condition);
    if(!fHelp.isConditionMet2(field.condition, conditionValue)){
        return null;
    }

    return children;
}

export function RowInputField({field, sectionId, rowIndex, dataProps, onChangeOverride}){
    const rowData   = useRowValue(sectionId, rowIndex);
    const value     = rowData ? rowData[field.id] : null;
    return (<CoreInputField field={field} value={value} rowIndex={rowIndex} data={rowData} dataProps={dataProps} onChangeOverride={onChangeOverride} />);
}

export function StandardInputField({field, dataProps, onChangeOverride}){
    let value     = useValue(field.id);
    return (<CoreInputField field={field} value={value} dataProps={dataProps} onChangeOverride={onChangeOverride} />);
}


export const CoreInputField = ({field, value, rowIndex, rowData, dataProps, onChangeOverride}) => {
    console.log("** render input field");
    // if(!fHelp.isConditionMet(field.condition, data)){
    //     return null;
    // }
    //TODO: eleminate the duplicate field ids, but need to wait until the onChange doesn't rely on the id...
    const elementId     = field.id; //rowIndex ? `${field.id}~${rowIndex}` : field.id;

    const isUndefined   = Boolean(value === undefined); //data ? (data[field.id] === undefined) : true;
    value               = value || ""; //data && data[field.id] ? data[field.id] : "";
    let isDisabled      = false;
    let classNames      = `form-control form-control-sm field-${field.size ? field.size : "sm"} field-${field.type}`;
    const onChange      = () => {}; //don't do anything in onChange, since it will bubble up automatically

    switch(field.type){
        case "text":
            return <input key={field.id} type="text" id={elementId} className={classNames} value={value} onChange={onChange} placeholder={field.label} {...dataProps}></input>;
        
        case "number":
            return <input key={field.id} type="text" id={elementId} className={classNames} value={value} onChange={onChange} onKeyDown={nHelp.onlyNumbers} placeholder={field.label} {...dataProps}></input>;
        
        case "currency":
            if(field.calculation){
                value       = rowData ? fHelp.calculateCalcField(field, rowData) : "";
                isDisabled  = true;
            } 
            value = nHelp.asCurrency(value);
            // classNames += "field-currency";

            return <input key={field.id} type="text" data-type="currency" disabled={isDisabled} id={elementId} className={classNames} value={value} onChange={onChange} onKeyDown={nHelp.onlyNumbers} placeholder={field.label} {...dataProps}></input>;

        case "select":
            //TODO: If no options...?
            const options   = _.map(field.options, opt => <option key={opt.id} value={opt.id} disabled={opt.isDisabled}>{opt.label}</option>);
            value           = value ? value : field.options[0].id;
            return <select key={field.id} id={elementId} className={classNames} value={value} onChange={onChange} {...dataProps} placeholder="choose">{options}</select>;

        case "date":
            //TODO: switch this to a date picker
            return <input key={field.id} type="text" id={elementId} className={classNames} value={value} onChange={onChange} placeholder={field.label} {...dataProps}></input>;

        case "checkbox":
            classNames      = `field-${field.size ? field.size : "sm"} field-checkbox`;
            const cbValue   = !!value; //field.isNegative ? !!!value : !!value;
            // value           = (value !== false && value !== true) ? false : value;
            const cbSize    = field.size === "md" ? "medium" : "small";
            const cbLabel   = field.label || field.subLabel || null;
            const negProp   = field.isNegative ? {"data-isnegative": true} : null;
            const inpProps  = {...dataProps, ...negProp};

            return <FormControlLabel label={cbLabel} className="field-checkbox-label" control={
                <Checkbox id={elementId} size={cbSize} className={classNames} checked={cbValue} onChange={onChangeOverride} inputProps={inpProps}/>
            } />;
            // return (
            //     <div className="pretty p-switch p-fill" title={field.label}>
            //         <input key={field.id} id={elementId} className={classNames} type="checkbox" checked={isUndefined || value} onChange={onChangeOverride} {...dataProps}/>
            //         <div className={`state ${cbType}`}>
            //             <label>{field.label || field.subLabel}</label>
            //         </div>
            //     </div>
            // );

        case "switch":
            value           = (value !== false && value !== true) ? false : value;
            const swType    = field.subType ? `p-${field.subType}` : "p-danger";
            
            return (
                <div className="pretty p-switch p-fill" title={field.label}>
                    <input key={field.id} id={elementId} className={classNames} type="checkbox" checked={isUndefined || value} onChange={onChangeOverride} {...dataProps}/>
                    <div className={`state ${swType}`}>
                        <label>{field.label || field.subLabel}</label>
                    </div>
                </div>
            );

        default:
            //TODO: what If I don't recognize the type
            return <span>[unrecognized field]</span>
    }
}