import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { useFieldComments } from 'helpers/selector-hooks';
import numberHelper from 'helpers/number-helper';
import fHelper from 'helpers/financials-helper';
import { IfBlock } from 'components/index';

const ttPropsA   = {placement: "top", enterDelay: 750};
const ttPropsB   = {placement: "left", enterDelay: 750};

export function FieldLabel({field, values}){
  const classes   = buildStyles();
  const isPField  = Boolean(field.params && field.params.length > 0);
  const value     = isPField ? getParamValue(field.params[0], values) : getFieldValue(field, values);
  const hasValue  = !!value;
  const fType     = (isPField ? field.params[0].type : field.type) || "currency";   //default to currency
  const [hasComments]   = useFieldComments(field.id);
  
  return (
    <Grid container className={classes.fieldLabel}>
      <Tooltip title="click to add or view comments" {...ttPropsA}>
        <Grid item className={clsx(classes.labelCol, {"bold": hasValue})}>
          {prepareLabel(field, values)}
        </Grid>
      </Tooltip>
      <Tooltip title="click to add or view comments" {...ttPropsB}>
        <Grid item className={clsx(classes.valueCol, classes[fType])}>
          {value}
          {hasComments && <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>}
        </Grid>
      </Tooltip>
    </Grid>
  );
}

export function MultiFieldLabel({field, values}){
  const children  = field.params ? field.params : field.children;
  const classes   = buildStyles();
  const vField    = getFieldValue(field, values);
  const hasFValue = !!vField;
  const p0        = children[0];
  const v0        = getParamValue(p0, values);
  const hasValue  = !!v0;
  const fType     = p0.type;
  const [hasComments]   = useFieldComments(field.id);

  if(p0.label){
    //Since the first parameter has a label, need to break it out onto it's own line
    return (
      <Grid container className={classes.singleLineParamLabel}>
        <Tooltip title="click to add or view comments" {...ttPropsA}>
          <Grid container>
            <Grid item className={clsx(classes.labelCol, {"bold": hasValue})}>
              {prepareLabel(field)}
              <IfBlock condition={!hasFValue && hasComments}>
                <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>
              </IfBlock>
            </Grid>
            <IfBlock condition={hasFValue}>
              <Tooltip title="click to add or view comments" {...ttPropsB}>
                <Grid item className={clsx(classes.valueCol, classes[field.type])}>
                  {vField}
                  {hasComments && <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>}
                </Grid>
              </Tooltip>
            </IfBlock>
            <Grid container className={classes.paramFields}>
              {_.map(children, p => <ChildField key={p.id} param={p} values={values} classes={classes} />)}
            </Grid>
          </Grid>
        </Tooltip>
      </Grid>
    );
  }
  else{
    //No label for the first parameter, so the value goes on the first line with the label from the field
    const otherChildren   = [...children.slice(1)];
    return (
      <Grid container className={classes.multiLineParamLabel}>
        <Tooltip title="click to add or view comments" {...ttPropsA}>
          <Grid item className={clsx(classes.labelCol, {"bold": hasValue})}>
            {prepareLabel(field)}
          </Grid>
        </Tooltip>
        <Tooltip title="click to add or view comments" {...ttPropsB}>
          <Grid item className={clsx(classes.valueCol, classes[fType])}>
            {v0}
            {hasComments && <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>}
          </Grid>
        </Tooltip>
        <Grid container className={classes.paramFields}>
          {_.map(otherChildren, p => <ChildField key={p.id} param={p} values={values} classes={classes} />)}
        </Grid>
      </Grid>
    );
  }
}

const buildStyles   = makeStyles(theme => ({
  labelCol  : {
    flexGrow  : 1,
  },
  valueCol  : {
    width     : 200,
    minWidth  : 200,
  },
  commentIcon   : {
    alignSelf   : "flex-end",
    float       : "right",
    fontSize    : 14,
    verticalAlign : "center",
    marginRight   : theme.spacing(1),
    color         : theme.palette.secondary.main, //theme.palette.grey[600],
  },
  fieldLabel  : {

  },
  paramFields   : {
    marginTop       : theme.spacing(0.25),
  },
  parameterField  : {
    paddingLeft     : theme.spacing(1.5),
  },
  singleLineParamLabel  : {

  },
  multiLineParamLabel   : {

  },  
}));

function ChildField({param, values, classes}){
  //Check to see if this parameter should be displayed
  if(param.condition && !fHelper.isConditionMet(param.condition, values)) return null;

  const vField    = getParamValue(param, values);
  const fType     = param.type;
  return ( param.label && 
    <Grid container className={classes.parameterField}>
      <Grid item className={clsx(classes.labelCol)}>
        {prepareLabel(param)}
      </Grid>
      <Grid item className={clsx(classes.valueCol, classes[fType])}>
        {vField}
      </Grid>
    </Grid>
  );
}

function getParamValue(p, values){

  if(p.options){
    const v   = values[p.id];
    const opt = _.find(p.options, o => o.id == v);
    return opt ? opt.label : "";
  }
  else{
    let value =  values[p.id];
    if(p.type === "currency") value = numberHelper.asCurrency(value);
    else if(p.type === "checkbox"){
      if(Boolean(value)) return "yes";
      // else return "no";
    }
    return value;
  }
}

function getFieldValue(field, values){
  const myType  = field.type;
  const value   = values[field.id];

  if(field.options){
    const oVal  = _.find(field.options, o => o.id == value);
    return oVal ? oVal.label : field.options[0].label;
  }  
  else if(myType === "currency" || !myType){
    return numberHelper.asCurrency(value);
  }
  else{
    return value;
  }
}

function prepareLabel(item, values){
  let result  = item.label;

  if(result && result.indexOf("{") >= 0){
    result  = result.substr(0, item.label.indexOf("{"));
  }

  //If this is an other, need to check for a user-provided label associated with it
  if(item.isOther && values){
    const othKey    = `${item.id}Label`;  //`
    const othLabel  = values[othKey];
    if(othLabel){
      result  = `${result}: ${othLabel}`;   //`
    }
  }

  return result;
}