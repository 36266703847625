import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Loading } from 'components';
import DataTableRegion from './data-table-region';
import { selectProfile } from 'store/selectors/app-selectors';

const DataTable = ({isReviewer, isFirstExpanded}) => {

  const classes   = buildStyles();
  const profile   = useSelector(selectProfile);
  const regions   = useSelector(state => state.app.regions);
  const values    = useSelector(state => isReviewer ? state.client.clientData?.values : state.values);

  if(!regions || !values.a_isLoaded){
    return (
      <Grid container alignItems="center" justifyContent="center" className={classes.loadingGrid}>
        <Loading isVisible={true} message="Loading..."/>
      </Grid>
    );
  }

  const myRegions  = _.filter(regions, r => r.type !== "summary" && r.id !== "docs" && r.id !== "review");
  const rows       = _.map(myRegions, reg => <DataTableRegion key={reg.id} region={reg} isDefaultExpanded={isFirstExpanded && reg.id === "profile"} values={values} profile={profile} isReviewer={Boolean(isReviewer)} />);

  return (
    <Grid id="data-table" container justifyContent="center" className={classes.dataTable}>
      <Grid item xs={12}>
        {rows}
      </Grid>
    </Grid>
  );
}

export default DataTable;

const buildStyles   = makeStyles(theme => ({
  dataTable  : {
    // padding       : theme.spacing(1),
    paddingBottom : 0,
    fontSize      : 14,
  },  
  loadingGrid: {
    minHeight     : 200,
  }
}));