import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import logo from "figure-ex-small-color.png";
import bgLeft from "./breakup-man.jpg";
import bgRight from"./breakup-woman.jpg";
import LandingFooter from './landing-footer';

const LandingPage = () => {
  const classes   = buildStyles({bgLeft: bgLeft, bgRight: bgRight});
  console.log(bgLeft, bgRight);

  return (
    <Grid container className={classes.landingPage}>
      <Grid item container direction="row" className={classes.mainView}>
        <Helmet>
          <title>Easy Sworn Financials - FigureEx</title>
        </Helmet>
        <Grid className={classes.leftCol} item xs={4}/>
        <Grid className={classes.centerCol} item xs={4} container direction="column" justifyContent="flex-start">
          <div className={classes.hero}>

            <h1 className={classes.head}>Divorce sucks.</h1>
            <h2 className={classes.subHead}>Your sworn financials don't have to.</h2>
            
            <h1 className={classes.brand}>
              <img src={logo} alt="figure-ex logo" className={classes.logo}/><br/>
              FigureEx
            </h1>
            
            <h2 className={classes.info}>
              Use our secure app to fill and manage your sworn financials with ease. Save time and reduce stress when you need it most.
            </h2>  

            <Grid container justifyContent="center" className={classes.quoteDiv}>
              <Grid item>
                <span className={classes.quote}>"Like turbo tax for your divorce."</span>
                <span className={classes.quoteName}> -Erik</span>
              </Grid>
            </Grid>
            
            <div>
              <Button className={classes.blockButton} component={Link} to="/landing/learn-more" color="primary">Learn More</Button>        
              <Button className={classes.callToAction} component={Link} to="/app" color="secondary" variant="contained">Get Started</Button>
              {/* <Button className={classes.blockButton} component={Link} to="/landing/lawyer" color="default" variant="outlined">I'm a Lawyer</Button> */}
            </div>
            
          </div>
        </Grid>
        <Grid className={classes.rightCol} item xs={4}/>
      </Grid>
      <LandingFooter/>
    </Grid>
  );
}

const buildStyles  = makeStyles(theme => ({
  landingPage  : {
    width   : "100%",
    height  : "100%",
  },
  mainView: {
    height        : "100vh",
    width         : "100%",
    minHeight     : 775,
  },
  leftCol   : props => ({
    height  : "100%",
    backgroundImage       : `url(${props.bgLeft})`,
    backgroundSize        : "cover",
    backgroundPosition    : "100%",
  }),
  rightCol  : props => ({
    height  : "100%",
    backgroundImage       : `url(${props.bgRight})`,
    backgroundSize        : "cover",
    backgroundPosition    : "0%",
  }),
  centerCol   : {
    height          : "100%",
    width           : "33%",
    padding         : "15px",
    position        : "relative",
    "&:before": {
      boxShadow     : "-15px 0 15px -15px inset #0005",
      content       : '" "',
      height        : "100%",
      left          : "-15px",
      position      : "absolute",
      top           : 0,
      width         : "15px",
    },
    "&:after": {
      boxShadow     : "15px 0 15px -15px inset #0005",
      content       : '" "',
      height        : "100%",
      right         : "-15px",
      position      : "absolute",
      top           : 0,
      width         : "15px",
    }
  },
  hero      : {
    marginTop       : "10%",
    width           : "100%",
    textAlign       : "center",
    color         : theme.palette.secondary.dark, //"#493E87",
  },
  head    : {
    fontSize      : 40,
    fontWeight    : 500,
    marginBottom  : 5,
    marginTop     : 5,
  },
  subHead   : {
    fontSize      : 34,
    fontWeight    : 200,
    margin        : "5px 0",      
  },
  brand   : {
    fontSize      : 34,
    fontWeight    : 300,
    fontFamily    : "'Nunito Sans',Roboto,Muli",
    color         : theme.palette.primary.main,
    marginTop     : theme.spacing(4),
    marginBottom  : theme.spacing(5),
  }, 
  info    : {
    marginTop     : 15,
    fontSize      : 21,
    fontWeight    : 400,
    padding       : "0 10px",
    color         : theme.palette.primary.main,
  } ,
  logo    : {
    marginBottom    : "-5px",
    maxHeight       : "36px",
  },
  blockButton   : {
    display         : "block",
    marginBottom    : 15,
    maxWidth        : 260,
    marginLeft      : "auto",
    marginRight     : "auto",
    "&:hover"   : {
      color         : theme.palette.primary.main,
    }
  },
  callToAction   : {
    display         : "block",
    marginBottom    : 15,
    maxWidth        : 260,
    marginLeft      : "auto",
    marginRight     : "auto",
    "&:hover"   : {
      color         : theme.palette.secondary.contrastText,
    }
  },
  quoteDiv  : {
    marginTop     : theme.spacing(3),
    marginBottom  : theme.spacing(4),
  },
  quote   : {
    fontSize        : 18,
    fontWeight      : 400,
    color           : theme.palette.primary.main,
    fontStyle       : "italic",
    display         : "block",
  },
  quoteName   : {
    fontSize        : 18,
    fontWeight      : 400,
    color           : theme.palette.primary.main,
    fontStyle       : "italic",
    textAlign       : "right",
    display         : "block",
  }
}));

export default LandingPage;