export const APP_ACTIONS = {
  WORKING         : 'APP_WORKING',
  ERROR           : 'APP_ERROR',
  CLEAR_ERROR     : 'APP_CLEAR_ERROR',
  CLEAR_ALL_ERRORS: 'CLEAR_ALL_ERRORS',
  SETTINGS_LOADED : 'APP_SETTINGS_LOADED',
  INITIALIZE      : 'APP_INITIALIZING',
  INITIALIZED     : 'APP_INITIALIZED',

  NO_OP           : 'APP_NO_OP',

  READ_LOCATION   : 'APP_READ_LOCATION',      //Reads the stored location for this user
  READ_TOS        : 'APP_READ_TOS',           
  CHANGE_LOCATION : 'APP_CHANGE_LOCATION',    //Updates the stored location for this user
  LOAD_LOCATION   : 'APP_LOAD_LOCATION',      //Loads the region, config for this location
  TOS_AGREE       : 'APP_TOS_AGREE',          //Flags the TOS as agreed to
  SAFETY_AGREE    : 'APP_SAFETY_AGREE',       //Flags the safety as read and agreed to
  WELCOME_COMPLETE: 'APP_WELCOME_COMPLETE',   
  
  CUSTOMER_LOADED  : 'APP_CUSTOMER_LOADED',
  CUSTOMER_FAILED  : 'APP_CUSTOMER_FAILED',
  CUSTOMER_LOADING : 'APP_CUSTOMER_LOADING',

  SIGNING_IN      : 'APP_SIGNING_IN',
  SIGNED_IN       : 'APP_SIGNED_IN',
  SIGNING_OUT     : 'APP_SIGNING_OUT',
  SIGNED_OUT      : 'APP_SIGNED_OUT',
  SIGN_IN_ERROR   : 'APP_SIGN_IN_ERROR',
  STORE_SIGNUP_PROPS : 'APP_STORE_SIGNUP_PROPS',

  PROFILE_CREATING: 'APP_PROFILE_CREATING',    //TODO: need to wire up reducer for this
  PROFILE_LOADED  : 'APP_PROFILE_LOADED',
  PROFILE_CREATED : 'APP_PROFILE_CREATED',
  PROFILE_FAILED  : 'APP_PROFILE_FAILED',   //TODO: need to wire up reducer for this
  INVITE_MATCHED  : 'APP_INVITE_MATCHED',

  TOGGLE_OPEN_DIALOG  : 'APP_TOGGLE_OPEN_DIALOG',
  TOGGLE_SAVE_DIALOG  : 'APP_TOGGLE_SAVE_DIALOG',
  SAVED           : 'APP_FINANCIALS_SAVED',

  EXPORTING       : 'APP_EXPORTING',
  EXPORTED        : 'APP_EXPORTED',
  EXPORT_ERROR    : 'APP_EXPORT_FAILED',
  RECEIVE_NOTIFICATIONS   : 'APP_RECEIVE_NOTIFICATIONS',

  VOTED_LOCATION  : 'VOTED_FOR_LOCATION',
  SEND_MESSAGE    : 'MESSAGE_SENT',
  FEEDBACK_SENT   : 'FEEDBACK_SENT',
};

export const HOME_ACTIONS       = {
  // TOGGLE_DETAIL   : 'HOME_TOGGLE_DETAIL'
};

export const VALUE_ACTIONS       = {
  FIELD_UPDATED   : 'VALUE_FIELD_UPDATED',
  ROW_DELETED     : 'VALUE_ROW_DELETED',
  DATA_SAVED      : 'VALUE_SAVED',
  DATA_LOADED     : 'VALUE_LOADED',
  DATA_READ       : 'VALUES_READ',
  DATA_CLEARED    : 'VALUE_CLEARED',
  VERSIONS_LOADED : 'VALUE_VERSIONS_LOADED',
  DATA_DOWNLOADED : 'VALUE_DOWNLOADED',
  DATA_NOT_LOADED : 'VALUES_NOT_LOADED',
  DATA_LOADED_SILENT: 'VALUES_LOADED_SILENT',

  DATA_DB_WORKING   : 'VALUES_DB_WORKING',
  DATA_DB_LOADED    : 'VALUES_DB_LOADED',
  DATA_DB_SAVED     : 'VALUES_DB_SAVED',
  DB_LOAD_FAILED    : 'VALUES_DB_LOAD_FAILED',
  DATA_DB_DELETED   : 'VALUES_DB_DELETED',
  DATA_DB_ERROR     : 'VALUES_DB_ERROR',

  REVIEWER_ADDING   : 'REVIEWER_ADDING',
  ADD_REVIEWER      : 'VALUES_ADD_REVIEWER',
  REVIEWER_ADD_FAILED   : 'REVIEWER_ADD_FAILED',
  REVIEWER_REMOVING : 'REVIEWER_REMOVING',
  REMOVE_REVIEWER   : 'VALUES_REMOVE_REVIEWER',
  REVIEWER_REMOVE_FAILED  : 'REVIEWER_REMOVE_FAILED',

  REGION_STATUS_UPDATED   : 'REGION_STATUS_UPDATED',
};

export const PROFILE_ACTIONS    = {
  WORKING         : 'PROFILE_WORKING',
  ERROR           : 'PROFILE_ERROR',
  CLEAR_ERROR     : 'PROFILE_CLEAR_ERROR',

  TOGGLE_LOGIN    : 'PROFILE_TOGGLE_LOGIN',

  FETCHED         : 'PROFILE_FETCHED',
  RECEIVE_NOTIFICATIONS : 'PROFILE_RECIEVE_NOTIFICATIONS',
  UPDATED         : 'PROFILE_UPDATED',
  USERNAME_UPDATED: 'PROFILE_USERNAME_UPDATED',
  GOT_PWD_COMPLEXITY : 'PROFILE_GOT_PWD_COMPLEXITY',
  PASSWORD_CHANGED: 'PROFILE_PASSWORD_CHANGED',

  NOTIFICATION_SENT   : 'PROFILE_NOTIFICATION_SENT'
};