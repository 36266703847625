import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Header } from '../financials/layout';
import DefaultView from './my-default-view';
import ProfileView from './my-profile';
import DataView from './my-data';
import SharingView from './my-sharing';
import { initialize } from 'store/actions/app-actions';
import { LoadingContainer } from 'components';
import MyNav from './parts/my-nav';
import MySubscriptionView from "./my-subscription";

function MyView(){
  const classes   = buildStyles();
  const dispatch  = useDispatch();
  const isInitialized   = useSelector(state => state.app.isInitialized);
  const isAuthed        = useSelector(state => state.app.isAuthenticated);

  useEffect(() => {
    if(!isInitialized){
      async function init(){
        await dispatch(initialize());
      }
      init();
    }
  }, []);  

  return (
    <Grid container className={classes.view}>
      <Grid item sm={12} container className={classes.headerWrapper}>
        <Header/>
      </Grid>
      <Grid item container className={classes.viewBody}>
        <Grid item className={classes.navCol}>
          <MyNav/>
        </Grid>
        <Grid item container alignItems="flex-start" className={classes.contentWrapper}>
          {!isAuthed && <LoadingContainer isVisible={isInitialized} style={{width: "100%"}}/>}
          {isAuthed && 
            <React.Fragment>
              <Route path="/my" exact component={DefaultView} />
              <Route path="/my/profile" exact component={ProfileView} />
              <Route path={["/my/data", "/my/data/view"]} component={DataView} />
              <Route path="/my/sharing" component={SharingView} /> 
              <Route path="/my/subscription" component={MySubscriptionView} />
            </React.Fragment>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MyView;

const buildStyles   = makeStyles(theme => ({
  view  : {

  },
  viewBody  : {
    flexWrap    : "nowrap",
  },
  navCol  : {
    // width       : 180,
  },
  contentWrapper  : {
    position        : "relative",
    paddingTop      : theme.spacing(2),
    paddingLeft     : theme.spacing(2),
    // width           : "calc(100% - 180px)",
    minHeight          : "calc(100vh - 60px)",
    overflowY       : "auto",
    overflowX       : "hidden",
  },
}));