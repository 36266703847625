import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ItemMenu, StatusChip, ItemListRow } from 'components';
import { deleteWaitlistItem } from 'store/actions/admin-actions';
import { useStatus } from 'redux-action-status';

function WaitListItem({item, onAction}){
  const classes           = buildStyles();
  const status            = useStatus("waitlist");
  const [data, setData]   = useState(item);
  const [isDeleting, setDeleting]   = useState(false);
  
  const dispatch  = useDispatch();

  useEffect(() => {
    async function prepareItem(item){
      let data  = item;
      if(!data.isLoaded && data.ref){
        data  = {...data, isLoaded: true, ...(await data.ref.data())};
      }
      setData(data);
    }
    prepareItem(item);
    return undefined;
  }, [item]);

  const onToggleDelete = () => { setDeleting(!isDeleting); }
  const onDelete  = (e) => { dispatch(deleteWaitlistItem(item.id)); }

  async function onMenuClick(item, action){
    console.log("action " + action + " for item " + item.name);
    if(action === "delete"){
      onToggleDelete();
    }
    else if(action === "invite"){
      // await onInvite(item);
      await onAction("invite", item);
    }
    else if(action === "view_invitation"){
      await onAction("view_invitation", item.invite);
    }
  }

  function onMenuClosed(){
    // setOver(false);
  }

  return (
    <ItemListRow isLoading={!item.isLoaded && item.ref} colSpan={4} itemName={data.name} isDeleting={isDeleting} onCancelDelete={onToggleDelete} onDelete={onDelete} isWorking={status.isWorking}>
      <td>{data.name}</td>
      <td>{data.email}</td>
      <td>
        <StatusChip status={data.status}/>
      </td>
      <td title={data.dateLong}>{data.date}</td>
      <td title={data.invitedOnLong}>
        {data.invitedOn && 
          <Button onClick={() => onMenuClick(item, "view_invitation")} color="secondary" className={classes.linkButton}>{data.invitedOn}</Button>
        }            
      </td>
      <td title={data.acceptedOnLong}>
        {data.acceptedOn && 
          <Button onClick={() => onMenuClick(item, "view_user")} color="secondary" className={classes.linkButton}>{data.acceptedOn}</Button>
        }            
      </td>
      <td className="moreCol">            
        <ItemMenu item={item} options={menuOptions[item.status]} status={status} onMenuClick={onMenuClick} onMenuClosed={onMenuClosed} />            
      </td>    
    </ItemListRow>
  );
}

export default WaitListItem;

const buildStyles   = makeStyles(theme => ({
  actionButton  : {
    marginLeft   : theme.spacing(0.5),
  },
  linkButton    : {
    textTransform     : "none",
    fontSize          : 14,
    fontWeight        : "400",
    padding           : "2px 6px",
  },
  accepted  : {
    color       : theme.palette.primary.main,
    fontWeight  : 600,
  },
  pending  : {
    // color       : theme.palette.primary.main,
    fontWeight  : 600,
  }
}));

const menuOptions   = {
  waiting   : [
    {id: 0, label: "Invite User", action: "invite" },
    {id: 10, label: "Remove from Waitlist", action: "delete" },
  ],
  invited   : [
    {id: 0, label: "View invitation", action: "view_invitation" },
    {id: 10, label: "Delete Invitation", action: "delete" },
  ],
  accepted   : [
    {id: 0, label: "View invitation", action: "view_invitation" },
    {id: 10, label: "View user", action: "view_user" },
  ]
};