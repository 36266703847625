import React from 'react';
// import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeLocation, welcomeComplete } from 'store/actions/app-actions';
import { loadData, loadRawData } from 'store/actions/value-actions';
import { Header } from '../financials/layout';
import WelcomeAuth from './welcome-auth';
import WelcomLocation from './welcome-location';
import WelcomeResume from './welcome-resume';
import WelcomeData from './welcome-data';
import { readFileAsJson } from 'helpers/file-helper';
import { trackEvent, Events } from 'helpers/analytics';
import { selectProfile } from 'store/selectors/app-selectors';
import { redirectConsideringReroute } from 'helpers/general-helpers';

class WelcomeView extends React.Component{

  resume = async () => {
    await this.props.actions.loadData();
    await this.props.actions.welcomeComplete();
    trackEvent(Events.resume_work);
  }

  open = async (file) => {
    //TODO: Make this more advanced - display a dialog, and evaluate the dates (downloaded vs current)
    // and warn the user that they'll be overwriting current data.
    const json  = await readFileAsJson(file);
    const result  = await this.props.actions.loadRawData(json);

    if(result){
      await this.props.actions.welcomeComplete();
      trackEvent(Events.open_existing);
    }
  }

  startOver = async () => {
    await this.props.actions.welcomeComplete();
    trackEvent(Events.start_over);
  }

  render(){
    const { classes, location }   = this.props;
    const screens = chooseView(this.props);

    if(screens.isFinished){
      //Note: not currently used, but may be if we skip out before the resume screen
      return redirectConsideringReroute(location, "/app");
    }

    return (
      <Grid container direction="row" className={classes.root}>
        <Grid item sm={12} container direction="column" className={classes.headerWrapper}>
          <Header/>
        </Grid>
        <Grid item sm={12} direction="row" container align-items="center" justifyContent="center" className={classes.contentWrapper}>
          <Grid item sm={10} container className={classes.contentBox}>
            <Paper className={classes.contentPaper} elevation={0} variant="outlined">
              <Grid container className={classes.innerBox}>
                {screens.isAuth && <WelcomeAuth />}
                {screens.isData && <WelcomeData />}
                {screens.isLocation && <WelcomLocation />}
                {screens.isResume &&  <WelcomeResume onResume={this.resume} onOpen={this.open} onStartOver={this.startOver} />}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapState(state, myProps){
  return {
    settings    : state.app.settings,
    isAuthed    : Boolean(state.app.user && state.app.profile),
    isWelcomed  : state.app.isWelcomed,
    profile     : selectProfile(state),
    user        : state.app.user,
    localProps  : state.values.local,
  };
}

function mapActions(dispatch){
  return {
    actions   : bindActionCreators({changeLocation, welcomeComplete, loadData, loadRawData}, dispatch)
  };
}

const styles = theme => ({
  root  : {

  },
  contentWrapper  : {
    position        : "relative",
    paddingTop      : theme.spacing(2),
    paddingLeft     : theme.spacing(2),
    height          : `calc(100vh - 76px)`,
    overflow        : "auto",
  },
  contentBox     : {
    height          : "90%",
    maxHeight       : "90%",
    marginTop       : "25px",
    minHeight       : "500px",
  },
  contentPaper  : {
    width           : "100%",
    // padding         : theme.spacing(1),
  },
  innerBox        : {
    height          : "100%",
  },  
})

export default connect(mapState, mapActions)(withStyles(styles)(WelcomeView));

function chooseView(props){
  const { isAuthed, profile }   = props;
  
  const result  = {
    isAuth      : false,
    isData      : false,
    isLocation  : false,
    isResume    : false,
    isFinished  : false,
  };

  if(!isAuthed || !profile)           return {...result, isAuth: true};  
  else if(!profile.hasDataChoice)     return {...result, isData: true };
  else if(!profile.hasLocation)       return {...result, isLocation : true};
  // else if(!profile.isRemote && user.lastLoginAt && localProps.isEmpty){
  //   //User is local, has a last login and their data is empty... prompt with the resume screen
  //   return {...result, isResume   : true};
  // }
  //TODO: removing the Resume screen...
  // else if(lastSaved)         return {...result, isResume   : true};
  
  return {...result, isFinished: true};    //none of the above...
} 

// function isPresent(value){
//   return value === true || value === false;
// }