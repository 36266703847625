import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Header } from 'sections/financials/layout';
import AdminNav from './admin-nav';
import { initialize } from 'store/actions/app-actions';
import { LoadingContainer } from 'components';
import DashboardView from "./dashboard/dashboard-view";
import WaitlistView from './waitlist/waitlist-view';
import UsersView from './users/users-view';
import FeedbackView from './feedback/feedback-view';
import AuditLogView from './auditlog/auditlog-view';
import InvitationsView from './invitations/invitations-view';

const AdminView = () => {
  const classes   = buildStyles();
  const dispatch  = useDispatch();
  const isInitialized   = useSelector(state => state.app.isInitialized);
  const isAuthed        = useSelector(state => state.app.isAuthenticated);

  useEffect(() => {
    if(!isInitialized){
      async function init(){
        await dispatch(initialize());
      }
      init();
    }
  }, []);  

  return (
        <Grid id="admin-view" container justifyContent="center" className={classes.adminRoot}>
          <Grid item container className={classes.adminHeader} >
            <Header />
          </Grid>
          <Grid item container className={classes.adminBody}>
            <Grid item className={classes.navCol}>
              <AdminNav/>
            </Grid>
            <Grid item container alignItems="flex-start" className={classes.contentWrapper}>
              {!isAuthed && <LoadingContainer isVisible={isInitialized} style={{width: "100%"}}/>}
              {isAuthed && 
                <React.Fragment>
                  <Route path="/admin" exact component={DashboardView} />
                  <Route path="/admin/users" exact component={UsersView} />
                  <Route path="/admin/invitations" component={InvitationsView} />
                  <Route path="/admin/feedback" component={FeedbackView} />
                  <Route path="/admin/waitlist" component={WaitlistView} /> 
                  <Route path="/admin/auditlog" component={AuditLogView} />
                </React.Fragment>
              }
            </Grid>
          </Grid>

        </Grid>
  );
}

export default AdminView;

const buildStyles   = makeStyles(theme => ({
  adminRoot  : {
    // padding     : theme.spacing(1),
  },
  adminBody  : {
    flexWrap    : "nowrap",
  },
  contentWrapper  : {
    position        : "relative",
    paddingTop      : theme.spacing(2),
    paddingLeft     : theme.spacing(2),
    minHeight          : "calc(100vh - 60px)",
    overflowY       : "auto",
    overflowX       : "hidden",
  },
}));