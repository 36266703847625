import React from 'react';
import _ from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { CommentContainer } from 'components';
import fHelper from 'helpers/financials-helper';
import nHelper from 'helpers/number-helper';
import { useFieldComments } from 'helpers/selector-hooks';

const ttPropsA   = {placement: "top", enterDelay: 750};

const DataTableListSection = ({section, values, profile}) => {
  const classes   = buildStyles();
  
  const rows    = values[section.id];
  const fields  = _.map(section.fields, f => fHelper.getField(f, false));
  let key       = 0;
  const isEmpty = Boolean(!rows || rows.length === 0);
  const [hasComments]   = useFieldComments(section.id);
  const match     = useRouteMatch("/app/review");         //TODO: a better way to make this read only??

  return (
    <CommentContainer fieldKey={section.id} profile={profile} isReadOnly={!!match}>
      <Grid container className={classes.sectionGrid}>
          <Grid container>
            <Tooltip title="click to add or view comments" {...ttPropsA}>
              <Typography variant="h4" className={classes.sectionTitle}>{section.title}</Typography>
            </Tooltip>
            {hasComments && <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>}
          </Grid>
        <Grid item container>
          <table className={classes.sectionTable}>
            {!isEmpty && 
              <thead>
                <tr className={classes.listHeader}>
                  {_.map(fields, field => <th key={field.id} className={classes[field.type]}>{field.shortHeader || field.header}</th>)}
                </tr>
              </thead>
            }
            <tbody>
              {isEmpty && 
                <tr>
                  <td> - none - </td>
                </tr>
              }
              {!isEmpty && _.map(rows, row => <DataTableListRow key={key++} row={row} fields={fields} classes={classes}/>)}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </CommentContainer>
  );
}

export default DataTableListSection;

const buildStyles   = makeStyles(theme => ({
  sectionTable  : {
    width         : "100%",
  },
  sectionTitle  : {
    fontWeight    : 400,
    fontSize      : 16,
    color         : theme.palette.primary.main, //"#aaa",
    paddingTop     : theme.spacing(2),
  },
  commentIcon   : {
    alignSelf   : "flex-end",
    float       : "right",
    fontSize    : 14,
    verticalAlign : "center",
    // marginRight   : theme.spacing(1),
    marginLeft    : theme.spacing(3),
    color         : theme.palette.secondary.main, //theme.palette.grey[600],
  },
  sectionTable  : {
    margin      : `${theme.spacing(0.5)}px ${theme.spacing(1)}px`, //`
    width       : "100%",
  },
  listTable       : {
    width       : "calc(95% - 30px)",
    margin      : "0 15px",
  },
  listHeader    : {
    "& th" : {
      fontWeight    : 300,
      fontSize      : 15,
      color         : theme.palette.grey[500],
      textAlign     : "left",
    }    
  },
  currency  : {
    textAlign     : "right !important",
  }
}));

function DataTableListRow({row, fields, classes}){
  let key   = 0;

  return (
      <tr key={key++}>
        {
          _.map(fields, f => (
            <td key={key++} title={f.header} className={classes[f.type]}>
              {getFieldValue(row, f)}
            </td>
          ))
        }
      </tr>
  );
}

function getFieldValue(row, field, type){
  const myType  = type !== undefined ? type : field.type;
  const value   = field.calculation ? fHelper.calculateCalcField(field, row) : row[field.id];

  if(field.options){
    const oVal  = _.find(field.options, o => o.id == value);
    return oVal ? oVal.label : field.options[0].label;
  }  
  else if(myType === "currency" || !myType){
    return nHelper.asCurrency(value);
  }
  else if(field.calculation){
    //TODO: Calculate the field value

  }
  else{
    return value;
  }
}