import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Header } from '../financials/layout';
import ReviewerClients from './reviewer-clients';
import ClientDetails from './client-details';
import ReviewerNav from './parts/reviewer-nav';
import ReviewerSubscription from './reviewer-subscriptions';
import ProfileView from '../my/my-profile';

const ReviewerView = () => {
  const classes   = buildStyles();

  return (
    <Grid id="reviewer-view" container>
      <Grid item sm={12} container direction="column">
        <Header/>
      </Grid>
      
      <Grid item container className={classes.viewBody}>
        <Grid item className={classes.navCol}>
          <ReviewerNav />
        </Grid>
        
        <Grid item className={classes.contentWrapper} container>
          <Route path="/app" exact component={ReviewerClients}/>
          <Route path="/app/profile" exact component={ProfileView} />
          <Route path="/app/account" exact component={ReviewerSubscription} />
          <Route path="/app/clients/:shareRequestId" component={ClientDetails}/>
        </Grid>

      </Grid>

    </Grid>
  );
}

export default ReviewerView;

const buildStyles   = makeStyles(theme => ({
  content  : {
    padding     : theme.spacing(1),
  },
  viewBody  : {
    flexWrap    : "nowrap",
  },
  navCol  : {
  },
  contentWrapper  : {
    position        : "relative",
    padding         : `${theme.spacing(2)}px ${theme.spacing(1)}px`, //`
    paddingLeft     : theme.spacing(2),
    flexGrow        : 1,
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
}));