import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../financials/parts/data-table';
import { NavButton } from 'components';
// import ConversationChooser from '../financials/parts/conversation-chooser';
// import { useStatusMulti } from 'redux-action-status';
// import { selectConversation } from 'store/actions/conversation-actions';

const ViewData = () => {
  const classes         = buildStyles();
  // const status          = useStatusMulti(["conversations", "comments"]);
  // const conversations   = useSelector(state => state.convo.conversations);
  // const dispatch        = useDispatch();
  // const hasConvos       = Boolean(conversations);
  // const { conversationId }  = useParams(); 
  
  // useEffect(() => {
  //   //Only do this once we've got the conversations loaded
  //   if(conversations){
  //     const id  = conversationId || "0";
  //     dispatch(selectConversation(id));
  //   }
  // }, [conversations, conversationId]);
  
  return (
    <Grid id="data-view" container className={classes.root}>
      <Grid item container style={{flexWrap: "nowrap"}} spacing={2}>
        <Card className={classes.headerCard} variant="outlined">
          <CardContent>
            <Grid container>
              <Grid item xs={9} container alignItems="flex-start">
                <Typography variant="h5" color="secondary" className={classes.title}>Your FigureEx data</Typography>
              </Grid>
              <Grid item xs={3} container justifyContent="flex-end" alignItems="flex-start">
                {/* <Loading isVisible={status.isWorking} size="small" noMessage={true} /> */}
                <NavButton to="/my/data" title="Back to Data Management" color="primary" size="small" className={classes.backButton}><CloseIcon fontSize="small"/> Close</NavButton>
              </Grid>
              {/* <Grid item>
                <Typography variant="h6" className={classes.info}>Select a conversation to view comments from your <Link to="/my/sharing">reviewers</Link>.</Typography>
              </Grid>
              <IfBlock condition={hasConvos}>
                <Grid item container className={classes.selectorGrid}>
                  <ErrorAlert error={status.error} title="Failed to update conversation" className={classes.error}/>
                  <ConversationChooser />
                </Grid>
              </IfBlock> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item container className={classes.dataTableContainer}>
        <DataTable isFirstExpanded={true}/>
      </Grid>
    </Grid>
  );
}

export default ViewData;

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  headerCard  : {
    width     : "100%",
    margin    : `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    marginBottom  : theme.spacing(1),
    // background    : theme.palette.grey[50],
  },
  backButton  : {
    fontSize    : 15,
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
  },
  dataTableContainer  : {
    padding     : theme.spacing(1),
  },
  info  : {
    fontSize      : 15,
    fontWeight    : 400,
    color         : theme.palette.grey[600],
    marginBottom  : theme.spacing(2),
    "& a, a:hover"  : {
      textDecoration  : "none",
      color           : theme.palette.secondary.main,
    }
  },
  selectorGrid  : {
    padding       : `${theme.spacing(0.5)}px ${theme.spacing(1)}px`, //`

  },  
  error   : {
    width     : "100%",
  }
}));