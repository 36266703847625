import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

function StandardLogin({isConfirm, onAuth, onToggle, isWorking}){
  const classes   = useStyles();
  const btnLabel  = isConfirm ? "Create Account" : "Login";

  const [email, setEmail]       = useState("");
  const [pwd, setPwd]           = useState("");
  const [confirm, setConfirm]   = useState("");
  const [errors, setErrors]     = useState(null);

  const doAuth  = (e) => {
    setErrors(null);
    const errors  = validateCredentials(email, pwd, confirm, isConfirm);
    if(!errors){
      return onAuth(email, pwd);
    }
    else{
      setErrors(errors);
    }
  }

  const onKeyPress = (e) => {
    if(e.key === "Enter"){
      doAuth();
    }
  }

  let i   = 0;

  return (
    <Grid container justifyContent="center" className={classes.root}>
      {errors && 
        <Card elevation={0} className={classes.errorCard} variant="outlined">
          <ul className={classes.errorList}>
            {_.map(errors, e => <li key={i++} className={classes.errorItem}>{e}</li>)}
          </ul>
        </Card>
      }
      <Grid item sm={10} container justifyContent="center">
        <TextField id="email" type="email" size="small" label="email" disabled={isWorking} className={classes.textField} value={email} onChange={(e) => setEmail(e.target.value)} onKeyPress={onKeyPress}/>
        <TextField id="password" type="password" size="small" label="password" disabled={isWorking} className={classes.textField} value={pwd} onChange={(e) => setPwd(e.target.value)} onKeyPress={onKeyPress}/>
        {isConfirm && <TextField id="passwordConfirm" type="password" size="small" label="confirm password" disabled={isWorking} className={classes.textField} value={confirm} onChange={(e) => setConfirm(e.target.value)} onKeyPress={onKeyPress}/> }
        <Grid container className={classes.buttonRow} justifyContent="space-between" alignItems="center">
          <Grid>
            {!isConfirm && (
              <>
                <span>First time here?</span>
                <Button onClick={onToggle} className={classes.linkButton} size="small" color="primary">Create Account</Button>
              </>
            )}
          </Grid>
          <Button className={classes.btn} color="primary" variant="outlined" onClick={doAuth} disabled={isWorking}>{btnLabel}</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StandardLogin;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `0 ${theme.spacing(1)}px`,
  },
  buttonRow        : {
    marginTop     : theme.spacing(2),
  },
  textField           : {
    width         : "100%",
    "& input, label"   : { 
      fontSize      : 18,
    }
  },
  errorCard         : {
    background      : theme.palette.error.light,
    border          : `1px solid ${theme.palette.error.dark}`,
    borderRadius    : 5,
    color           : theme.palette.error.main,
    margin          : "10px 0",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",
  },
  errorList   : {
    margin          : theme.spacing(0.5),
    paddingLeft     : theme.spacing(2),
    fontSize        : 16,
  },
  errorListItem   : {

  },
  linkButton    : {
    textTransform     : "none",
    fontSize          : 14,
    fontWeight        : "400",
    padding           : "2px 6px",
  }
}));

function validateCredentials(email, password, confirmPassword, isConfirm){
  let errors  = [];
  if(email.indexOf("@") <= 0) errors.push("Email address is not valid.");
  if(!email || !password || (isConfirm && !confirmPassword)) errors.push("All fields are required.");
  if(isConfirm && password !== confirmPassword) errors.push("Password and Confirm Password don't match.");

  //TODO: Password Complexity...

  return errors.length === 0 ? null : errors;
}